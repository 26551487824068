angular.module("KhBase").factory("FileUploadFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/userdatafileupload/data/:id/', {id: '@id'}, {
        save: {
            method: 'POST',
            transformRequest: function (data) {
                if (data === undefined)
                    return data;
                var fd = new FormData();
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        fd.append(key, data[key]);
                    }
                }
                return fd;
            },
            headers: {'Content-Type': undefined}
        }
    });
}]);

angular.module("khFileUploads").directive("khFileUploads", [function () {
	return {
		restrict: 'E',
		replace: true,
		templateUrl: '/static/assets/khapps2/base/khutils/khFileUploads/partials/kh_fileuploads.html',
		controller: "khFileUploadsController",
		scope: {
			appTag: '@',
			dbtype: '@',
			displaytype: '@',
			maxfiles: '@',
			allowduplicates: '@',
			filetype: '@'
		}
	}
}]);

angular.module("KhBase").factory("FarmSummaryFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/fms/farmsummary/', {}, {
        query: {
            params: {growing_season: '@growing_season'},
            url: '/api/v1.0/fms/farmsummary/',
            isArray: true
        }
    });
}]);


angular.module("AppContainer", [])
    .directive('appContainer', function () {
        return {
            restrict: 'E',
            templateUrl: '/static/assets/khapps2/base/app_container/app_container.html',
            controller: "@",
            name: "controllerName",
            scope: {
                apptemplatepath: '@'
            }
        };
    });
/**
 * Simple locally cached copy of a set of commonly used constants
 *
 * Values stored:
 *   - soils (i.e soil types)
 *   - firms
 *   - yield_units
 *   - crops
 *   - planting_season_list
 *   - destinations
 *   - store_types
 */
angular.module('khUtils').service('FarmConstantsService', function($resource) {
    var self=this;
    this.promise = $resource('/api/v1.0/fms/fmsconstants/').get(function(resp) {
        _.extend(self, resp);
    }).$promise;
});
angular.module("khUtils").component('khPager', {
    templateUrl: '/static/assets/khapps2/base/khutils/paginator/khpager.component.html',
    bindings: {
        pageSize: '<',
        total: '<',
        onUpdate: '&'
    },
    controller: function () {

        var self = this;
         
        function refresh() {
            self.onUpdate({page:self.current, pageSize: parseInt(self.pageSize)});
        }
        
        self.$onInit = function() {
            self.current = 1;
            self.numPages = 0;
        };

        self.$onChanges = function(changed) {
            self.current = 1;
            if (changed.total) {
                self.numPages = Math.ceil(changed.total.currentValue / self.pageSize);
            }
        };

        self.startOfPage = function() {
            return (self.current - 1) * self.pageSize + 1;
        };
        self.endOfPage = function() {
            return Math.min( self.current*self.pageSize, self.total );
        };

        self.next = function () {
            self.current++;
            refresh();
        };
        self.prev = function () {
            self.current--;
            refresh();
        };

        self.pageSizeChanged = function() {
            self.current = 1;
            self.numPages = Math.ceil(self.total / self.pageSize);
            refresh();
        };

        self.noPrevious = function() {
            return !self.numPages || !self.pageSize || self.current === 1;
        };

        self.noNext = function() {
            return !self.numPages || !self.pageSize || self.current === self.numPages;
        };
    }

});

angular.module("khUtils").service('JsonStorage', function($window) {
   var self=this;

    /**
     * Store an object in local storage
     * @param key
     * @param obj
     */
   self.set = function(key, obj) {
       $window.localStorage.setItem(key, angular.toJson(obj));
   };

    /**
     * Retrieve an object from local storage
     * @param key - key
     * @param defaultObj - default object if none found in storage
     * @return {Object|Array|string|number}
     */
   self.get = function(key, defaultObj) {
       var obj = $window.localStorage.getItem(key);
       if (obj) {
           return angular.fromJson(obj);
       } else {
           return defaultObj;
       }
   }
});
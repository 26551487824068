import * as Sentry from '@sentry/browser';

angular.module('khUtils').service("ErrorLoggingService", function ($log, $filter, $khMessage) {
    // Simple service that wraps Sentry
    var self=this;

    self.genericAPIError = function(error, duration) {
        if (!duration) duration = 300000;
        if (error.status === 400) {
            $khMessage.show($filter('translate')('error_message_400'), duration);
        } else if (error.status === 409) {
            $khMessage.show(error.data, duration);
        } else {
            $khMessage.show($filter('translate')('default_error_message'), duration);
        }
        if (error.data) {
            self.log(error.statusText, error.data);
        }
    };

    /**
     * Log a HTTP error response
     * @param resp
     */
    self.logErrorResponse = function(resp) {
        self.log(resp.statusText, resp.data);
    };

    self.log = function(msg, details) {
       if (Sentry) {
            Sentry.configureScope(scope => {
                scope.setExtra('details', details); // We need the whole trace
                Sentry.captureMessage(msg);
            });
        } else {
            $log.log(msg);
        }
    }
});

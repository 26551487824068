angular.module("khUtils").directive('khDialog', function ($mdDialog) {
    return {
        restrict: 'E',
        scope: false,
        link: function (scope, element, attrs) {
            scope.cancel = function () {
                $mdDialog.cancel();
            };
            scope.template = attrs['template'];
            scope.dialogtitle = attrs['dialogtitle'];
        },
        templateUrl: '/static/assets/khapps2/base/khutils/partials/kh_dialog.html'
    };
}).service('$khDialogClose', function ($mdDialog) {
    return function () {
        $mdDialog.hide();
    };
});

angular.module('khUtils').service("DateUtilsService", function () {
    this.toAPIDateFormat = function (dateobj) {
        return moment(dateobj).format("YYYY-MM-DD");
    };

    this.toAPIDateTimeFormat = function (dateobj) {
        return moment(dateobj).format('YYYY-MM-DDTHH:mm:ss');
    };

    this.get_readable_date = function (timestamp) {
        return moment(timestamp, "YYYY-MM-DDT00:00:00").format('DD MMMM YYYY');
    };

    this.get_date_object = function (timestamp) {
        return moment(timestamp, "YYYY-MM-DDT00:00:00").toDate()
    };

    this.get_timestamp_of_material_date = function (readable_date) {
        return moment(readable_date).format("YYYY-MM-DDT00:00:00");
    };

});



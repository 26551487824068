export default angular.module('kh.collapse', [])

	.directive('khCollapse', ['$animate', '$q', '$parse', '$injector', function ($animate, $q, $parse, $injector) {
		var $animateCss = $injector.has('$animateCss') ? $injector.get('$animateCss') : null;
		return {
			link: function (scope, element, attrs) {
				var expandingExpr = $parse(attrs.expanding),
					expandedExpr = $parse(attrs.expanded),
					collapsingExpr = $parse(attrs.collapsing),
					collapsedExpr = $parse(attrs.collapsed),
					horizontal = false,
					css = {},
					cssTo = {};

				init();

				function init() {
					horizontal = !!('horizontal' in attrs);
					if (horizontal) {
						css = {
							width: ''
						};
						cssTo = {width: '0'};
					} else {
						css = {
							height: ''
						};
						cssTo = {height: '0'};
					}
					if (!scope.$eval(attrs.khCollapse)) {
						element.addClass('in')
							.addClass('collapse')
							.attr('aria-expanded', true)
							.attr('aria-hidden', false)
							.css(css);
					}
				}

				function getScrollFromElement(element) {
					if (horizontal) {
						return {width: element.scrollWidth + 'px'};
					}
					return {height: element.scrollHeight + 'px'};
				}

				function expand() {
					if (element.hasClass('collapse') && element.hasClass('in')) {
						return;
					}

					$q.resolve(expandingExpr(scope))
						.then(function () {
							element.removeClass('collapse')
								.addClass('collapsing')
								.attr('aria-expanded', true)
								.attr('aria-hidden', false);

							if ($animateCss) {
								$animateCss(element, {
									addClass: 'in',
									easing: 'ease',
									css: {
										overflow: 'hidden'
									},
									to: getScrollFromElement(element[0])
								}).start()['finally'](expandDone);
							} else {
								$animate.addClass(element, 'in', {
									css: {
										overflow: 'hidden'
									},
									to: getScrollFromElement(element[0])
								}).then(expandDone);
							}
						});
				}

				function expandDone() {
					element.removeClass('collapsing')
						.addClass('collapse')
						.css(css);
					expandedExpr(scope);
				}

				function collapse() {
					if (!element.hasClass('collapse') && !element.hasClass('in')) {
						return collapseDone();
					}

					$q.resolve(collapsingExpr(scope))
						.then(function () {
							element
								.css(getScrollFromElement(element[0]))
								.removeClass('collapse')
								.addClass('collapsing')
								.attr('aria-expanded', false)
								.attr('aria-hidden', true);

							if ($animateCss) {
								$animateCss(element, {
									removeClass: 'in',
									to: cssTo
								}).start()['finally'](collapseDone);
							} else {
								$animate.removeClass(element, 'in', {
									to: cssTo
								}).then(collapseDone);
							}
						});
				}

				function collapseDone() {
					element.css(cssTo);
					element.removeClass('collapsing')
						.addClass('collapse');
					collapsedExpr(scope);
				}

				scope.$watch(attrs.khCollapse, function (shouldCollapse) {
					if (shouldCollapse) {
						collapse();
					} else {
						expand();
					}
				});
			}
		};
	}]);


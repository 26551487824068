angular.module("khUtils").directive('khFormDialog', function ($mdDialog) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        require: 'ngModel',
        scope: {
            'form': '=',
            'dialogtitle': '@',
            'show_progress_bar': '@',
            'fileuploadsconfig': '=',
            'imageuploadsconfig': '=',
            'buttonlabel': '@',
            'onSubmit': '&onSubmit',
            'onCancel': '&onCancel',
            'datamodel': '=ngModel'
        },
        link: function (scope, element, attrs, controllers) {
            scope.cancel = function () {
                $mdDialog.cancel();
            };
        },
        templateUrl: '/static/assets/khapps2/base/khutils/partials/kh_form_dialog.html'
    };
});
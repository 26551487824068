angular.module("khUtils").factory("SetTeamForCurrentSessionFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/khutils/setteamforcurrentsession/', {}, {

        set: {
            params: {team_slug: '@team_slug'},
            method: 'POST'
        },
        query: {
            isArray: false,
            method: 'GET'
        }

    });
}]);

angular
  .module('KhBase')
  .service('GenericMapService', function(
    WeatherUtilsService,
    WeatherService,
    $rootScope,
    $resource
  ) {
    var self = this;
    self.map = null;

    // ======================== FARM RELATED ================================================================

    this.load_farmdata = function(
      FarmFactory,
      $khPulse,
      $rootScope,
      leafletData,
      mapid
    ) {
      var message;
      $khPulse.show();
      FarmFactory.query(
        { all: 1 },
        function(data) {
          $rootScope.farmdata_geoJson = data[0];
          leafletData.getMap(mapid).then(function(map) {
            if ($rootScope.farmdata_geoJsonLayer) {
              map.removeLayer($rootScope.farmdata_geoJsonLayer);
              map.removeLayer($rootScope.farmdata_clusterLayer);
            }
            if (data[2] !== 0) {
              $rootScope.farms = [];
              var features = $rootScope.farmdata_geoJson['features'];
              for (var i = 0; i < features.length; i++) {
                var field_properties = features[i]['properties'];
                $rootScope.farms.push({
                  farm_id: field_properties.farm_id,
                  farm_name: field_properties.farm_name,
                  farm_slug: field_properties.farm_slug,
                  value: field_properties.farm_slug,
                  name: field_properties.farm_name
                });
              }
              $rootScope.farmdata_geoJsonLayer = self.createGeoJsonFarmLayer(
                data[0],
                $rootScope,
                leafletData,
                mapid
              );
              //map.addLayer($rootScope.farmdata_geoJsonLayer);
              $rootScope.farmdata_clusterLayer = self.createFarmMarkerClusterLayer(
                $rootScope.farmdata_geoJsonLayer,
                leafletData,
                mapid
              );
            }
          });
          $khPulse.hide();
        },
        function(error) {
          if (error.status) {
            message = 'Failed to query.';
          }
          $khPulse.hide();
        }
      );
    };

    this.createGeoJsonFarmLayer = function(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      return L.geoJson(result, {
        name: 'Farm',
        layer: 'FarmLayer',
        group: 'FarmField',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var inactiveIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'gray', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'green', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        marker = new L.marker(latlng, {
          icon: activeIcon,
          title: 'FarmLayer'
        });

        marker.on('click', getFeature);
        //$rootScope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    };

    this.createFarmMarkerClusterLayer = function(result, leafletData, mapid) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className: 'farm-marker-cluster farm-marker-cluster-' + clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(result);
      leafletData.getMap(mapid).then(function(map) {
        map.addLayer(markers);
        map.fitBounds(markers.getBounds());
      });
      return markers;
    };

    // ======================== FIELD RELATED ================================================================

    this.uploadNewFieldsToMap = function(
      feature_data,
      $rootScope,
      leafletData,
      mapid
    ) {
      if ($rootScope.leaflet_field_layer_id === -9999) {
        $rootScope.leaflet_field_layer_id = -9999;
        $rootScope.fielddata_geoJsonLayer = {};
      }

      if (feature_data.features) {
        angular.forEach(feature_data.features, function(value) {
          $rootScope.fielddata_geoJson.features.push(value);
        });
      } else {
        for (var i = 0, l = feature_data.length; i < l; i++) {
          var objValue = feature_data[i];
          $rootScope.fielddata_geoJson.features.push(objValue);
        }
      }

      var fieldLayer = self.createGeoJsonPolygonLayer(
        feature_data,
        $rootScope,
        leafletData,
        mapid
      );

      if ($rootScope.leaflet_field_layer_id === -9999) {
        $rootScope.fielddata_geoJsonLayer = fieldLayer;
        $rootScope.leaflet_field_layer_id =
          $rootScope.fielddata_geoJsonLayer._leaflet_id;
        leafletData.getMap(mapid).then(function(map) {
          map.addLayer($rootScope.fielddata_geoJsonLayer);
        });
      } else {
        leafletData.getMap(mapid).then(function(map) {
          for (var first in fieldLayer._layers) {
            $rootScope.fielddata_geoJsonLayer._layers[
              fieldLayer._layers[first]._leaflet_id
            ] = fieldLayer._layers[first];
          }
          fieldLayer.addTo(map);
        });
      }
    };

    this.addNewFieldsToMap = function(
      $khPulse,
      feature_data,
      $rootScope,
      leafletData,
      mapid
    ) {
      if (feature_data.features) {
        angular.forEach(feature_data.features, function(value) {
          $rootScope.fielddata_geoJson.features.push(value);
        });
      } else {
        for (var i = 0, l = feature_data.length; i < l; i++) {
          var objValue = feature_data[i];
          $rootScope.fielddata_geoJson.features.push(objValue);
        }
      }

      var fieldLayer = self.createGeoJsonPolygonLayer(
        feature_data,
        $rootScope,
        leafletData,
        mapid
      );

      leafletData.getMap(mapid).then(function(map) {
        for (var first in fieldLayer._layers) {
          $rootScope.fielddata_geoJsonLayer._layers[
            fieldLayer._layers[first]._leaflet_id
          ] = fieldLayer._layers[first];
        }
        fieldLayer.addTo(map);
        fieldLayer.bringToBack();
        $rootScope.fielddata_geoJsonLayer.bringToBack();
      });
      if ($rootScope.apiCallCount <= 0) {
        $rootScope.$broadcast(
          'completedFieldLoading',
          $rootScope.fielddata_geoJson
        );
        $khPulse.hide();
      }
    };

    this.createGeoJsonPolygonLayer = function(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      var getStyle = {
        weight: 2,
        opacity: 1,
        stroke: 'black',
        dashArray: 10,
        fillOpacity: 0.1
      };

      return L.geoJson(result, {
        name: 'Field',
        layer: 'FieldLayer',
        group: 'FarmField',
        onEachFeature: onEachFeature,
        style: getStyle
      });

      function onEachFeature(feature, layer) {
        feature.properties.bounds_calculated = layer.getBounds().toBBoxString();
        layer.on({ click: zoomToFeature });
        leafletData.getMap(mapid).then(function(map) {
          var label = L.marker(layer.getBounds().getCenter(), {
            icon: L.divIcon({
              className: 'label',
              html:
                '<span id=' +
                layer.feature.properties.field_slug +
                '>' +
                layer.feature.properties.field_name +
                '</span>',
              iconSize: [100, 50]
            })
          }).addTo(map);
          $('.leaflet-marker-icon').hide();
        });
      }

      function zoomToFeature(event, args) {
        $rootScope.tempMarker = this;
        leafletData.getMap(mapid).then(function(map) {
          map.fitBounds(event.target.getBounds(), { padding: [100, 100] });
          $rootScope.selectedFeature = event.target;
          $rootScope.originalFeature = event.target;
          $rootScope.$broadcast('changedSelectedFeature');
        });
      }
    };

    // ======================== STORE RELATED ================================================================

    this.load_storedata = function(
      StoreFactory,
      $khPulse,
      $rootScope,
      leafletData,
      mapid
    ) {
      StoreFactory.query(
        { 'data-format': 'geojson' },
        function(data) {
          //$khPulse.show()
          $rootScope.storedata_geoJson = data[0];
          leafletData.getMap(mapid).then(function(map) {
            if ($rootScope.storedata_geoJsonLayer) {
              map.removeLayer($rootScope.storedata_geoJsonLayer);
              map.removeLayer($rootScope.storedata_clusterLayer);
            }
            $rootScope.storedata_geoJsonLayer = self.createGeoJsonStorePointLayer(
              data,
              $rootScope,
              leafletData,
              mapid
            );
            $rootScope.storedata_clusterLayer = self.createStoreMarkerClusterLayer(
              $rootScope.storedata_geoJsonLayer,
              leafletData,
              mapid
            );
          });
          //$khPulse.hide(0)
        },
        function(error) {
          if (error.status) {
            message = 'Failed to query.';
            // $khMessage.show(message);
          }
          //$khPulse.hide(0)
        }
      );
    };

    this.createStoreMarkerClusterLayer = function(result, leafletData, mapid) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className:
              'store-marker-cluster store-marker-cluster-' + clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(result);
      leafletData.getMap(mapid).then(function(map) {
        map.addLayer(markers);
        //map.fitBounds(markers.getBounds());
      });
      return markers;
    };

    this.createGeoJsonStorePointLayer = function(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      return L.geoJson(result, {
        name: 'Store',
        layer: 'StoreLayer',
        group: 'FarmField',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var inactiveIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'gray', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'orange', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        marker = new L.marker(latlng, {
          icon: activeIcon,
          title: 'StoreLayer'
        });

        marker.on('click', getFeature);
        //$rootScope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    };

    // ======================== PLOT RELATED ================================================================

    this.load_plotdata = function(
      FieldPlotCropFactory,
      $khPulse,
      $rootScope,
      leafletData,
      mapid
    ) {
      if (angular.isDefined($rootScope.selected_growing_season)) {
        var query_params = {
          growing_season: $rootScope.selected_growing_season.value,
          all: 1
        };
        FieldPlotCropFactory.query(
          query_params,
          function(data) {
            $rootScope.plotdata_geoJson = data[0];
            leafletData.getMap(mapid).then(function(map) {
              if ($rootScope.leaflet_plot_layer_id !== -9999) {
                map.removeLayer($rootScope.plotdata_geoJsonLayer);
              }
              if (data[2] !== 0) {
                $rootScope.plotdata_geoJsonLayer = self.createGeoJsonPlotPolygonLayer(
                  data[0],
                  $rootScope,
                  leafletData,
                  mapid
                );
                $rootScope.leaflet_plot_layer_id =
                  $rootScope.plotdata_geoJsonLayer._leaflet_id;
                map.addLayer($rootScope.plotdata_geoJsonLayer);
                $rootScope.plotdata_geoJsonLayer.bringToFront();
              } else {
                message =
                  'No crop plots for selected growing season (' +
                  $rootScope.selected_growing_season.value +
                  ')';
              }
            });
          },
          function(error) {
            if (error.status) {
              message = 'Failed to query.';
            }
          }
        );
      }
    };

    this.createGeoJsonPlotPolygonLayer = function(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      var getStyle = function(feature) {
        var fillcolor = feature.properties.colour_code;
        return {
          color: fillcolor,
          fillColor: fillcolor,
          weight: 4,
          opacity: 1,
          fillOpacity: 0.7
        };
      };
      return L.geoJson(result, {
        name: 'Plot',
        layer: 'PlotLayer',
        group: 'FarmField',
        onEachFeature: onEachFeature,
        style: getStyle
      });

      function onEachFeature(feature, layer) {
        feature.properties.bounds_calculated = layer.getBounds().toBBoxString();
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        $rootScope.tempMarker = this;
        leafletData.getMap(mapid).then(function(map) {
          map.fitBounds(event.target.getBounds(), { padding: [100, 100] });
          $rootScope.selectedFeature = event.target;
          $rootScope.originalFeature = event.target;
          $rootScope.$broadcast('changedSelectedFeature');
        });
      }
    };

    this.addNewPlotsToMap = function(
      feature_data,
      $rootScope,
      leafletData,
      mapid
    ) {
      if (feature_data.features) {
        angular.forEach(feature_data.features, function(value) {
          $rootScope.plotdata_geoJson.features.push(value);
        });
      } else {
        for (var i = 0, l = feature_data.length; i < l; i++) {
          var objValue = feature_data[i];
          $rootScope.plotdata_geoJson.features.push(objValue);
        }
      }
      $rootScope.plotLayer = self.createGeoJsonPlotPolygonLayer(
        feature_data,
        $rootScope,
        leafletData,
        mapid
      );
      leafletData.getMap(mapid).then(function(map) {
        for (first in $rootScope.plotLayer._layers) break;
        $rootScope.plotdata_geoJsonLayer._layers[
          $rootScope.leaflet_plot_layer_id
        ] = $rootScope.plotLayer._layers[first];
        $rootScope.plotLayer.addTo(map);
        $rootScope.plotLayer.bringToFront();
      });
    };

    //================================================================================================

    this.getPostcodeGeocode = function($rootScope, postcode, callback) {
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: postcode }, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            var address = google_locations(results[0].address_components);
            if (address.region == null) {
              address.region = address.sub_region;
            }
            if (address.location == null) {
              address.location = address.sub_region;
            }
            if (address.location == null) {
              address.location = address.region;
            }

            $rootScope.geocoder.location = address.location;
            $rootScope.geocoder.address_line1 = address.address_line1;
            $rootScope.geocoder.address_line2 = address.address_line2;
            $rootScope.geocoder.sub_sub_region = address.sub_sub_region;
            $rootScope.geocoder.sub_region = address.sub_region;
            $rootScope.geocoder.region = address.region;
            $rootScope.geocoder.post_code = address.post_code;
            $rootScope.geocoder.country = address.country;
            $rootScope.geocoder.latitude = Number(
              results[0].geometry.location.lat().toFixed(8)
            );
            $rootScope.geocoder.longitude = Number(
              results[0].geometry.location.lng().toFixed(8)
            );
          }
        }
        callback();
      });
    };

    this.getMarkerGeocode = function($rootScope, layer, callback) {
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: {
            lat: layer._latlng.lat,
            lng: layer._latlng.lng
          }
        },
        function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var address = google_locations(results[0].address_components);
              if (address.region == null) {
                address.region = address.sub_region;
              }
              if (address.location == null) {
                address.location = address.sub_region;
              }
              if (address.location == null) {
                address.location = address.region;
              }

              $rootScope.geocoder.location = address.location;
              $rootScope.geocoder.address_line1 = address.address_line1;
              $rootScope.geocoder.address_line2 = address.address_line2;
              $rootScope.geocoder.sub_sub_region = address.sub_sub_region;
              $rootScope.geocoder.sub_region = address.sub_region;
              $rootScope.geocoder.region = address.region;
              $rootScope.geocoder.post_code = address.post_code;
              $rootScope.geocoder.country = address.country;
            }
          }
          $rootScope.geocoder.latitude = Number(layer._latlng.lat.toFixed(8));
          $rootScope.geocoder.longitude = Number(layer._latlng.lng.toFixed(8));
          callback();
        }
      );
    };

    function google_locations(address_components) {
      var address = {
        address_line1: null,
        address_line2: null,
        region: null,
        sub_region: null,
        sub_sub_region: null,
        location: null,
        post_code: null,
        country: null
      };

      for (var i = 0; i < address_components.length; i++) {
        for (var b = 0; b < address_components[i].types.length; b++) {
          if (address_components[i].types[b] == 'street_number') {
            address.address_line1 = address_components[i].long_name;
            break;
          }
          if (address_components[i].types[b] == 'route') {
            address.address_line2 = address_components[i].long_name;
            if (address.address_line1 == null) {
              address.address_line1 = address.address_line2;
            }
            break;
          }
          if (address_components[i].types[b] == 'locality') {
            address.location = address_components[i].long_name;
            break;
          }
          if (address_components[i].types[b] == 'postal_town') {
            address.sub_sub_region = address_components[i].long_name;
            if (address.location == null) {
              address.location = address.sub_sub_region;
            }
            break;
          }
          if (address_components[i].types[b] == 'administrative_area_level_2') {
            address.sub_region = address_components[i].long_name;
            break;
          }
          if (address_components[i].types[b] == 'administrative_area_level_1') {
            address.region = address_components[i].long_name;
            if (address.sub_region == null) {
              address.sub_region = address.region;
            }
            break;
          }
          if (address_components[i].types[b] == 'postal_code') {
            address.post_code = address_components[i].long_name;
            break;
          }
          if (address_components[i].types[b] == 'country') {
            address.country = address_components[i].short_name;
            break;
          }
        }
      }
      return address;
    }

    this.addUAVDataToMap = function(
      feature_data,
      $rootScope,
      leafletData,
      mapid
    ) {
      console.log('Printing : Sent email notification');
    };

    // Leaflet map functions

    this.getStyle = function(feature) {
      return {
        color: 'white',
        weight: 1,
        opacity: 1,
        dashArray: 10
      };
    };

    this.display_field_labels = function(
      $scope,
      mapid,
      leafletData,
      $rootScope
    ) {
      if (typeof $scope.geojson_fieldslabellayer_not_visible === 'undefined') {
        $scope.geojson_fieldslabellayer_not_visible = true;
      }
      if ($scope.center.zoom >= 15) {
        if ($scope.geojson_fieldslabellayer_not_visible) {
          $('.leaflet-marker-icon').show();
          $scope.geojson_fieldslabellayer_not_visible = false;
        }
      } else {
        if ($scope.geojson_fieldslabellayer_not_visible === false) {
          $('.leaflet-marker-icon').hide();
          $scope.geojson_fieldslabellayer_not_visible = true;
        }
      }
      leafletData.getMap(mapid).then(function(map) {
        $rootScope.mapBounds = map.getBounds();
      });
    };

    this.zoomtoExtent = function() {
      angular.extend($rootScope, {
        center: {
          lat: 54.5,
          lng: -1.89,
          zoom: 5
        }
      });
    };

    this.setDefaultMapView = function($rootScope, $scope) {
      var centerLat;
      var centerLong;
      var centerZoom;
      if (angular.isDefined($scope.platformSettings)) {
        if (angular.isDefined($scope.platformSettings.DEFAULT_CENTER_LAT)) {
          centerLat = $scope.platformSettings.DEFAULT_CENTER_LAT;
          centerLong = $scope.platformSettings.DEFAULT_CENTER_LONG;
          centerZoom = $scope.platformSettings.DEFAULT_CENTER_ZOOM;
        }
        if (angular.isDefined($rootScope.user_settings.DEFAULT_USER_FARM)) {
          var user_default_farm = _.filter($rootScope.farm_list, function(el) {
            return el.value === $rootScope.user_settings.DEFAULT_USER_FARM;
          });
          if (user_default_farm.length) {
            centerLat = user_default_farm[0].location_latitude;
            centerLong = user_default_farm[0].location_longitude;
            centerZoom = 15;
          }
        }
        if (angular.isDefined($rootScope.user_settings.DEFAULT_USER_FIELD)) {
          var user_default_field = _.filter($rootScope.farm_list, function(el) {
            return el.value === $rootScope.user_settings.DEFAULT_USER_FARM;
          });
          if (user_default_field.length) {
            centerLat = user_default_field[0].location_latitude;
            centerLong = user_default_field[0].location_longitude;
            centerZoom = 15;
          }
        }
        if (
          angular.isDefined($rootScope.selected_farm) &&
          $rootScope.selected_field.value !== 'All Farms'
        ) {
          centerLat = $rootScope.selected_farm.location_latitude;
          centerLong = $rootScope.selected_farm.location_longitude;
          centerZoom = 18;
        }
        if (
          angular.isDefined($rootScope.selected_field) &&
          $rootScope.selected_field.value !== 'All Fields'
        ) {
          centerLat = $rootScope.selected_field.location_latitude;
          centerLong = $rootScope.selected_field.location_longitude;
          centerZoom = 18;
        }
        $scope.center = {
          lat: centerLat,
          lng: centerLong,
          zoom: centerZoom
        };
      }
    };

    this.DEFINED_LAYERS = {
      googleHybrid: {
        name: 'Google Hybrid',
        type: 'google',
        layerType: 'HYBRID',
        noWarp: true
      },
      ESRI_satellite_layer: {
        name: 'ESRI Satellite',
        type: 'xyz',
        url:
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        noWrap: true
      },
      Esri_WorldGrayCanvas: {
        name: 'ESRI World Gray',
        type: 'xyz',
        url:
          'http://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
        maxZoom: 16,
        attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
        noWrap: true
      },
      OSM: {
        name: 'Openstreetmap',
        type: 'xyz',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        noWrap: true
      }
    };

    this.HERE_hybridDay = L.tileLayer(
      'http://{s}.{base}.maps.cit.api.here.com/maptile/2.1/{type}/{mapID}/hybrid.day/{z}/{x}/{y}/{size}/{format}?app_id={app_id}&app_code={app_code}&lg={language}',
      {
        attribution:
          'Map &copy; 1987-2014 <a href="http://developer.here.com">HERE</a>',
        subdomains: '1234',
        mapID: 'newest',
        app_id: '<your app_id>',
        app_code: '<your app_code>',
        base: 'aerial',
        maxZoom: 20,
        type: 'maptile',
        language: 'eng',
        format: 'png8',
        size: '256'
      }
    );

    this.buildmap = function(
      $scope,
      $rootScope,
      leafletData,
      mapid,
      mapSpeedDialList
    ) {
      var layer;
      var editableLayers = new L.FeatureGroup();

      if (ie_11) {
        $scope.IS_IE = true;
      }
      $scope.definedLayers = self.DEFINED_LAYERS;
      if (typeof mapSpeedDialList !== 'undefined') {
        $scope.mapSpeedDialList = mapSpeedDialList;
      }
      $rootScope.drawnItems = new L.FeatureGroup();

      leafletData.getMap(mapid).then(function(map) {
        $scope.show_overlay_layer_list_EmptyState = true;
        $scope.show_user_overlay_layer_list_EmptyState = true;
        map.addLayer(editableLayers);
        map.addLayer($rootScope.drawnItems);
        var omsOptions = {
          legWeight: 5,
          nearbyDistance: 50,
          keepSpiderfied: true
        };
        $scope.oms = new L.OverlappingMarkerSpiderfier(map, omsOptions);
        $rootScope.oms = new L.OverlappingMarkerSpiderfier(map, omsOptions);

        $scope.legend = L.control({ position: 'bottomleft' });
        $scope.legend.onAdd = function(map) {
          var div = L.DomUtil.create('div', 'info legend');
          div.innerHTML += "<p><h4 align='left'>Legend</></p>";
          div.innerHTML +=
            "<img src='" +
            $scope.process_legend +
            "' width='250' height='60' ></img>";
          return div;
        };

        $scope.sidebar = L.control.sidebar('mapSidebar', {
          position: 'left'
        });
        map.addControl($scope.sidebar);

        $scope.bottombar = L.control.bottombar('mapBottombar', {
          position: 'bottom'
        });
        map.addControl($scope.bottombar);

        $scope.locate = L.control.locate({ position: 'topleft' });
        map.addControl($scope.locate);

        $scope.zoombox = L.control.boxzoom({ position: 'topleft' });
        map.addControl($scope.zoombox);

        var optionsOCS = {
          // 3c38d15e76c02545181b07d3f8cfccf0 REPLACE WITH YOUR API-KEY. This key might go away any time!
          key: '72491cbd40544ce1a4158ff6a3bb742d',
          limit: 10
        };
        L.Control.OpenCageSearch.geocoder(optionsOCS);
        L.Control.openCageSearch(optionsOCS).addTo(map);

        L.control.mousePosition().addTo(map);
        L.control.scale().addTo(map);
        $rootScope.mapBounds = map.getBounds();
      });
      angular.extend($rootScope, {
        defaults: {
          maxZoom: 22,
          minZoom: 2,
          worldCopyJump: true,
          doubleClickZoom: true,
          scrollWheelZoom: true
        },
        center: {
          lat: $scope.default_latval,
          lng: $scope.default_lngval,
          zoom: $scope.default_zoomlevel
        },
        layers: {
          baselayers: {
            googleHybrid: {
              name: 'Google Hybrid',
              layerType: 'HYBRID',
              type: 'google'
            },
            googleTerrain: {
              name: 'Google Terrain',
              layerType: 'TERRAIN',
              type: 'google'
            },
            googleRoadmap: {
              name: 'Google Streets',
              layerType: 'ROADMAP',
              type: 'google'
            },
            ESRI: $scope.definedLayers.ESRI_satellite_layer,
            ESRIWC: $scope.definedLayers.Esri_WorldGrayCanvas,
            OSM: $scope.definedLayers.OSM
          },
          tileLayerOptions: {
            opacity: 0.9,
            detectRetina: true,
            reuseTiles: true
          },
          overlays: {
            draw: {
              name: 'draw',
              type: 'markercluster',
              visible: true,
              layerParams: {
                showOnSelector: false
              }
            }
          },
          controls: {
            zoomControl: { zoomControl: false, position: 'bottomright' },
            scale: { scale: true, position: 'bottomright' },
            draw: {
              draw: {
                marker: false,
                polyline: false,
                polygon: false,
                rectangle: false,
                circle: false
              }
            }
          }
        }
      });
      leafletData.getMap(mapid).then(function(map) {
        $rootScope.mapBounds = map.getBounds();
      });

      leafletData.getMap(mapid).then(function(map) {
        map.invalidateSize.bind(map);
        setTimeout(map.invalidateSize.bind(map));
      });
      return layer;
    };


    this.readPlatformSettings = function(PlatformSettingsFactory, $scope) {
      PlatformSettingsFactory.get(function(data) {
        $scope.platformSettings = data;
        $scope.default_plot_name_prefix = data.PLOT_NAME_PREFIX;
        $scope.default_plot_name_suffix = data.PLOT_NAME_SUFFIX;
        $scope.default_zoomlevel = data.DEFAULT_CENTER_ZOOM;
        $scope.default_latval = data.DEFAULT_CENTER_LAT;
        $scope.default_lngval = data.DEFAULT_CENTER_LONG;
        $scope.default_workable_area_contingency =
          data.DEFAULT_FIELD_WORKABLE_AREA_CONTINGENCY;
        if (typeof $scope.default_workable_area_contingency === 'undefined') {
          $scope.default_workable_area_contingency = 1;
        }
        $scope.planting_season_current = data.PLANTING_SEASON_CURRENT;
        $scope.khgeoserver = data.KISANSAT_UAVGEOSERVER_URL;
        $scope.ksatgeoserver = data.KISANSAT_SATGEOSERVER_URL;
        $scope.khauthserver = data.KH_INFRASTRUCTURE_AUTH_URL;

        $scope.khmapprintserver = data.KISANSAT_PRINTSERVER_URL;
        $scope.public_station_search_radius =
          data.WEATHER_PUBLIC_STATION_SEARCH_RADIUS;
        $scope.$broadcast('reading_platform_settings', data);
      });
    };


    this.create_overlay_layer_append_dict = function(fieldvalue, res, x) {
      return {
        field_slug: res[0],
        field_name:
          fieldvalue.properties.farm_name +
          '-' +
          fieldvalue.properties.field_name,
        process_name: res[1],
        name: x,
        value: x
      };
    };

    this.remove_overlay_layer_for_unmapped_field = function($scope, res) {
      for (var prop in $scope.layers['overlays']) {
        if ($scope.layers['overlays'][prop].field_slug === res[0]) {
          delete $scope.layers['overlays'][prop];
        }
      }
    };

    this.loadSatelliteData = function(
      $rootScope,
      $scope,
      SatelliteProcessedDataListFactory
    ) {
      self.legend_table($scope);
      $scope.showSatelliteImages = false;
      var queryParams = {};
      if ($scope.feature !== undefined)
        queryParams = { field_slug: $scope.feature.properties.field_slug };
      $scope.satellite_list = SatelliteProcessedDataListFactory.query(
        queryParams,
        function(result) {
          $scope.satellite_data_list = result;
          for (var i = 0; i < $scope.satellite_data_list.length; i++) {
            var layername =
              $scope.satellite_data_list[i].workspace +
              ':' +
              $scope.satellite_data_list[i].layername;
            var legendname = $scope.satellite_data_list[i].legendname;
            var raster_legend_options =
              '&LEGEND_OPTIONS=fontSize:10;dx:2;layout:vertical';
            var vector_legend_options =
              '&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:14;bgColor:0xFFFFEE;dpi:180';
            var process_name = $scope.satellite_data_list[i].processingtype;
            satellitelayer = {
              slug: $scope.satellite_data_list[i].slug,
              // field_name: get_field_Feature($rootScope, $scope.satellite_data_list[i].field_slug, 'field_slug'),
              farm_slug: $scope.satellite_data_list[i].farm_slug,
              field_slug: $scope.satellite_data_list[i].field_slug,
              field_plot_crop: $scope.satellite_data_list[i].fieldplotcrop,
              is_uav: true,
              acquisition_date: moment(
                $scope.satellite_data_list[i].acquisition_date
              ).format('DD MMM, YYYY'),
              satellite_name: $scope.satellite_data_list[i].resolution,
              process_name: process_name,
              name: $scope.satellite_data_list[i].displayname,
              type: 'wms',
              visible: false,
              legend:
                $scope.khgeoserver +
                '/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=2&LAYER=' +
                legendname +
                raster_legend_options,
              thumbnail_url:
                $scope.khgeoserver +
                '/geoserver/wms?STYLES=&LAYERS=' +
                $scope.satellite_data_list[i].layername +
                '&SERVICE=WMS&FORMAT=image/png&REQUEST=GETMAP&SRS=EPSG:4326&HEIGHT=250&WIDTH=500&VERSION=1.1.1&BBOX=' +
                $scope.bbox,
              url:
                $scope.khgeoserver +
                '/geoserver/' +
                $scope.satellite_data_list[i].workspace +
                '/wms',
              layerParams: {
                layers: $scope.satellite_data_list[i].layername,
                format: 'image/png',
                transparent: true,
                version: '1.1.0',
                attribution: 'KisanHub',
                showOnSelector: false,
                zIndex: 1000
              },
              layerOptions: {
                showOnSelector: false
              },
              group: process_name
            };
            $scope.showSatelliteImages = true;
            $scope.layers['overlays'][
              $scope.satellite_data_list[i].displayname
            ] = satellitelayer;
            $scope.show_user_overlay_layer_list_EmptyState = false;
          }
          var user_uploaded_overlay_layer_list = [];
          var unique = {};
          var distinct = [];
          var group_overlay_data = [];
          var count_layers = 0;

          for (var prop in $scope.layers['overlays']) {
            if ($scope.layers['overlays'][prop].is_uav) {
              group_overlay_data.push({
                group:
                  $scope.layers['overlays'][prop].field_slug +
                  '|' +
                  $scope.layers['overlays'][prop].group,
                overlay_layer_slug: prop
              });
              count_layers += 1;
            }
          }
          $scope.user_uploaded_overlaid_layers_count = count_layers;
          group_overlay_data.forEach(function(d) {
            if (!unique[d.group]) {
              distinct.push(d.group);
              unique[d.group] = true;
            }
          });
          distinct.forEach(function(x) {
            var res = x.split('|');
            var fieldvalue = self.get_field_Feature(
              $rootScope,
              res[0],
              'field_slug'
            );
            if (angular.isDefined(fieldvalue)) {
              user_uploaded_overlay_layer_list.push(
                self.create_overlay_layer_append_dict(fieldvalue, res, x)
              );
            } else {
              self.remove_overlay_layer_for_unmapped_field($scope, res);
            }
          });
          $scope.user_uploaded_overlay_layer_list = user_uploaded_overlay_layer_list.filter(
            function(e) {
              return e.value;
            }
          );
          if ($scope.user_uploaded_overlay_layer_list.length) {
            $scope.user_upload_selected_process =
              $scope.user_uploaded_overlay_layer_list[0].value;
            $scope.user_uploaded_overlay_layer_list = _.sortBy(
              $scope.user_uploaded_overlay_layer_list,
              'process_description'
            );
            $scope.user_uploaded_overlay_layer_list.reverse();
          }
        }
      );
    };
    this.get_kisansat_team_layers = function(
      $rootScope,
      $scope,
      SatellitePublishedDatasetResource,
      pageNum,
      team_slug
    ) {
      var format_json = 'json';
      SatellitePublishedDatasetResource.query(
        {
          format: format_json,
          team: team_slug,
          page: pageNum
        },
        function(result_data) {
          self.add_layer_to_overlay(
            $rootScope,
            $scope,
            result_data,
            SatellitePublishedDatasetResource,
            team_slug
          );
        }
      );
    };

    this.get_kisansat_field_layers = function(
      $rootScope,
      $scope,
      SatellitePublishedDatasetResource,
      pageNum,
      field1_slug
    ) {
      var format_json = 'json';
      SatellitePublishedDatasetResource.query(
        {
          format: format_json,
          field_slug: field1_slug,
          page: pageNum
        },
        function(result_data) {
          self.add_layer_to_overlay(
            $rootScope,
            $scope,
            result_data,
            SatellitePublishedDatasetResource,
            field1_slug
          );
        }
      );
    };

    this.remove_all_overlay_layers = function($scope) {
      for (var key in $scope.layers.overlays) {
        if (key !== 'draw') {
          delete $scope.layers.overlays[key];
        }
      }
      $scope.show_user_overlay_layer_list_EmptyState = true;
      $scope.show_overlay_layer_list_EmptyState = true;
      $scope.overlay_layer_list = [];
      $scope.user_overlay_layer_list = [];
    };

    this.build_ksat_overlay_layers_field_process_unique_list = function(
      $scope,
      $rootScope
    ) {
      $scope.show_field_overlay_layer_list_EmptyState = {};
      var overlay_layer_list = [];
      var unique = {};
      var distinct = [];
      var group_overlay_data = [];
      var count_layers = 0;
      for (var prop in $scope.layers['overlays']) {
        if ($scope.layers['overlays'][prop].is_uav === false) {
          group_overlay_data.push({
            group:
              $scope.layers['overlays'][prop].field_slug +
              '|' +
              $scope.layers['overlays'][prop].group,
            overlay_layer_slug: prop
          });
          count_layers += 1;
        }
      }
      $scope.overlaid_layers_count = count_layers;
      group_overlay_data.forEach(function(d) {
        if (!unique[d.group]) {
          distinct.push(d.group);
          unique[d.group] = true;
        }
      });
      distinct.forEach(function(x) {
        var res = x.split('|');
        if (res[0] !== 'undefined') {
          var fieldvalue = self.get_field_Feature(
            $rootScope,
            res[0],
            'field_slug'
          );
          if (angular.isDefined(fieldvalue)) {
            overlay_layer_list.push(
              self.create_overlay_layer_append_dict(fieldvalue, res, x)
            );
            $scope.show_field_overlay_layer_list_EmptyState[res[0]] = true;
          } else {
            self.remove_overlay_layer_for_unmapped_field($scope, res);
          }
        }
      });
      $scope.overlay_layer_list = overlay_layer_list.filter(function(e) {
        return e.value;
      });
      if ($scope.overlay_layer_list.length) {
        $scope.selected_process = $scope.overlay_layer_list[0].value;
        $scope.overlay_layer_list = _.sortBy(
          $scope.overlay_layer_list,
          'process_name'
        );
      }
    };

    this.add_layer_to_overlay = function(
      $rootScope,
      $scope,
      result_data,
      SatellitePublishedDatasetResource,
      field1_slug
    ) {
      $scope.kisansat_data_list = result_data.data;
      for (var i = 0; i < $scope.kisansat_data_list.length; i++) {
        var layername =
          $scope.kisansat_data_list[i].workspace +
          ':' +
          $scope.kisansat_data_list[i].layer;
        var legendname = layername;
        var raster_legend_options =
          '&LEGEND_OPTIONS=fontSize:10;dx:2;layout:vertical';
        var vector_legend_options =
          '&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:5;bgColor:0xFFFFFF;dpi:180';
        var display_name =
          $scope.kisansat_data_list[i].service.processing_type.display_name;
        var process_description =
          $scope.kisansat_data_list[i].service.processing_type.description;
        var process_name =
          $scope.kisansat_data_list[i].service.processing_type.process_name;
        var mode_upper_band = '';
        var total_area = '';
        if ($scope.kisansat_data_list[i].statistics) {
          var mode_upper_band =
            $scope.kisansat_data_list[i].statistics.mode_upper_band;
          var total_area = $scope.kisansat_data_list[i].statistics.total_area;
        }
        satellitelayer = {
          slug: $scope.kisansat_data_list[i].slug,
          // field_name: get_field_Feature($rootScope, $scope.kisansat_data_list[i].field_slug, 'field_slug'),
          field_slug: $scope.kisansat_data_list[i].field_slug,
          is_uav: false,
          metadata: $scope.kisansat_data_list[i],
          acquisition_date: $scope.kisansat_data_list[i].acquisition_timestamp,
          satellite_name:
            $scope.kisansat_data_list[i].service.satellite_data_provider
              .satellite_name,
          process_name: process_name,
          mode_upper_band: mode_upper_band,
          total_area: total_area,
          thumbnail_url: $scope.kisansat_data_list[i].thumbnail_url,
          rgb_thumbnail_url: $scope.kisansat_data_list[i].thumbnail_url.replace(
            process_name,
            'RGB'
          ),
          large_thumbnail_url: $scope.kisansat_data_list[i].large_image_url,
          rgb_large_thumbnail_url: $scope.kisansat_data_list[
            i
          ].large_image_url.replace(process_name, 'RGB'),
          name: $scope.kisansat_data_list[i].layer,
          type: 'wms',
          visible: false,
          legend:
            $scope.ksatgeoserver +
            '/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=10&HEIGHT=5&LAYER=' +
            legendname +
            vector_legend_options,
          url:
            $scope.ksatgeoserver +
            '/geoserver/' +
            $scope.kisansat_data_list[i].workspace +
            '/wms',
          layerParams: {
            layers: layername,
            format: 'image/png',
            transparent: true,
            version: '1.1.0',
            attribution: 'KisanHub',
            showOnSelector: false,
            zIndex: 1000
          },
          layerOptions: {
            showOnSelector: false
          },
          group: display_name
        };
        $scope.show_overlay_layer_list_EmptyState = false;
        $scope.showSatelliteImages = true;
        $scope.layers['overlays'][
          $scope.kisansat_data_list[i].layer
        ] = satellitelayer;
      }
      if (result_data.next) {
        newPage = result_data.current_page + 1;
        var url = new URL(result_data.next);
        var team_slug = url.searchParams.get('team');
        field1_slug = url.searchParams.get('field_slug');
        if (team_slug) {
          self.get_kisansat_team_layers(
            $rootScope,
            $scope,
            SatellitePublishedDatasetResource,
            newPage,
            team_slug
          );
        } else {
          self.get_kisansat_field_layers(
            $rootScope,
            $scope,
            SatellitePublishedDatasetResource,
            newPage,
            field1_slug
          );
        }
      } else {
        self.build_ksat_overlay_layers_field_process_unique_list(
          $scope,
          $rootScope
        );
      }
    };

    this.loadFarmData = function(
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      FarmFactory
    ) {
      $rootScope.leaflet_farm_layer_id = -9999;
      $rootScope.farmdata_geoJson = {};
      $rootScope.farmdata_geoJsonLayer = {};
      $rootScope.farmdata_clusterLayer = {};
      self.load_farmdata(FarmFactory, $khPulse, $rootScope, leafletData, mapid);
    };

    this.loadPWSData = function(
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      PWSFactory,
      $khMessage,
      NgTableParams,
      $scope
    ) {
      $rootScope.leaflet_pws_layer_id = -9999;
      $rootScope.pwsdata_geoJson = {};
      $rootScope.pwsdata_geoJsonLayer = {};
      $rootScope.pwsdata_clusterLayer = {};
      self.load_pws_data(
        PWSFactory,
        $khPulse,
        $rootScope,
        leafletData,
        mapid,
        $khMessage,
        NgTableParams,
        $scope
      );
    };

    this.loadPublicSourceData = function(
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      EADataResource,
      query_parameters,
      $khMessage,
      NgTableParams,
      $scope
    ) {
      $rootScope.leaflet_public_source_layer_id = -9999;
      $rootScope.public_source_data_geoJson = {};
      $rootScope.public_source_data_geoJsonLayer = {};
      $rootScope.public_source_data_clusterLayer = {};
      self.load_public_source_data(
        EADataResource,
        $khPulse,
        $rootScope,
        leafletData,
        mapid,
        query_parameters,
        $khMessage,
        NgTableParams,
        $scope
      );
    };

    this.loadFieldPlotCropData = function(
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      FieldPlotCropFactory
    ) {
      $rootScope.leaflet_plot_layer_id = -9999;
      $rootScope.plotdata_geoJson = {};
      $rootScope.plotdata_geoJsonLayer = {};
      self.load_plotdata(
        FieldPlotCropFactory,
        $khPulse,
        $rootScope,
        leafletData,
        mapid
      );
    };

    this.loadStoreLocationData = function(
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      StoreFactory
    ) {
      $rootScope.leaflet_store_layer_id = -9999;
      $rootScope.storedata_geoJson = {};
      $rootScope.storedata_geoJsonLayer = {};
      $rootScope.storedata_clusterLayer = {};
      self.load_storedata(
        StoreFactory,
        $khPulse,
        $rootScope,
        leafletData,
        mapid
      );
    };

    // Weather

    this.load_pws_data = function(
      PWSFactory,
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      $khMessage,
      NgTableParams,
      $scope
    ) {
      PWSFactory.query({ data_format: 'geojson' }, function(data) {
        $rootScope.pwsdata_geoJson = data.data[0];

        if (typeof $scope !== 'undefined') {
          $scope.weatherStationTableParameters = new NgTableParams(
            {},
            { dataset: $rootScope.pwsdata_geoJson.features }
          );
          $rootScope.PWSApiJsonData = $rootScope.pwsdata_geoJson.features;
          $scope.isEmptyTableDataMessage =
            $rootScope.pwsdata_geoJson.features.length === 0;
          if ($scope.isEmptyTableDataMessage) {
            $khMessage.show('No weather station found', 5000);
          }
        }
        self.calculateStationDistanceFromSelectedLocation(
          data.data[0].features
        );

        leafletData.getMap(mapid).then(function(map) {
          if ($rootScope.pwsdata_geoJsonLayer) {
            map.removeLayer($rootScope.pwsdata_geoJsonLayer);
            map.removeLayer($rootScope.pwsdata_clusterLayer);
          }
          if (data.data[0].length !== 0) {
            $rootScope.pws_list = [];
            var features = $rootScope.pwsdata_geoJson['features'];
            for (var i = 0; i < features.length; i++) {
              var field_properties = features[i]['properties'];
              $rootScope.pws_list.push({
                value: field_properties.slug,
                name: field_properties.provider_reference
              });
            }
            $rootScope.pwsdata_geoJsonLayer = createGeoJsonPWSLayer(
              data.data[0],
              $rootScope,
              leafletData,
              mapid
            );
            $rootScope.pwsdata_clusterLayer = createPWSMarkerClusterLayer(
              $rootScope.pwsdata_geoJsonLayer,
              leafletData,
              mapid
            );
          } else {
            message = 'No Polygon Data';
          }
        });
      });
    };

    function createGeoJsonPWSLayer(result, $rootScope, leafletData, mapid) {
      var inactiveIcon = L.AwesomeMarkers.icon({
        prefix: 'fa', //font awesome rather than bootstrap
        markerColor: 'red', // see colors above
        icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
      });
      var activeIcon = L.AwesomeMarkers.icon({
        prefix: 'fa', //font awesome rather than bootstrap
        markerColor: 'blue', // see colors above
        icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
      });
      var activePartialIcon = L.AwesomeMarkers.icon({
        prefix: 'fa', //font awesome rather than bootstrap
        markerColor: 'orange', // see colors above
        icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
      });

      return L.geoJson(result, {
        name: 'PWS',
        layer: 'StationLayer',
        group: 'Sensors',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        if (
          feature.properties.status &&
          feature.properties.status.status === 0
        ) {
          marker = new L.marker(latlng, {
            icon: activeIcon,
            title: 'StationLayer'
          });
        } else if (
          feature.properties.status &&
          feature.properties.status.status === 1
        ) {
          marker = new L.marker(latlng, {
            icon: activePartialIcon,
            title: 'StationLayer'
          });
        } else {
          marker = new L.marker(latlng, {
            icon: inactiveIcon,
            title: 'StationLayer'
          });
        }

        marker.on('click', getFeature);
        //$rootScope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    }

    function createPWSMarkerClusterLayer(result, leafletData, mapid) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className: 'pws-marker-cluster pws-marker-cluster-' + clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(result);
      leafletData.getMap(mapid).then(function(map) {
        map.addLayer(markers);
        //map.fitBounds(markers.getBounds());
      });
      return markers;
    }

    this.calculateStationDistanceFromSelectedLocation = function(stations) {
      var selectedCoordinates = self.getSelectionCoordinates($rootScope);
      if (selectedCoordinates) {
        var selectedPointLatLng = new L.LatLng(
          selectedCoordinates.latitude,
          selectedCoordinates.longitude
        );
        _.forEach(stations, function(station) {
          var point = new L.LatLng(
            station.geometry.coordinates[1],
            station.geometry.coordinates[0]
          );
          // calculate distance from selectedPoint (in km)
          station.distance = point.distanceTo(selectedPointLatLng) / 1000;
        });
      } else {
        _.forEach(stations, function(station) {
          station.distance = null;
        });
      }
      return stations;
    };

    this.getSelectionCoordinates = function($rootScope) {
      if (
        $rootScope.fielddata_geoJson &&
        typeof $rootScope.fielddata_geoJson['features'] !== 'undefined' &&
        $rootScope.selected_field &&
        $rootScope.selected_field.value !== 'All Fields'
      ) {
        return getSelectedFieldCoordinates($rootScope);
      } else if (
        $rootScope.farmdata_geoJson &&
        typeof $rootScope.farmdata_geoJson['features'] !== 'undefined' &&
        $rootScope.selected_farm &&
        $rootScope.selected_farm.value !== 'All Farms'
      ) {
        return getSelectedFarmCoordinates($rootScope);
      } else {
        return null;
      }
    };

    function getSelectedFieldCoordinates($rootScope) {
      var field_features = $rootScope.fielddata_geoJson['features'];
      for (var j = 0; j < field_features.length; j++) {
        var field_properties = field_features[j]['properties'];
        if (field_properties.field_slug === $rootScope.selected_field.value) {
          var field_lat = field_properties.field_latitude;
          var field_lon = field_properties.field_longitude;
          return {
            latitude: field_lat,
            longitude: field_lon
          };
        }
      }
    }

    function getSelectedFarmCoordinates($rootScope) {
      var features = $rootScope.farmdata_geoJson['features'];
      for (var i = 0; i < features.length; i++) {
        var farm_properties = features[i]['properties'];
        if (farm_properties.farm_slug === $rootScope.selected_farm.value) {
          var farm_lat = features[i]['geometry'].coordinates[1];
          var farm_lon = features[i]['geometry'].coordinates[0];
          return {
            latitude: farm_lat,
            longitude: farm_lon
          };
        }
      }
    }

    this.load_public_source_data = function(
      EADataResource,
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      query_parameters,
      $khMessage,
      NgTableParams,
      $scope
    ) {
      EADataResource.query(
        query_parameters,
        function(data) {
          $rootScope.public_source_data_geoJson = data[0];
          if (typeof $scope !== 'undefined') {
            $scope.EATableDataParameters = new NgTableParams(
              {},
              { dataset: data[0].features }
            );
            $scope.isEmptyTableDataMessage = data[0].features.length === 0;
            $rootScope.EAJsonData = data;
            if ($scope.isEmptyTableDataMessage) {
              $khMessage.show('No public data source found', 5000);
            }
          }
          self.calculateStationDistanceFromSelectedLocation(
            $rootScope.public_source_data_geoJson.features
          );
          leafletData.getMap(mapid).then(function(map) {
            if ($rootScope.public_source_data_geoJsonLayer) {
              map.removeLayer($rootScope.public_source_data_geoJsonLayer);
              map.removeLayer($rootScope.public_source_data_clusterLayer);
            }
            if (data[0].length !== 0) {
              $rootScope.public_source_list = [];
              var features = $rootScope.public_source_data_geoJson['features'];
              for (var i = 0; i < features.length; i++) {
                var field_properties = features[i]['properties'];
                $rootScope.public_source_list.push({
                  value: field_properties.slug,
                  name: field_properties.provider_reference
                });
              }
              $rootScope.public_source_data_geoJsonLayer = createGeoJsonPublicSourceLayer(
                data[0],
                $rootScope,
                leafletData,
                mapid
              );
              $rootScope.public_source_data_clusterLayer = createPublicSourceMarkerClusterLayer(
                $rootScope.public_source_data_geoJsonLayer,
                leafletData,
                mapid
              );
            } else {
              message = 'No Polygon Data';
            }
          });
        },
        function(error) {
          if (error.status) {
            message = 'Failed to query.';
            $khMessage.show(message);
          }
        }
      );
    };

    function createGeoJsonPublicSourceLayer(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      return L.geoJson(result, {
        name: 'Public Source',
        layer: 'PublicSourceLayer',
        group: 'Sensors',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;

        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'gray', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });

        var inactiveIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'red', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });

        marker = new L.marker(latlng, {
          icon: activeIcon,
          title: 'PublicSourceLayer'
        });

        marker.on('click', getFeature);
        //$rootScope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    }

    function createPublicSourceMarkerClusterLayer(result, leafletData, mapid) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className: 'ea-marker-cluster ea-marker-cluster-' + clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(result);
      leafletData.getMap(mapid).then(function(map) {
        map.addLayer(markers);
      });
      return markers;
    }

    function createGeoJsonFieldSensorLayer(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      var inactiveSensorIcon = L.AwesomeMarkers.icon({
        prefix: 'fa', //font awesome rather than bootstrap
        markerColor: 'gray', // see colors above
        icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
      });
      var activeSensorIcon = L.AwesomeMarkers.icon({
        prefix: 'fa', //font awesome rather than bootstrap
        markerColor: 'purple', // see colors above
        icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
      });

      return L.geoJson(result, {
        name: 'FieldSensor',
        layer: 'FieldSensorLayer',
        group: 'Sensors',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        if (feature.properties.status) {
          marker = new L.marker(latlng, {
            icon: activeSensorIcon,
            title: 'FieldSensorLayer'
          });
        } else {
          marker = new L.marker(latlng, {
            icon: inactiveSensorIcon,
            title: 'FieldSensorLayer'
          });
        }

        marker.on('click', getFeature);
        //$rootScope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    }

    function createFieldSensorMarkerClusterLayer(result, leafletData, mapid) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className:
              'sensor-marker-cluster sensor-marker-cluster-' + clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(result);
      leafletData.getMap(mapid).then(function(map) {
        map.addLayer(markers);
        //map.fitBounds(markers.getBounds());
      });
      return markers;
    }

    this.zoom_to_searched_feature = function(
      filter_field_feature,
      leafletData,
      mapid,
      $rootScope
    ) {
      var zoom = 16;
      if (filter_field_feature._leaflet_id) {
        var bounds = filter_field_feature.getBounds();
        if (angular.isDefined(bounds)) {
          if (self.map) {
            self.map.setView(bounds.getCenter(), zoom);
            self.map = null;
          } else {
            leafletData.getMap(mapid).then(function(map) {
              map.fitBounds(bounds, { padding: [100, 100] });
              $rootScope.mapBounds = bounds;
            });
          }
        }
      }
    };

    this.query_feature_layer = function(
      searchString,
      geojsonfeature,
      featureField
    ) {
      return L.geoJson(geojsonfeature, {
        filter: function(feature, layer) {
          return feature.properties[featureField] === searchString;
        }
      });
    };

    this.remove_layer = function(mapid, $rootScope, leafletData, layerName) {
      leafletData.getMap(mapid).then(function(map) {
        map.removeLayer(layerName);
        $rootScope.selectedFeature = null;
      });
    };

    this.getWeatherForecastDataForSelectedFeatureLocation = function(
      $rootScope,
      $scope,
      slug,
      type,
      callback
    ) {
      var queryParams = {
        start_date: getDbDateDailyFormatDay(addDays(moment(), 0)),
        end_date: getDbDateDailyFormatDay(addDays(moment(), 4)),
        parameters:
          'precipitation,temperature,wind_speed,wind_direction,pressure,weather_type,dew_point,relative_humidity,ref_et_penman',
        service_name: 'METEOMATICS-ACTUAL_AND_FORECAST',
        account_key: 'kisanhub-account'
      };
      var slugType = { slug: slug, type: type };
      WeatherService.getWeatherData('forecast', slugType, queryParams).then(
        function(apiData) {
          if (apiData) {
            if (apiData.data) {
              $scope.forecastHourlyData = apiData.data.hourly;
              $scope.forecastDailyData = apiData.data.daily;
              $scope.forecastUpdateTime = apiData.metadata.last_updated;
            }
          } else {
          }
          callback();
        },
        function(errorResponse) {
          $scope.forecastHourlyData = [];
          $scope.forecastDailyData = [];
          $scope.forecastUpdateTime = null;
          callback();
        }
      );
    };

    this.respondToFeatureSelectionChange = function(
      mapid,
      $khPulse,
      $rootScope,
      $scope,
      LegacyOperationFactory,
      DateTimeHelperFactory,
      StoreLotFactory,
      SoilTestFactory,
      EADataResource,
      SatelliteProcessedDataListFactory,
      SatellitePublishedDatasetResource,
      UserSettingsService
    ) {
      $scope.showSatelliteImages = false;
      if (typeof $scope.sidebar !== 'undefined') {
        if ($scope.sidebar.isVisible() === false) {
          $scope.sidebar.toggle();
        }
      }
      $rootScope.$watch('selectedFeature', function(newValue, oldValue) {
        if (newValue) {
          $scope.showEdit = false;
          $scope.feature = newValue.feature;
          if (typeof newValue.options.layer === 'undefined') {
            newValue.options.layer = newValue.options.title;
          }
          switch (newValue.options.layer) {
            case 'FieldLayer':
              if ($rootScope.page_id === 'farm') $scope.showEdit = true;
              growing_season = $rootScope.selected_growing_season.value;
              $khPulse.show();
              self.getWeatherForecastDataForSelectedFeatureLocation(
                $rootScope,
                $scope,
                $scope.feature.properties.field_slug,
                'field'
              );
              LegacyOperationFactory.get(
                {
                  growing_season: growing_season,
                  field: $scope.feature.properties.field_slug
                },
                function(apiData) {
                  $scope.operationsData = [];
                  if (apiData) {
                    $scope.operationsData = apiData.operations.filter(function(
                      operation
                    ) {
                      operation.readable_date = DateTimeHelperFactory.convertDateFormatUsingMoment(
                        operation.date
                      );
                      return operation;
                    });
                  }

                  $scope.sidebar_path = '/static/assets/khapps/fms_app/myfarm/map/sidebar/field/field-sidebar.directive.html';
                  $khPulse.hide(0);
                },
                function(error) {
                  $scope.operationsData = [];
                  $scope.sidebar_path =
                      '/static/assets/khapps/fms_app/myfarm/map/sidebar/field/field-sidebar.directive.html';
                  $khPulse.hide(0);
                }
              );
              break;
            case 'PlotLayer':
              if ($rootScope.page_id === 'farm') $scope.showEdit = true;
              self.getWeatherForecastDataForSelectedFeatureLocation(
                $rootScope,
                $scope,
                $scope.feature.properties.plot_slug,
                'plot'
              );
              $khPulse.show();
              LegacyOperationFactory.get(
                {
                  growing_season: $rootScope.selected_growing_season.value,
                  plot: $scope.feature.properties.plot_slug
                },
                function(apiData) {
                  if (apiData) {
                    $scope.operationsData = apiData.operations.filter(function(
                      operation
                    ) {
                      operation.readable_date = DateTimeHelperFactory.convertDateFormatUsingMoment(
                        operation.date
                      );
                      return operation;
                    });
                  }
                  $scope.sidebar_path =
                    '/static/assets/khapps/fms_app/myfarm/map/sidebar/plot/plot-sidebar.directive.html';
                  $khPulse.hide(0);
                },
                function(error) {
                  $scope.operationsData = [];
                  $scope.sidebar_path =
                    '/static/assets/khapps/fms_app/myfarm/map/sidebar/plot/plot-sidebar.directive.html';
                  $khPulse.hide(0);
                }
              );
              break;
            default:
              if (newValue.feature.properties.hasOwnProperty('logger_slug')) {
                $khPulse.show();
                if ($rootScope.page_id === 'weather') $scope.showEdit = true;
                $scope.sidebar_path =
                  '/static/assets/khapps/weather/partials/mapSidebar/logger_sidebar.html';
                $khPulse.hide();
              } else if (newValue.feature.properties.hasOwnProperty('status')) {
                $khPulse.show();
                $scope.parameterUINameConvertorMap = WeatherUtilsService.parameterUINameConvertorMap();
                $scope.parameterUnitMap = WeatherUtilsService.parameterUnitMap();
                $scope.userSettingDateFormat = $rootScope.userSettingDateFormat;
                $scope.latestGatewayData = null;
                var apiQueryParams = {
                  frequency: 'instantaneous',
                  gateway_slug: String(newValue.feature.properties.slug),
                  node_slug: String(newValue.feature.properties.nodes[0].slug),
                  data_format: 'json',
                  localize: '1'
                };
                $resource('/api/weather/sensor-data/').get(
                  apiQueryParams,
                  function(apiData) {
                    if (apiData.data) {
                      $scope.latestGatewayData = apiData.data.pop();
                    }
                    if ($rootScope.page_id === 'weather')
                      $scope.showEdit = true;
                    $scope.sidebar_path =
                      '/static/assets/khapps/weather/partials/mapSidebar/pws_sidebar.html';
                    $khPulse.hide(0);
                  },
                  function(error) {
                    if ($rootScope.page_id === 'weather')
                      $scope.showEdit = true;
                    $scope.sidebar_path =
                      '/static/assets/khapps/weather/partials/mapSidebar/pws_sidebar.html';
                    $khPulse.hide(0);
                  }
                );
              } else if (
                newValue.feature.properties.hasOwnProperty('soil_sample_slug')
              ) {
                if ($rootScope.page_id === 'farm') $scope.showEdit = true;
                SoilTestFactory.query(
                  { sample: newValue.feature.properties.soil_sample_slug },
                  function(result_data) {
                    $scope.soilTests = result_data;
                  },
                  function(error) {
                    $scope.soilTests = [];
                  }
                );
                $scope.sidebar_path =
                  '/static/assets/khapps/fms_app/myfarm/map/sidebar/soil-sample/soil-sample-sidebar.directive.html';
              } else if (
                newValue.feature.properties.hasOwnProperty('store_type')
              ) {
                $khPulse.show();
                if ($rootScope.page_id === 'store') $scope.showEdit = true;
                StoreLotFactory.query(
                  { store_slug: $scope.feature.properties.slug },
                  function(data) {
                    $scope.storelots = data;
                  },
                  function(error) {
                    $scope.storelots = [];
                  }
                );
                $scope.sidebar_path =
                  '/static/assets/khapps/fms_app/myfarm/map/sidebar/store/store-sidebar.directive.html';
                $khPulse.hide(0);
              } else if (
                newValue.feature.properties.hasOwnProperty('farm_slug')
              ) {
                function initFarmSideBar() {
                  if ($rootScope.page_id === 'farm') $scope.showEdit = true;
                  $scope.sidebar_path =
                    '/static/assets/khapps/fms_app/myfarm/map/sidebar/farm/farm-sidebar.directive.html';
                }
                self.getWeatherForecastDataForSelectedFeatureLocation(
                  $rootScope,
                  $scope,
                  $scope.feature.properties.farm_slug,
                  'farm',
                  initFarmSideBar
                );
              } else if (newValue.feature.properties.hasOwnProperty('slug')) {
                $scope.public_source_info = newValue.feature.properties;
                $scope.public_source_geometry = newValue.feature.geometry;
                $scope.latestEAData = newValue.feature.latestReading;
                $scope.pds_data = newValue.feature;
                $scope.pds_distance = newValue.feature.distance;
                $scope.sidebar_path =
                  '/static/assets/khapps/weather/partials/mapSidebar/public_source_sidebar.html';
              } else {
              }
              break;
          }
          $scope.sidebar.show();
        }
      });
    };

    function getDbDateDailyFormatDay(moment_object) {
      return moment_object.format('YYYY-MM-DD');
    }

    function addDays(moment_object, no_of_days) {
      return moment_object.add(no_of_days, 'days');
    }

    this.mapbased_filter_watchers = function($rootScope, leafletData, mapid) {
      $rootScope.$watch('selected_station', function(
        newValue,
        oldValue,
        $rootScope
      ) {
        $rootScope.zoom_to_selected_feature(
          $rootScope,
          mapid,
          newValue,
          oldValue,
          $rootScope.pwsdata_geoJsonLayer,
          'All Stations',
          $rootScope.pwsdata_geoJson,
          'slug',
          'slug'
        );
      });

      $rootScope.$watch('selected_farm', function(
        newValue,
        oldValue,
        $rootScope
      ) {
          $rootScope.zoom_to_selected_feature(
            $rootScope,
            mapid,
            newValue,
            oldValue,
            $rootScope.farmdata_geoJsonLayer,
            'All Farms',
            $rootScope.farmdata_geoJson,
            'farm_slug',
            'farm_slug'
          );
      });

      $rootScope.$watch('selected_field', function(
        newValue,
        oldValue,
        $rootScope
      ) {
        $rootScope.zoom_to_selected_feature(
          $rootScope,
          mapid,
          newValue,
          oldValue,
          $rootScope.fielddata_geoJsonLayer,
          'All Fields',
          $rootScope.fielddata_geoJson,
          'field_slug',
          'farm_slug'
        );
      });


      $rootScope.zoom_to_selected_feature = function(
        $rootScope,
        mapid,
        newValue,
        oldValue,
        geojsonLayer,
        all,
        geojsonFeature,
        key_to_match_1,
        key_to_match_2
      ) {
        if (
          angular.isDefined(newValue) &&
          angular.isDefined(geojsonLayer) &&
          angular.isDefined(geojsonFeature) &&
          geojsonLayer.hasOwnProperty('_layers') &&
          geojsonFeature.hasOwnProperty('type')
        ) {
          var filter_field_feature;
          var searchString = newValue;
          if (newValue && newValue.hasOwnProperty('value')) {
            searchString = newValue.value;
          }
          if (oldValue != newValue && geojsonFeature.features.length > 0) {
            if (newValue !== all) {
              filter_field_feature = self.query_feature_layer(
                searchString,
                geojsonFeature.features,
                key_to_match_1
              );
              self.zoom_to_searched_feature(
                filter_field_feature,
                leafletData,
                mapid,
                $rootScope
              );
            } else if (key_to_match_2 !== 'undefined') {
              filter_field_feature = self.query_feature_layer(
                searchString,
                geojsonFeature.features,
                key_to_match_2
              );
              self.zoom_to_searched_feature(
                filter_field_feature,
                leafletData,
                mapid,
                $rootScope
              );
            }
          } else {
            self.zoom_to_searched_feature(
              geojsonLayer,
              leafletData,
              mapid,
              $rootScope
            );
          }
        }
      };

      $rootScope.reset_map_view = function(
        window,
        timeout,
        leafletData,
        mapid
      ) {
        $(window).trigger('resize');

        leafletData.getMap(mapid).then(function(map) {
          timeout(function() {
            map.invalidateSize();
          }, 500);
        });
      };
    };

    this.get_field_Feature = function(
      $rootScope,
      searchstring,
      search_field_name
    ) {
      if (angular.isDefined($rootScope.fielddata_geoJson)) {
        for (var i = 0; i < $rootScope.fielddata_geoJson.features.length; i++) {
          if (
            searchstring ===
            $rootScope.fielddata_geoJson.features[i].properties[
              search_field_name
            ]
          ) {
            return $rootScope.fielddata_geoJson.features[i];
          }
        }
      }
    };

    this.reset_map_view = function(
      $rootScope,
      leafletData,
      window,
      timeout,
      mapid
    ) {
      $(window).trigger('resize');
      leafletData.getMap(mapid).then(function(map) {
        timeout(function() {
          map.invalidateSize();
        }, 500);
      });
    };

    this.get_this_location_on_map = function(
      $rootScope,
      FMSConstants,
      type,
      slug
    ) {
      var mapid = 'landingMap';
      switch (type) {
        case 'farm':
          $rootScope.zoom_to_selected_feature(
            $rootScope,
            mapid,
            slug,
            '',
            $rootScope.farmdata_geoJsonLayer,
            FMSConstants.All,
            $rootScope.farmdata_geoJson,
            'farm_slug',
            'farm_slug'
          );
          break;
        case 'field':
          $rootScope.zoom_to_selected_feature(
            $rootScope,
            mapid,
            slug,
            '',
            $rootScope.fielddata_geoJsonLayer,
            'All Fields',
            $rootScope.fielddata_geoJson,
            'field_slug',
            'farm_slug'
          );
          break;
        case 'fieldplotcrop':
          $rootScope.zoom_to_selected_feature(
            $rootScope,
            mapid,
            slug,
            '',
            $rootScope.plotdata_geoJsonLayer,
            FMSConstants.All,
            $rootScope.plotdata_geoJson,
            'plot_slug',
            'plot_slug'
          );
          break;
      }
    };

    this.changed_selection = function($rootScope, $scope) {
      if (typeof $scope.sidebar !== 'undefined') {
        if ($scope.sidebar.isVisible() === false) {
          $scope.sidebar.toggle();
        }
      }
    };

    this.legend_table = function($scope) {
      $scope.legendTable = {};
      $scope.legendTable['NDVI'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];
      $scope.legendTable['GDVI'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];
      $scope.legendTable['OSAVI'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];
      $scope.legendTable['NDRE'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];
      $scope.legendTable['LAI'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];
      $scope.legendTable['CanopyCover'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];
      $scope.legendTable['default'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];

      $scope.legendTableUAV = {};
      $scope.legendTableUAV['MS_NDVI'] = [
        {
          lower_band: -1.0,
          upper_band: 0.0,
          class_value: 'VERY LOW',
          color_value: '#d7191c'
        },
        {
          lower_band: 0.0,
          upper_band: 0.25,
          class_value: 'LOW',
          color_value: '#fdae61'
        },
        {
          lower_band: 0.25,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#ffffc0'
        },
        {
          lower_band: 0.5,
          upper_band: 0.75,
          class_value: 'HIGH',
          color_value: '#a6d96a'
        },
        {
          lower_band: 0.75,
          upper_band: 1.0,
          class_value: 'VERY HIGH',
          color_value: '#1a9641'
        }
      ];
      $scope.legendTableUAV['MS_GDVI'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'VERY LOW',
          color_value: '#d7191c'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#fdae61'
        },
        {
          lower_band: 0.2,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#ffffc0'
        },
        {
          lower_band: 0.5,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#a6d96a'
        },
        {
          lower_band: 0.8,
          upper_band: 1.0,
          class_value: 'VERY HIGH',
          color_value: '#1a9641'
        }
      ];
      $scope.legendTableUAV['MS_OSAVI'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'VERY LOW',
          color_value: '#d7191c'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#fdae61'
        },
        {
          lower_band: 0.2,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#ffffc0'
        },
        {
          lower_band: 0.5,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#a6d96a'
        },
        {
          lower_band: 0.8,
          upper_band: 1.0,
          class_value: 'VERY HIGH',
          color_value: '#1a9641'
        }
      ];
      $scope.legendTableUAV['MS_NDRE'] = [
        {
          lower_band: 0.0,
          upper_band: 0.15,
          class_value: 'VERY LOW',
          color_value: '#d7191c'
        },
        {
          lower_band: 0.15,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#fdae61'
        },
        {
          lower_band: 0.3,
          upper_band: 0.45,
          class_value: 'MEDIUM',
          color_value: '#ffffc0'
        },
        {
          lower_band: 0.45,
          upper_band: 0.6,
          class_value: 'HIGH',
          color_value: '#a6d96a'
        },
        {
          lower_band: 0.6,
          upper_band: 1.0,
          class_value: 'VERY HIGH',
          color_value: '#1a9641'
        }
      ];
      $scope.legendTableUAV['LAI'] = [
        {
          lower_band: 0,
          upper_band: 1,
          class_value: 'LOW',
          color_value: '#f7fcf5'
        },
        {
          lower_band: 1,
          upper_band: 2,
          class_value: 'LOW',
          color_value: '#caeac3'
        },
        {
          lower_band: 2,
          upper_band: 3,
          class_value: 'LOW',
          color_value: '#7bc87c'
        },
        {
          lower_band: 3,
          upper_band: 4,
          class_value: 'LOW',
          color_value: '#2a924a'
        },
        {
          lower_band: 4,
          upper_band: 5,
          class_value: 'MEDIUM',
          color_value: '#00441b'
        }
      ];
      $scope.legendTableUAV['CanopyCover'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];
      $scope.legendTableUAV['default'] = [
        {
          lower_band: 0.0,
          upper_band: 0.1,
          class_value: 'LOW',
          color_value: '#a6611a'
        },
        {
          lower_band: 0.1,
          upper_band: 0.2,
          class_value: 'LOW',
          color_value: '#c08c46'
        },
        {
          lower_band: 0.2,
          upper_band: 0.3,
          class_value: 'LOW',
          color_value: '#d9b772'
        },
        {
          lower_band: 0.3,
          upper_band: 0.4,
          class_value: 'LOW',
          color_value: '#e7d3a5'
        },
        {
          lower_band: 0.4,
          upper_band: 0.5,
          class_value: 'MEDIUM',
          color_value: '#f1eadb'
        },
        {
          lower_band: 0.5,
          upper_band: 0.6,
          class_value: 'MEDIUM',
          color_value: '#dbedea'
        },
        {
          lower_band: 0.6,
          upper_band: 0.7,
          class_value: 'MEDIUM',
          color_value: '#a7dbd3'
        },
        {
          lower_band: 0.7,
          upper_band: 0.8,
          class_value: 'HIGH',
          color_value: '#72c5b8'
        },
        {
          lower_band: 0.8,
          upper_band: 0.9,
          class_value: 'HIGH',
          color_value: '#39a595'
        },
        {
          lower_band: 0.9,
          upper_band: 1.0,
          class_value: 'HIGH',
          color_value: '#018571'
        }
      ];
    };

    // Crowd source functions

    function createOtherUserResponseMarkerClusterLayer(
      result,
      leafletData,
      mapid
    ) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className: 'pws-marker-cluster pws-marker-cluster-' + clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(result);
      leafletData.getMap(mapid).then(function(map) {
        map.addLayer(markers);
        //map.fitBounds(markers.getBounds());
      });
      return markers;
    }

    this.load_CSResponsedata = function(
      OilSeedRapeGrowthFactory,
      $khPulse,
      $rootScope,
      leafletData,
      mapid
    ) {
      $khPulse.show();
      OilSeedRapeGrowthFactory.query(
        { growing_season: $rootScope.selected_growing_season.value },
        function(success_data) {
          $rootScope.users_team_responses_geoJson =
            success_data[0].users_team_responses[0];
          $rootScope.all_teams_responses_geoJson =
            success_data[0].all_teams_responses[0];
          load_user_responses(
            mapid,
            leafletData,
            $rootScope,
            $rootScope.users_team_responses_geoJson
          );
          load_other_user_responses(
            mapid,
            leafletData,
            $rootScope,
            $rootScope.all_teams_responses_geoJson
          );
          $khPulse.hide(0);
        }
      );
    };

    this.loadCSResponseData = function(
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      OilSeedRapeGrowthFactory
    ) {
      $rootScope.leaflet_users_responses_layer_id = -9999;
      $rootScope.users_responses_geoJsonLayer = {};
      $rootScope.users_responses_clusterLayer = {};
      $rootScope.leaflet_other_user_responses_layer_id = -9999;
      $rootScope.other_user_responses_geoJsonLayer = {};
      $rootScope.other_user_responses_clusterLayer = {};
      self.load_CSResponsedata(
        OilSeedRapeGrowthFactory,
        $khPulse,
        $rootScope,
        leafletData,
        mapid
      );
    };

    this.loadCSFBResponseData = function(
      $khPulse,
      $rootScope,
      leafletData,
      mapid,
      DataFactory
    ) {
      $rootScope.leaflet_users_responses_layer_id = -9999;
      $rootScope.users_responses_geoJsonLayer = {};
      $rootScope.users_responses_clusterLayer = {};
      $rootScope.leaflet_other_user_responses_layer_id = -9999;
      $rootScope.other_user_responses_geoJsonLayer = {};
      $rootScope.other_user_responses_clusterLayer = {};
      self.load_CSFBResponsedata(
        DataFactory,
        $khPulse,
        $rootScope,
        leafletData,
        mapid
      );
    };

    this.load_CSFBResponsedata = function(
      DataFactory,
      $khPulse,
      $rootScope,
      leafletData,
      mapid
    ) {
      $khPulse.show();
      DataFactory.query(
        { growing_season: $rootScope.selected_growing_season.value },
        function(success_data) {
          $rootScope.users_team_responses_geoJson =
            success_data[0].users_team_responses[0];
          $rootScope.all_teams_responses_geoJson =
            success_data[0].all_teams_responses[0];
          load_csfb_user_responses(
            mapid,
            leafletData,
            $rootScope,
            $rootScope.users_team_responses_geoJson
          );
          load_csfb_other_user_responses(
            mapid,
            leafletData,
            $rootScope,
            $rootScope.all_teams_responses_geoJson
          );
          $khPulse.hide(0);
        }
      );
    };

    function load_other_user_responses(mapid, leafletData, $rootScope, data) {
      leafletData.getMap(mapid).then(function(map) {
        if (
          $rootScope.other_user_responses_geoJsonLayer ||
          $rootScope.leaflet_other_user_responses_layer_id === -9999
        ) {
          map.removeLayer($rootScope.other_user_responses_geoJsonLayer);
          map.removeLayer($rootScope.other_user_responses_clusterLayer);
        }
        if (data.length !== 0) {
          $rootScope.other_user_responses_geoJsonLayer = createGeoJsonOtherUserResponseLayer(
            data,
            $rootScope,
            leafletData,
            mapid
          );
          $rootScope.other_user_responses_clusterLayer = createOtherUserResponseMarkerClusterLayer(
            $rootScope.other_user_responses_geoJsonLayer,
            leafletData,
            mapid
          );
          $rootScope.leaflet_other_user_responses_layer_id =
            $rootScope.other_user_responses_geoJsonLayer._leaflet_id;
        } else {
          message = 'No Polygon Data';
        }
      });
    }

    function createGeoJsonOtherUserResponseLayer(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      return L.geoJson(result, {
        name: 'OtherUserResponse',
        layer: 'OtheruserResponseLayer',
        group: 'Crowdsource',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var inactiveIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'gray', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'blue', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        marker = new L.marker(latlng, {
          icon: activeIcon,
          title: 'OtherUsersResponseLayer'
        });

        marker.on('click', getFeature);
        //$rootScope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    }

    function load_user_responses(mapid, leafletData, $rootScope, data) {
      leafletData.getMap(mapid).then(function(map) {
        if (
          $rootScope.users_responses_geoJsonLayer ||
          $rootScope.leaflet_users_responses_layer_id === -9999
        ) {
          map.removeLayer($rootScope.users_responses_geoJsonLayer);
          map.removeLayer($rootScope.users_responses_clusterLayer);
        }
        if (data.length !== 0) {
          $rootScope.users_responses_geoJsonLayer = createGeoJsonUserResponseLayer(
            data,
            $rootScope,
            leafletData,
            mapid
          );
          $rootScope.users_responses_clusterLayer = createUserResponseMarkerClusterLayer(
            $rootScope.users_responses_geoJsonLayer,
            leafletData
          );
          $rootScope.leaflet_users_responses_layer_id =
            $rootScope.users_responses_geoJsonLayer._leaflet_id;
        } else {
          message = 'No Polygon Data';
        }
      });
    }

    function createGeoJsonUserResponseLayer(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      return L.geoJson(result, {
        name: 'Response',
        layer: 'ResponseLayer',
        group: 'Crowdsource',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var inactiveIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'gray', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'orange', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        marker = new L.marker(latlng, {
          icon: activeIcon,
          title: 'UserResponseLayer'
        });

        marker.on('click', getFeature);
        //$rootScope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    }

    function createUserResponseMarkerClusterLayer(result, leafletData, mapid) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className:
              'store-marker-cluster store-marker-cluster-' + clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(result);
      leafletData.getMap(mapid).then(function(map) {
        map.addLayer(markers);
        //map.fitBounds(markers.getBounds());
      });
      return markers;
    }

    function load_csfb_user_responses(mapid, leafletData, $rootScope, data) {
      leafletData.getMap(mapid).then(function(map) {
        if (
          $rootScope.users_responses_geoJsonLayer ||
          $rootScope.leaflet_users_responses_layer_id === -9999
        ) {
          map.removeLayer($rootScope.users_responses_geoJsonLayer);
          map.removeLayer($rootScope.users_responses_clusterLayer);
        }
        if (data.length !== 0) {
          $rootScope.users_responses_geoJsonLayer = createGeoJsonUserPointLayer(
            data,
            $rootScope,
            leafletData,
            mapid
          );
          map.addLayer($rootScope.users_responses_geoJsonLayer);
          $rootScope.leaflet_users_responses_layer_id =
            $rootScope.users_responses_geoJsonLayer._leaflet_id;
        } else {
          message = 'No Polygon Data';
        }
      });
    }

    function load_csfb_other_user_responses(
      mapid,
      leafletData,
      $rootScope,
      data
    ) {
      leafletData.getMap(mapid).then(function(map) {
        if (
          $rootScope.other_user_responses_geoJsonLayer ||
          $rootScope.leaflet_other_user_responses_layer_id === -9999
        ) {
          map.removeLayer($rootScope.other_user_responses_geoJsonLayer);
          map.removeLayer($rootScope.other_user_responses_clusterLayer);
        }
        if (data.length !== 0) {
          $rootScope.other_user_responses_geoJsonLayer = createGeoJsonOtherUserPointLayer(
            data,
            $rootScope,
            leafletData,
            mapid
          );
          map.addLayer($rootScope.other_user_responses_geoJsonLayer);
          $rootScope.leaflet_other_user_responses_layer_id =
            $rootScope.other_user_responses_geoJsonLayer._leaflet_id;
        } else {
          message = 'No Polygon Data';
        }
      });
    }

    function createGeoJsonUserPointLayer(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      var percent_crop_growing_well;
      var percent_crop_low_concern;
      var percent_crop_moderate_concern;
      var percent_crop_severe_concern;
      var percent_crop_written_off;

      return L.geoJson(result, {
        name: 'Point Layer',
        layer: 'UserResponseLayer',
        group: 'Group2 Layers',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var max_value = feature.properties.quantitative_damage[0].value;
        var max_value_key = 0;
        var max_value_question = feature.properties.quantitative_damage[0].key;
        for (
          var i = 0;
          i < feature.properties.quantitative_damage.length;
          i++
        ) {
          if (max_value <= feature.properties.quantitative_damage[i].value) {
            max_value = feature.properties.quantitative_damage[i].value;
            max_value_question = feature.properties.quantitative_damage[i].key;
            max_value_key = i;
          }
        }
        var cropArea = feature.properties.cropped_area;
        var totalArea = feature.properties.totalCroppedArea;
        var maxArea = feature.properties.maxCroppedArea;
        //var iconSizeValue = max_value * cropArea / maxArea;
        var iconSizeValue = cropArea;
        if (iconSizeValue < 50) {
          iconsizesize = '_size_2';
          iconanchor = [14, 45];
          popupanchor = [0, -40];
        } else if (iconSizeValue < 100) {
          iconsizesize = '_size_2_5';
          iconanchor = [21, 67];
          popupanchor = [0, -60];
        } else {
          iconsizesize = '_size_3';
          iconanchor = [29, 90];
          popupanchor = [0, -80];
        }

        max_value_question = feature.properties.your_response_key;
        if (feature.properties.cultivation_impact === 'StoppedCropping') {
          max_value_question = 'StoppedCropping';
        }
        switch (max_value_question) {
          case 'StoppedCropping':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_black' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'UserResponseLayer'
            });
            break;
          case 'percent_crop_growing_well':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_dark_green' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'UserResponseLayer'
            });
            break;
          case 'percent_crop_low_concern':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_green' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'UserResponseLayer'
            });
            break;
          case 'percent_crop_moderate_concern':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_yellow' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'UserResponseLayer'
            });
            break;
          case 'percent_crop_severe_concern':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_orange' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'UserResponseLayer'
            });
            break;
          case 'percent_crop_written_off':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_wine_red' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'UserResponseLayer'
            });
            break;
        }
        marker.on('click', getFeature);
        //$scope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    }

    function createGeoJsonOtherUserPointLayer(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      var percent_crop_growing_well;
      var percent_crop_low_concern;
      var percent_crop_moderate_concern;
      var percent_crop_severe_concern;
      var percent_crop_written_off;

      return L.geoJson(result, {
        name: 'Point Layer',
        layer: 'PointLayer',
        group: 'Group2 Layers',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var max_value = feature.properties.quantitative_damage[0].value;
        var max_value_key = 0;
        var max_value_question = feature.properties.quantitative_damage[0].key;
        for (
          var i = 0;
          i < feature.properties.quantitative_damage.length;
          i++
        ) {
          if (max_value <= feature.properties.quantitative_damage[i].value) {
            max_value = feature.properties.quantitative_damage[i].value;
            max_value_question = feature.properties.quantitative_damage[i].key;
            max_value_key = i;
          }
        }
        var cropArea = feature.properties.cropped_area;
        var totalArea = feature.properties.totalCroppedArea;
        var maxArea = feature.properties.maxCroppedArea;
        //var iconSizeValue = max_value * cropArea / maxArea;
        var iconSizeValue = cropArea;
        if (iconSizeValue < 50) {
          iconsizesize = '_size_2';
          iconanchor = [14, 45];
          popupanchor = [0, -40];
        } else if (iconSizeValue < 100) {
          iconsizesize = '_size_2_5';
          iconanchor = [21, 67];
          popupanchor = [0, -60];
        } else {
          iconsizesize = '_size_3';
          iconanchor = [29, 90];
          popupanchor = [0, -80];
        }

        max_value_question = feature.properties.your_response_key;
        if (feature.properties.cultivation_impact === 'StoppedCropping') {
          max_value_question = 'StoppedCropping';
        }
        switch (max_value_question) {
          case 'StoppedCropping':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_black' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'UserResponseLayer'
            });
            break;
          case 'percent_crop_growing_well':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_dark_green' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'OtherUsersResponseLayer'
            });
            break;
          case 'percent_crop_low_concern':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_green' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'OtherUsersResponseLayer'
            });
            break;
          case 'percent_crop_moderate_concern':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_yellow' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'OtherUsersResponseLayer'
            });
            break;
          case 'percent_crop_severe_concern':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_orange' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'OtherUsersResponseLayer'
            });
            break;
          case 'percent_crop_written_off':
            marker = new L.marker(latlng, {
              icon: new L.Icon({
                iconUrl: require('../../khapps/crowdsource/csfb/images/small/bug_wine_red' +
                  iconsizesize +
                  '.png'),
                iconAnchor: iconanchor,
                popupAnchor: popupanchor
              }),
              title: 'OtherUsersResponseLayer'
            });
            break;
        }
        marker.on('click', getFeature);
        //$scope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    }

    this.load_monitoring_site = function(mapid, leafletData, $rootScope, data) {
      leafletData.getMap(mapid).then(function(map) {
        if ($rootScope.monitoring_sites_geoJsonLayer) {
          map.removeLayer($rootScope.monitoring_sites_geoJsonLayer);
          map.removeLayer($rootScope.monitoring_sites_clusterLayer);
        }
        if (data.length !== 0) {
          $rootScope.monitoring_sites_geoJsonLayer = createGeoJsonMonitoringSiteLayer(
            data,
            $rootScope,
            leafletData,
            mapid
          );
          $rootScope.monitoring_sites_clusterLayer = createMonitoringSiteMarkerClusterLayer(
            $rootScope.monitoring_sites_geoJsonLayer,
            leafletData,
            mapid
          );
        } else {
          message = 'No Polygon Data';
        }
      });
    };

    function createGeoJsonMonitoringSiteLayer(
      result,
      $rootScope,
      leafletData,
      mapid
    ) {
      return L.geoJson(result, {
        name: 'Monitoring Site',
        layer: 'MonitoringSiteLayer',
        group: 'Crowdsource',
        onEachFeature: onEachFeature,
        pointToLayer: pointToLayerMarkers
      });

      function onEachFeature(feature, layer) {
        layer.on({ click: zoomToFeature });
      }

      function zoomToFeature(event, args) {
        leafletData.getMap(mapid).then(function(map) {
          map.setView([event.target._latlng.lat, event.target._latlng.lng], 18);
        });
      }

      function pointToLayerMarkers(feature, latlng) {
        var marker;
        var inactiveIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'gray', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        var activeIcon = L.AwesomeMarkers.icon({
          prefix: 'fa', //font awesome rather than bootstrap
          markerColor: 'darkred', // see colors above
          icon: 'leaf' //http://fortawesome.github.io/Font-Awesome/icons/
        });
        marker = new L.marker(latlng, {
          icon: activeIcon,
          title: 'MonitoringSiteLayer'
        });

        marker.on('click', getFeature);
        //$rootScope.oms.addMarker(marker);
        return marker;
      }

      function getFeature(event) {
        $rootScope.selectedFeature = event.target;
        $rootScope.$broadcast('changedSelectedFeature');
      }
    }

    function createMonitoringSiteMarkerClusterLayer(
      result,
      leafletData,
      mapid
    ) {
      var markers = L.markerClusterGroup({
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        spiderfyOnMaxZoom: true,
        removeOutsideVisibleBounds: true,
        animateAddingMarkers: true,
        iconCreateFunction: function(cluster) {
          var clusterSize = 'small';
          if (cluster.getChildCount() >= 50) {
            clusterSize = 'medium';
          }
          if (cluster.getChildCount() >= 100) {
            clusterSize = 'large';
          }
          return new L.DivIcon({
            html: '<span>' + cluster.getChildCount() + '</span>',
            className:
              'soil-sample-marker-cluster soil-sample-marker-cluster-' +
              clusterSize,
            iconSize: new L.Point(40, 40)
          });
        }
      });
      markers.addLayer(result);
      leafletData.getMap(mapid).then(function(map) {
        map.addLayer(markers);
        //map.fitBounds(markers.getBounds());
      });
      return markers;
    }
  });

import * as Sentry from "@sentry/browser";
import {SentryAngularJSIntegration} from "./khutils/ngSentry";
import LogRocket from 'logrocket';

import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';   
import {FileAttachmentsComponent} from '../../angular/src/app/upload/file-attachments.component'; 
import { UploadService } from '../../angular/src/app/upload/upload.service';
import { KhPdfViewerComponent } from '../../angular/src/app/shared/core/kh-pdf-viewer/kh-pdf-viewer.component';

export default  angular.module('KhBase', [
    'ngMaterial',
    'pascalprecht.translate',
    'ngSanitize',
    'KhSidebar',
    'FiltersData',
    'khUtils',
    'ProfileApp',
    'KhNavbar',
    'AccountSettingsApp',
    'UserSettingsApp',
    'ngMaterialDatePicker',
    'mdIcons',
    'ngResource',
    'ngRoute',
    'KhSecondaryNavbar',
    'SecondaryFilterBar',
    'ngMessages',
    'formly',
    'formlyMaterial',
    'ngAria',
    'ngTable',
    'ngAnimate',
    'KhFormElements',
    'khFileUploads',
    'angular-clipboard'
]).filter('capitalise', function () {
    return function (input_string, all_words) {
        var regExp = (all_words) ? /([^\W_]+[^\s-]*) */g : /([^\W_]+[^\s-]*)/;
        return (!!input_string) ? input_string.replace(regExp, function (str) {
            return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
        }) : '';
    };
}).filter('setDecimal', function () {
    return function (input, places) {
        if (isNaN(input)) return input;
        if (input === null) return 'n/a';
        if (input === 0) return 0;
        var factor = "1" + Array(+(places > 0 && places + 1)).join("0");
        return Math.round(input * factor) / factor;
    };
}).filter('getReadableDate', function (DateUtilsService) {
    return function (value) {
        return DateUtilsService.get_readable_date(value);
    };
}).filter('trim', function () {
    return function (sentence, maxLength) {
        if (sentence && sentence.length > maxLength) {
            return sentence.substr(0, maxLength) + "...";
        }
        return sentence
    }
}).filter('splitDateAndTime', function (UserSettingsConstants) {

    function splitDateAndTimeStr(date) {
        if (date[date.length - 1] == "Z") {
            date = date.substring(0, date.length - 1)
        }
        var time = moment(new Date(date)).format(UserSettingsConstants.USER.DEFAULT_USER_READABLE_TIME_FORMAT);
        return (readable_date(date) + ", " + time).toString()
    }

    function readable_date(timestamp, existing_format) {
        let date_format = existing_format ? existing_format : "YYYY-MM-DDT00:00:00";
        return moment(timestamp, date_format).format(UserSettingsConstants.USER.DEFAULT_USER_READABLE_DATE_FORMAT);
    }

    return function (date) {
        splitDateAndTimeStr(date);
    };
});


if (WEB_ENV == 'prod') {
    LogRocket.init('g8oxs0/kisanhub-vyknx');
}

if (typeof user_id !== 'undefined') {
    if (WEB_ENV == 'prod') {
        // user logged in
        LogRocket.identify(user_id, {
            name: user_name,
            email: user_email,
        });
    }

    // Sentry configuration
    if (window.sentryEnabled) {
        Sentry.init({
            dsn: window.atob(SENTRY_WEB_DSN),
            integrations: [new SentryAngularJSIntegration({angular})],
            environment: WEB_ENV,
            release: window.atob(BUILD_TAG)
        });
        Sentry.configureScope(scope => {
            scope.setUser({ id: user_id,  username: user_name, email: user_email});

            // LogRocket Sentry Integration
            if (WEB_ENV == 'prod') {
                scope.addEventProcessor(async (event) => {
                    const url = await new Promise((resolve) => {
                        LogRocket.getSessionURL(url => {
                            resolve(url);
                        });
                    });
                    event.extra = {"sessionURL": url};
                    return event
                });
            }
        });
        angular.module('KhBase').requires.push(SentryAngularJSIntegration.moduleName);
    }
}



angular
    .module('KhBase')
    .directive('uploadFileAttachments', downgradeComponent({
        component: FileAttachmentsComponent
    }))
    .factory('UploadService', downgradeInjectable(UploadService) )
    .directive(
        'khPdfViewer',
        downgradeComponent({component: KhPdfViewerComponent})
    );
angular.module("khUtils").directive('khSearchSelect', function () {
	return {
		restrict: 'A',
		link: function (scope, element) {
			scope.$parent.$parent.field.templateOptions.searchmodel = '';
			element.find('input').on('keydown', function (ev) {
				scope.$parent.$parent.field.templateOptions.searchmodel = ev.currentTarget.value;
				scope.$apply();
				ev.stopPropagation();
			});
			scope.clearSearch = function () {
				scope.$parent.$parent.field.templateOptions.searchmodel = '';
				angular.element("#" + scope.$parent.$parent.field.key).val('');
			}

		}
	};
});
angular.module("khUtils").directive('khSearchSelectKillEvent', function () {
	return {
		restrict: 'A',
		link: function (scope, element) {
			element.find('input').on('keydown', function (ev) {
				ev.stopPropagation();
			});
		}
	};
});
angular.module("khFileUploads").controller("khImageUploadsController", ["$scope", "$rootScope", "$cookies", "khFileUploadsFactory",
	"ImageFileUploadsFactory",
	function ($scope, $rootScope, $cookies, khFileUploadsFactory, ImageFileUploadsFactory) {
		$scope.displayable_files = [];
		$rootScope.model_images_for_app = [];
		$scope.getFileDetails = function (e) {
			if ($scope.displayable_files.length < parseInt($scope.maxfiles) && e.files.length <= parseInt($scope.maxfiles)) {
				if (e.files.length <= parseInt($scope.maxfiles)) {
					angular.forEach(e.files, function (value, key) {
						if ($scope.displayable_files.length > 0 && !$scope.allowduplicates) {
							angular.forEach($scope.displayable_files, function (val) {
								if (value.name == val.image_name) {
									window.alert("File with same name has already been uploaded");
								} else {
									$scope.uploading_progress_bar = true;
									switch (value.name.substring(value.name.lastIndexOf('.') + 1).toLowerCase()) {
										case 'gif':
										case 'jpg':
										case 'jpeg':
										case 'png':
										case 'bmp':
											khFileUploadsFactory.uploadFile(value, $cookies, $scope, 'image');
											$scope.$apply();
											break;
										default:
											$scope.uploading_progress_bar = false;
											window.alert("Please select either of these image types: jpg,jpeg,png,bmp,gif image");
											$scope.$apply();
											break;
									}
								}
							})
						} else {
							$scope.uploading_progress_bar = true;
							switch (value.name.substring(value.name.lastIndexOf('.') + 1).toLowerCase()) {
								case 'gif':
								case 'jpg':
								case 'jpeg':
								case 'png':
								case 'bmp':
									khFileUploadsFactory.uploadFile(value, $cookies, $scope, 'image');
									$scope.$apply();
									break;
								default:
									$scope.uploading_progress_bar = false;
									window.alert("Please select either of these image types: jpg,jpeg,png,bmp,gif image");
									$scope.$apply();
									break;
							}
						}
					});
				} else {
					window.alert("You can select a maximum of " + $scope.maxfiles + " images only.");
				}
			} else {
				window.alert("You can select a maximum of " + $scope.maxfiles + " images only.");
			}
		};
		$scope.$on("image_uploaded", function (event, data) {
			$scope.uploading_progress_bar = false;
			$scope.displayable_files.push(data);
			switch ($scope.dbtype) {
				case 'mysql':
					$rootScope.model_images_for_app.push(data.id);
					break;
				case 'mongo':
					$rootScope.model_images_for_app.push({
						file_path: data.image_file,
						file_id: data.id,
						file_name: data.image_name
					});
					break;
			}
			$scope.$apply();
			return $rootScope.model_images_for_app;
		});
		$scope.displayable_files = [];
		$scope.$on("image_file_loaded", function (result, data) {
			$scope.displayable_files.push(data);
			switch ($scope.dbtype) {
				case 'mysql':
					$rootScope.model_images_for_app.push(data.id);
					break;
				case 'mongo':
					$rootScope.model_images_for_app.push({
						file_path: data.image_file, file_id: data.id, file_name: data.image_name
					});
					break;
			}
			return $rootScope.model_images_for_app;
		});
		$scope.removeFile = function (file, dbtype) {
			ImageFileUploadsFactory.delete({id: file.id}, function (result) {
				$scope.deleteFile(file, dbtype);
			});
		};
		$scope.deleteFile = function (file, dbtype) {
			switch (dbtype) {
				case 'mysql':
					angular.forEach($rootScope.model_images_for_app, function (value, key) {
						if (value == file.id) {
							$rootScope.model_images_for_app.splice(key, 1);
						}
					});
					break;
				case 'mongo':
					angular.forEach($rootScope.model_images_for_app, function (value, key) {
						if (value.file_id == file.id) {
							$rootScope.model_images_for_app.splice(key, 1);
						}
					});
					break;
			}
			angular.forEach($scope.displayable_files, function (value, key) {
				if (value.file_name == file.file_name) {
					$scope.displayable_files.splice(key, 1);
				}
			});
			return $rootScope.model_images_for_app;
		};
	}]);
angular.module('khUtils').directive('khHorizontalFilter', function () {
    return {
        restrict: 'E',
        required: ['selected', 'options', 'labelProp', 'valueProp'],
        transclude: true,
        scope: {
            selected: '=',
            options: '=',
            labelProp: '=',
            valueProp: '='
        },
        link: function ($scope, element, attrs) {
            resetSearch();

            $scope.availableOptionsWithoutFilter = angular.copy($scope.options);
            $scope.availableOptionsWithFilter = angular.copy($scope.options);
            $scope.selectedWithoutFilter = [];
            $scope.selectedWithFilter = [];
            $scope.selectedAvailableOptions = [];
            $scope.selectedSelectedOptions = [];

            function mapSelectedItems() {
                $scope.selected.selected = $scope.selectedWithoutFilter.map(function (t) {
                    return t[$scope.valueProp];
                });
            }

            $scope.moveAllRight = function () {
                resetSearch();
                $scope.selectedWithoutFilter = angular.copy($scope.options);
                $scope.selectedWithFilter = angular.copy($scope.options);
                $scope.availableOptionsWithoutFilter = [];
                $scope.availableOptionsWithFilter = [];
                mapSelectedItems();
            };

            $scope.moveSelectedRight = function () {
                resetSearch();
                angular.forEach($scope.selectedAvailableOptions, function (option) {
                    for (var i = 0; i<$scope.availableOptionsWithoutFilter.length; i ++) {
                        if ($scope.availableOptionsWithoutFilter[i][$scope.valueProp] === option[$scope.valueProp]) {
                            $scope.availableOptionsWithoutFilter.splice(i, 1);
                            i --;
                        }
                    }
                    $scope.selectedWithoutFilter.push(option);
                });
                $scope.availableOptionsWithFilter = angular.copy($scope.availableOptionsWithoutFilter);
                $scope.selectedWithFilter = angular.copy($scope.selectedWithoutFilter);
                mapSelectedItems();
            };

            $scope.moveSelectedLeft = function () {
                resetSearch();
                angular.forEach($scope.selectedSelectedOptions, function (option) {
                    for (var i = 0; i<$scope.selectedWithoutFilter.length; i ++) {
                        if ($scope.selectedWithoutFilter[i][$scope.valueProp] === option[$scope.valueProp]) {
                            $scope.selectedWithoutFilter.splice(i, 1);
                            i --;
                        }
                    }
                    $scope.availableOptionsWithoutFilter.push(option);
                });
                $scope.availableOptionsWithFilter = angular.copy($scope.availableOptionsWithoutFilter);
                $scope.selectedWithFilter = angular.copy($scope.selectedWithoutFilter);
                mapSelectedItems();
            };

            $scope.moveAllLeft = function () {
                resetSearch();
                $scope.availableOptionsWithoutFilter = angular.copy($scope.options);
                $scope.availableOptionsWithFilter = angular.copy($scope.options);
                $scope.selectedWithoutFilter = [];
                $scope.selectedWithFilter = [];
                mapSelectedItems();
            };

            $scope.filterAvailableOptions = function () {
                $scope.availableOptionsWithFilter = $scope.availableOptionsWithoutFilter.filter(function (t) {
                    return t[$scope.labelProp].toLowerCase().indexOf($scope.search_available_options.toLowerCase()) > -1;
                });
            };

            $scope.filterSelectedOptions = function () {
                $scope.selectedWithFilter = $scope.selectedWithoutFilter.filter(function (t) {
                    return t[$scope.labelProp].toLowerCase().indexOf($scope.search_selected_options.toLowerCase()) > -1;
                });
            };
            function resetSearch() {
                $scope.search_available_options = '';
                $scope.search_selected_options = '';
            }
        },
        templateUrl: '/static/assets/khapps2/base/khformelements/partials/kh_horizontal_filter.html'
    };
});

angular.module("khUtils").service('$khPulse', function ($compile) {
	var khPulse = "";

	this.show = function () {

		// if ($("#kh-pulse").length == 0) {
		// 	var e = $(khPulse);
		// 	$("#pulse-container").append(e);
		// } else {
		// 	$("#kh-pulse").show();
		// }
	};
	this.hide = function () {
		// $("#kh-pulse").hide();
	}
});
export default angular.module("KhSecondaryNavbar", [])
    .directive('secondaryNavbar', function () {
        return {
            restrict: 'E',
            templateUrl: '/static/assets/khapps2/base/secondary_navbar/secondary_navbar.html',
            link: function (scope, element, attrs, controllers) {
                scope.in_app_links_template = attrs["inAppLinksTemplate"];
                scope.select_filters_template = attrs["selectFiltersTemplate"];
                scope.view_as_template = attrs["viewAsTemplate"];
                scope.windowWidth = window.innerWidth
            }
        };
    });
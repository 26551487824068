angular.module('SecondaryFilterBar').component('secondaryFilterBar', {
    templateUrl: '/static/assets/khapps2/base/secondary_filter_bar/secondary-filter-bar.component.html',
    controller: function ($mdSidenav, SideNavBarService) {
        var self = this;
        self.leftsidenavIsOpen = window.innerWidth > 1080;
        self.currentPage = null;

        SideNavBarService.getApp$().subscribe(function (app) {
            self.currentPage = app ? app.app_title : null;
        });


        self.buildToggler = function (componentId) {
            if (componentId === 'leftsidenav') {
                self.leftsidenavIsOpen = !self.leftsidenavIsOpen;
            }
            $mdSidenav(componentId).toggle();
        };
    }
});

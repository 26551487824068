angular.module("khUtils").constant('countries', [
    {
        'fullname': 'Afghanistan',
        'dial_code': '+93',
        'name': 'AF',
        'value': 'AF'
    },
    {
        'fullname': 'Aland Islands',
        'dial_code': '+358',
        'name': 'AX',
        'value': 'AX'
    },
    {
        'fullname': 'Albania',
        'dial_code': '+355',
        'name': 'AL',
        'value': 'AL'
    },
    {
        'fullname': 'Algeria',
        'dial_code': '+213',
        'name': 'DZ',
        'value': 'DZ'
    },
    {
        'fullname': 'AmericanSamoa',
        'dial_code': '+1684',
        'name': 'AS',
        'value': 'AS'
    },
    {
        'fullname': 'Andorra',
        'dial_code': '+376',
        'name': 'AD',
        'value': 'AD'
    },
    {
        'fullname': 'Angola',
        'dial_code': '+244',
        'name': 'AO',
        'value': 'AO'
    },
    {
        'fullname': 'Anguilla',
        'dial_code': '+1264',
        'name': 'AI',
        'value': 'AI'
    },
    {
        'fullname': 'Antarctica',
        'dial_code': '+672',
        'name': 'AQ',
        'value': 'AQ'
    },
    {
        'fullname': 'Antigua and Barbuda',
        'dial_code': '+1268',
        'name': 'AG',
        'value': 'AG'
    },
    {
        'fullname': 'Argentina',
        'dial_code': '+54',
        'name': 'AR',
        'value': 'AR'
    },
    {
        'fullname': 'Armenia',
        'dial_code': '+374',
        'name': 'AM',
        'value': 'AM'
    },
    {
        'fullname': 'Aruba',
        'dial_code': '+297',
        'name': 'AW',
        'value': 'AW'
    },
    {
        'fullname': 'Australia',
        'dial_code': '+61',
        'name': 'AU',
        'value': 'AU'
    },
    {
        'fullname': 'Austria',
        'dial_code': '+43',
        'name': 'AT',
        'value': 'AT'
    },
    {
        'fullname': 'Azerbaijan',
        'dial_code': '+994',
        'name': 'AZ',
        'value': 'AZ'
    },
    {
        'fullname': 'Bahamas',
        'dial_code': '+1242',
        'name': 'BS',
        'value': 'BS'
    },
    {
        'fullname': 'Bahrain',
        'dial_code': '+973',
        'name': 'BH',
        'value': 'BH'
    },
    {
        'fullname': 'Bangladesh',
        'dial_code': '+880',
        'name': 'BD',
        'value': 'BD'
    },
    {
        'fullname': 'Barbados',
        'dial_code': '+1246',
        'name': 'BB',
        'value': 'BB'
    },
    {
        'fullname': 'Belarus',
        'dial_code': '+375',
        'name': 'BY',
        'value': 'BY'
    },
    {
        'fullname': 'Belgium',
        'dial_code': '+32',
        'name': 'BE',
        'value': 'BE'
    },
    {
        'fullname': 'Belize',
        'dial_code': '+501',
        'name': 'BZ',
        'value': 'BZ'
    },
    {
        'fullname': 'Benin',
        'dial_code': '+229',
        'name': 'BJ',
        'value': 'BJ'
    },
    {
        'fullname': 'Bermuda',
        'dial_code': '+1441',
        'name': 'BM',
        'value': 'BM'
    },
    {
        'fullname': 'Bhutan',
        'dial_code': '+975',
        'name': 'BT',
        'value': 'BT'
    },
    {
        'fullname': 'Bolivia, Plurinational State of',
        'dial_code': '+591',
        'name': 'BO',
        'value': 'BO'
    },
    {
        'fullname': 'Bosnia and Herzegovina',
        'dial_code': '+387',
        'name': 'BA',
        'value': 'BA'
    },
    {
        'fullname': 'Botswana',
        'dial_code': '+267',
        'name': 'BW',
        'value': 'BW'
    },
    {
        'fullname': 'Brazil',
        'dial_code': '+55',
        'name': 'BR',
        'value': 'BR'
    },
    {
        'fullname': 'British Indian Ocean Territory',
        'dial_code': '+246',
        'name': 'IO',
        'value': 'IO'
    },
    {
        'fullname': 'Brunei Darussalam',
        'dial_code': '+673',
        'name': 'BN',
        'value': 'BN'
    },
    {
        'fullname': 'Bulgaria',
        'dial_code': '+359',
        'name': 'BG',
        'value': 'BG'
    },
    {
        'fullname': 'Burkina Faso',
        'dial_code': '+226',
        'name': 'BF',
        'value': 'BF'
    },
    {
        'fullname': 'Burundi',
        'dial_code': '+257',
        'name': 'BI',
        'value': 'BI'
    },
    {
        'fullname': 'Cambodia',
        'dial_code': '+855',
        'name': 'KH',
        'value': 'KH'
    },
    {
        'fullname': 'Cameroon',
        'dial_code': '+237',
        'name': 'CM',
        'value': 'CM'
    },
    {
        'fullname': 'Canada',
        'dial_code': '+1',
        'name': 'CA',
        'value': 'CA'
    },
    {
        'fullname': 'Cape Verde',
        'dial_code': '+238',
        'name': 'CV',
        'value': 'CV'
    },
    {
        'fullname': 'Cayman Islands',
        'dial_code': '+ 345',
        'name': 'KY',
        'value': 'KY'
    },
    {
        'fullname': 'Central African Republic',
        'dial_code': '+236',
        'name': 'CF',
        'value': 'CF'
    },
    {
        'fullname': 'Chad',
        'dial_code': '+235',
        'name': 'TD',
        'value': 'TD'
    },
    {
        'fullname': 'Chile',
        'dial_code': '+56',
        'name': 'CL',
        'value': 'CL'
    },
    {
        'fullname': 'China',
        'dial_code': '+86',
        'name': 'CN',
        'value': 'CN'
    },
    {
        'fullname': 'Christmas Island',
        'dial_code': '+61',
        'name': 'CX',
        'value': 'CX'
    },
    {
        'fullname': 'Cocos (Keeling) Islands',
        'dial_code': '+61',
        'name': 'CC',
        'value': 'CC'
    },
    {
        'fullname': 'Colombia',
        'dial_code': '+57',
        'name': 'CO',
        'value': 'CO'
    },
    {
        'fullname': 'Comoros',
        'dial_code': '+269',
        'name': 'KM',
        'value': 'KM'
    },
    {
        'fullname': 'Congo',
        'dial_code': '+242',
        'name': 'CG',
        'value': 'CG'
    },
    {
        'fullname': 'Congo, The Democratic Republic of the Congo',
        'dial_code': '+243',
        'name': 'CD',
        'value': 'CD'
    },
    {
        'fullname': 'Cook Islands',
        'dial_code': '+682',
        'name': 'CK',
        'value': 'CK'
    },
    {
        'fullname': 'Costa Rica',
        'dial_code': '+506',
        'name': 'CR',
        'value': 'CR'
    },
    {
        'fullname': 'Cote d\'Ivoire',
        'dial_code': '+225',
        'name': 'CI',
        'value': 'CI'
    },
    {
        'fullname': 'Croatia',
        'dial_code': '+385',
        'name': 'HR',
        'value': 'HR'
    },
    {
        'fullname': 'Cuba',
        'dial_code': '+53',
        'name': 'CU',
        'value': 'CU'
    },
    {
        'fullname': 'Cyprus',
        'dial_code': '+357',
        'name': 'CY',
        'value': 'CY'
    },
    {
        'fullname': 'Czech Republic',
        'dial_code': '+420',
        'name': 'CZ',
        'value': 'CZ'
    },
    {
        'fullname': 'Denmark',
        'dial_code': '+45',
        'name': 'DK',
        'value': 'DK'
    },
    {
        'fullname': 'Djibouti',
        'dial_code': '+253',
        'name': 'DJ',
        'value': 'DJ'
    },
    {
        'fullname': 'Dominica',
        'dial_code': '+1767',
        'name': 'DM',
        'value': 'DM'
    },
    {
        'fullname': 'Dominican Republic',
        'dial_code': '+1849',
        'name': 'DO',
        'value': 'DO'
    },
    {
        'fullname': 'Ecuador',
        'dial_code': '+593',
        'name': 'EC',
        'value': 'EC'
    },
    {
        'fullname': 'Egypt',
        'dial_code': '+20',
        'name': 'EG',
        'value': 'EG'
    },
    {
        'fullname': 'El Salvador',
        'dial_code': '+503',
        'name': 'SV',
        'value': 'SV'
    },
    {
        'fullname': 'Equatorial Guinea',
        'dial_code': '+240',
        'name': 'GQ',
        'value': 'GQ'
    },
    {
        'fullname': 'Eritrea',
        'dial_code': '+291',
        'name': 'ER',
        'value': 'ER'
    },
    {
        'fullname': 'Estonia',
        'dial_code': '+372',
        'name': 'EE',
        'value': 'EE'
    },
    {
        'fullname': 'Ethiopia',
        'dial_code': '+251',
        'name': 'ET',
        'value': 'ET'
    },
    {
        'fullname': 'Falkland Islands (Malvinas)',
        'dial_code': '+500',
        'name': 'FK',
        'value': 'FK'
    },
    {
        'fullname': 'Faroe Islands',
        'dial_code': '+298',
        'name': 'FO',
        'value': 'FO'
    },
    {
        'fullname': 'Fiji',
        'dial_code': '+679',
        'name': 'FJ',
        'value': 'FJ'
    },
    {
        'fullname': 'Finland',
        'dial_code': '+358',
        'name': 'FI',
        'value': 'FI'
    },
    {
        'fullname': 'France',
        'dial_code': '+33',
        'name': 'FR',
        'value': 'FR'
    },
    {
        'fullname': 'French Guiana',
        'dial_code': '+594',
        'name': 'GF',
        'value': 'GF'
    },
    {
        'fullname': 'French Polynesia',
        'dial_code': '+689',
        'name': 'PF',
        'value': 'PF'
    },
    {
        'fullname': 'Gabon',
        'dial_code': '+241',
        'name': 'GA',
        'value': 'GA'
    },
    {
        'fullname': 'Gambia',
        'dial_code': '+220',
        'name': 'GM',
        'value': 'GM'
    },
    {
        'fullname': 'Georgia',
        'dial_code': '+995',
        'name': 'GE',
        'value': 'GE'
    },
    {
        'fullname': 'Germany',
        'dial_code': '+49',
        'name': 'DE',
        'value': 'DE'
    },
    {
        'fullname': 'Ghana',
        'dial_code': '+233',
        'name': 'GH',
        'value': 'GH'
    },
    {
        'fullname': 'Gibraltar',
        'dial_code': '+350',
        'name': 'GI',
        'value': 'GI'
    },
    {
        'fullname': 'Greece',
        'dial_code': '+30',
        'name': 'GR',
        'value': 'GR'
    },
    {
        'fullname': 'Greenland',
        'dial_code': '+299',
        'name': 'GL',
        'value': 'GL'
    },
    {
        'fullname': 'Grenada',
        'dial_code': '+1473',
        'name': 'GD',
        'value': 'GD'
    },
    {
        'fullname': 'Guadeloupe',
        'dial_code': '+590',
        'name': 'GP',
        'value': 'GP'
    },
    {
        'fullname': 'Guam',
        'dial_code': '+1671',
        'name': 'GU',
        'value': 'GU'
    },
    {
        'fullname': 'Guatemala',
        'dial_code': '+502',
        'name': 'GT',
        'value': 'GT'
    },
    {
        'fullname': 'Guernsey',
        'dial_code': '+44',
        'name': 'GG',
        'value': 'GG'
    },
    {
        'fullname': 'Guinea',
        'dial_code': '+224',
        'name': 'GN',
        'value': 'GN'
    },
    {
        'fullname': 'Guinea-Bissau',
        'dial_code': '+245',
        'name': 'GW',
        'value': 'GW'
    },
    {
        'fullname': 'Guyana',
        'dial_code': '+595',
        'name': 'GY',
        'value': 'GY'
    },
    {
        'fullname': 'Haiti',
        'dial_code': '+509',
        'name': 'HT',
        'value': 'HT'
    },
    {
        'fullname': 'Holy See (Vatican City State)',
        'dial_code': '+379',
        'name': 'VA',
        'value': 'VA'
    },
    {
        'fullname': 'Honduras',
        'dial_code': '+504',
        'name': 'HN',
        'value': 'HN'
    },
    {
        'fullname': 'Hong Kong',
        'dial_code': '+852',
        'name': 'HK',
        'value': 'HK'
    },
    {
        'fullname': 'Hungary',
        'dial_code': '+36',
        'name': 'HU',
        'value': 'HU'
    },
    {
        'fullname': 'Iceland',
        'dial_code': '+354',
        'name': 'IS',
        'value': 'IS'
    },
    {
        'fullname': 'India',
        'dial_code': '+91',
        'name': 'IN',
        'value': 'IN'
    },
    {
        'fullname': 'Indonesia',
        'dial_code': '+62',
        'name': 'ID',
        'value': 'ID'
    },
    {
        'fullname': 'Iran, Islamic Republic of Persian Gulf',
        'dial_code': '+98',
        'name': 'IR',
        'value': 'IR'
    },
    {
        'fullname': 'Iraq',
        'dial_code': '+964',
        'name': 'IQ',
        'value': 'IQ'
    },
    {
        'fullname': 'Ireland',
        'dial_code': '+353',
        'name': 'IE',
        'value': 'IE'
    },
    {
        'fullname': 'Isle of Man',
        'dial_code': '+44',
        'name': 'IM',
        'value': 'IM'
    },
    {
        'fullname': 'Israel',
        'dial_code': '+972',
        'name': 'IL',
        'value': 'IL'
    },
    {
        'fullname': 'Italy',
        'dial_code': '+39',
        'name': 'IT',
        'value': 'IT'
    },
    {
        'fullname': 'Jamaica',
        'dial_code': '+1876',
        'name': 'JM',
        'value': 'JM'
    },
    {
        'fullname': 'Japan',
        'dial_code': '+81',
        'name': 'JP',
        'value': 'JP'
    },
    {
        'fullname': 'Jersey',
        'dial_code': '+44',
        'name': 'JE',
        'value': 'JE'
    },
    {
        'fullname': 'Jordan',
        'dial_code': '+962',
        'name': 'JO',
        'value': 'JO'
    },
    {
        'fullname': 'Kazakhstan',
        'dial_code': '+77',
        'name': 'KZ',
        'value': 'KZ'
    },
    {
        'fullname': 'Kenya',
        'dial_code': '+254',
        'name': 'KE',
        'value': 'KE'
    },
    {
        'fullname': 'Kiribati',
        'dial_code': '+686',
        'name': 'KI',
        'value': 'KI'
    },
    {
        'fullname': 'Korea, Democratic People\'s Republic of Korea',
        'dial_code': '+850',
        'name': 'KP',
        'value': 'KP'
    },
    {
        'fullname': 'Korea, Republic of South Korea',
        'dial_code': '+82',
        'name': 'KR',
        'value': 'KR'
    },
    {
        'fullname': 'Kuwait',
        'dial_code': '+965',
        'name': 'KW',
        'value': 'KW'
    },
    {
        'fullname': 'Kyrgyzstan',
        'dial_code': '+996',
        'name': 'KG',
        'value': 'KG'
    },
    {
        'fullname': 'Laos',
        'dial_code': '+856',
        'name': 'LA',
        'value': 'LA'
    },
    {
        'fullname': 'Latvia',
        'dial_code': '+371',
        'name': 'LV',
        'value': 'LV'
    },
    {
        'fullname': 'Lebanon',
        'dial_code': '+961',
        'name': 'LB',
        'value': 'LB'
    },
    {
        'fullname': 'Lesotho',
        'dial_code': '+266',
        'name': 'LS',
        'value': 'LS'
    },
    {
        'fullname': 'Liberia',
        'dial_code': '+231',
        'name': 'LR',
        'value': 'LR'
    },
    {
        'fullname': 'Libyan Arab Jamahiriya',
        'dial_code': '+218',
        'name': 'LY',
        'value': 'LY'
    },
    {
        'fullname': 'Liechtenstein',
        'dial_code': '+423',
        'name': 'LI',
        'value': 'LI'
    },
    {
        'fullname': 'Lithuania',
        'dial_code': '+370',
        'name': 'LT',
        'value': 'LT'
    },
    {
        'fullname': 'Luxembourg',
        'dial_code': '+352',
        'name': 'LU',
        'value': 'LU'
    },
    {
        'fullname': 'Macao',
        'dial_code': '+853',
        'name': 'MO',
        'value': 'MO'
    },
    {
        'fullname': 'Macedonia',
        'dial_code': '+389',
        'name': 'MK',
        'value': 'MK'
    },
    {
        'fullname': 'Madagascar',
        'dial_code': '+261',
        'name': 'MG',
        'value': 'MG'
    },
    {
        'fullname': 'Malawi',
        'dial_code': '+265',
        'name': 'MW',
        'value': 'MW'
    },
    {
        'fullname': 'Malaysia',
        'dial_code': '+60',
        'name': 'MY',
        'value': 'MY'
    },
    {
        'fullname': 'Maldives',
        'dial_code': '+960',
        'name': 'MV',
        'value': 'MV'
    },
    {
        'fullname': 'Mali',
        'dial_code': '+223',
        'name': 'ML',
        'value': 'ML'
    },
    {
        'fullname': 'Malta',
        'dial_code': '+356',
        'name': 'MT',
        'value': 'MT'
    },
    {
        'fullname': 'Marshall Islands',
        'dial_code': '+692',
        'name': 'MH',
        'value': 'MH'
    },
    {
        'fullname': 'Martinique',
        'dial_code': '+596',
        'name': 'MQ',
        'value': 'MQ'
    },
    {
        'fullname': 'Mauritania',
        'dial_code': '+222',
        'name': 'MR',
        'value': 'MR'
    },
    {
        'fullname': 'Mauritius',
        'dial_code': '+230',
        'name': 'MU',
        'value': 'MU'
    },
    {
        'fullname': 'Mayotte',
        'dial_code': '+262',
        'name': 'YT',
        'value': 'YT'
    },
    {
        'fullname': 'Mexico',
        'dial_code': '+52',
        'name': 'MX',
        'value': 'MX'
    },
    {
        'fullname': 'Micronesia, Federated States of Micronesia',
        'dial_code': '+691',
        'name': 'FM',
        'value': 'FM'
    },
    {
        'fullname': 'Moldova',
        'dial_code': '+373',
        'name': 'MD',
        'value': 'MD'
    },
    {
        'fullname': 'Monaco',
        'dial_code': '+377',
        'name': 'MC',
        'value': 'MC'
    },
    {
        'fullname': 'Mongolia',
        'dial_code': '+976',
        'name': 'MN',
        'value': 'MN'
    },
    {
        'fullname': 'Montenegro',
        'dial_code': '+382',
        'name': 'ME',
        'value': 'ME'
    },
    {
        'fullname': 'Montserrat',
        'dial_code': '+1664',
        'name': 'MS',
        'value': 'MS'
    },
    {
        'fullname': 'Morocco',
        'dial_code': '+212',
        'name': 'MA',
        'value': 'MA'
    },
    {
        'fullname': 'Mozambique',
        'dial_code': '+258',
        'name': 'MZ',
        'value': 'MZ'
    },
    {
        'fullname': 'Myanmar',
        'dial_code': '+95',
        'name': 'MM',
        'value': 'MM'
    },
    {
        'fullname': 'Namibia',
        'dial_code': '+264',
        'name': 'NA',
        'value': 'NA'
    },
    {
        'fullname': 'Nauru',
        'dial_code': '+674',
        'name': 'NR',
        'value': 'NR'
    },
    {
        'fullname': 'Nepal',
        'dial_code': '+977',
        'name': 'NP',
        'value': 'NP'
    },
    {
        'fullname': 'Netherlands',
        'dial_code': '+31',
        'name': 'NL',
        'value': 'NL'
    },
    {
        'fullname': 'Netherlands Antilles',
        'dial_code': '+599',
        'name': 'AN',
        'value': 'AN'
    },
    {
        'fullname': 'New Caledonia',
        'dial_code': '+687',
        'name': 'NC',
        'value': 'NC'
    },
    {
        'fullname': 'New Zealand',
        'dial_code': '+64',
        'name': 'NZ',
        'value': 'NZ'
    },
    {
        'fullname': 'Nicaragua',
        'dial_code': '+505',
        'name': 'NI',
        'value': 'NI'
    },
    {
        'fullname': 'Niger',
        'dial_code': '+227',
        'name': 'NE',
        'value': 'NE'
    },
    {
        'fullname': 'Nigeria',
        'dial_code': '+234',
        'name': 'NG',
        'value': 'NG'
    },
    {
        'fullname': 'Niue',
        'dial_code': '+683',
        'name': 'NU',
        'value': 'NU'
    },
    {
        'fullname': 'Norfolk Island',
        'dial_code': '+672',
        'name': 'NF',
        'value': 'NF'
    },
    {
        'fullname': 'Northern Mariana Islands',
        'dial_code': '+1670',
        'name': 'MP',
        'value': 'MP'
    },
    {
        'fullname': 'Norway',
        'dial_code': '+47',
        'name': 'NO',
        'value': 'NO'
    },
    {
        'fullname': 'Oman',
        'dial_code': '+968',
        'name': 'OM',
        'value': 'OM'
    },
    {
        'fullname': 'Pakistan',
        'dial_code': '+92',
        'name': 'PK',
        'value': 'PK'
    },
    {
        'fullname': 'Palau',
        'dial_code': '+680',
        'name': 'PW',
        'value': 'PW'
    },
    {
        'fullname': 'Palestinian Territory, Occupied',
        'dial_code': '+970',
        'name': 'PS',
        'value': 'PS'
    },
    {
        'fullname': 'Panama',
        'dial_code': '+507',
        'name': 'PA',
        'value': 'PA'
    },
    {
        'fullname': 'Papua New Guinea',
        'dial_code': '+675',
        'name': 'PG',
        'value': 'PG'
    },
    {
        'fullname': 'Paraguay',
        'dial_code': '+595',
        'name': 'PY',
        'value': 'PY'
    },
    {
        'fullname': 'Peru',
        'dial_code': '+51',
        'name': 'PE',
        'value': 'PE'
    },
    {
        'fullname': 'Philippines',
        'dial_code': '+63',
        'name': 'PH',
        'value': 'PH'
    },
    {
        'fullname': 'Pitcairn',
        'dial_code': '+872',
        'name': 'PN',
        'value': 'PN'
    },
    {
        'fullname': 'Poland',
        'dial_code': '+48',
        'name': 'PL',
        'value': 'PL'
    },
    {
        'fullname': 'Portugal',
        'dial_code': '+351',
        'name': 'PT',
        'value': 'PT'
    },
    {
        'fullname': 'Puerto Rico',
        'dial_code': '+1939',
        'name': 'PR',
        'value': 'PR'
    },
    {
        'fullname': 'Qatar',
        'dial_code': '+974',
        'name': 'QA',
        'value': 'QA'
    },
    {
        'fullname': 'Romania',
        'dial_code': '+40',
        'name': 'RO',
        'value': 'RO'
    },
    {
        'fullname': 'Russia',
        'dial_code': '+7',
        'name': 'RU',
        'value': 'RU'
    },
    {
        'fullname': 'Rwanda',
        'dial_code': '+250',
        'name': 'RW',
        'value': 'RW'
    },
    {
        'fullname': 'Reunion',
        'dial_code': '+262',
        'name': 'RE',
        'value': 'RE'
    },
    {
        'fullname': 'Saint Barthelemy',
        'dial_code': '+590',
        'name': 'BL',
        'value': 'BL'
    },
    {
        'fullname': 'Saint Helena, Ascension and Tristan Da Cunha',
        'dial_code': '+290',
        'name': 'SH',
        'value': 'SH'
    },
    {
        'fullname': 'Saint Kitts and Nevis',
        'dial_code': '+1869',
        'name': 'KN',
        'value': 'KN'
    },
    {
        'fullname': 'Saint Lucia',
        'dial_code': '+1758',
        'name': 'LC',
        'value': 'LC'
    },
    {
        'fullname': 'Saint Martin',
        'dial_code': '+590',
        'name': 'MF',
        'value': 'MF'
    },
    {
        'fullname': 'Saint Pierre and Miquelon',
        'dial_code': '+508',
        'name': 'PM',
        'value': 'PM'
    },
    {
        'fullname': 'Saint Vincent and the Grenadines',
        'dial_code': '+1784',
        'name': 'VC',
        'value': 'VC'
    },
    {
        'fullname': 'Samoa',
        'dial_code': '+685',
        'name': 'WS',
        'value': 'WS'
    },
    {
        'fullname': 'San Marino',
        'dial_code': '+378',
        'name': 'SM',
        'value': 'SM'
    },
    {
        'fullname': 'Sao Tome and Principe',
        'dial_code': '+239',
        'name': 'ST',
        'value': 'ST'
    },
    {
        'fullname': 'Saudi Arabia',
        'dial_code': '+966',
        'name': 'SA',
        'value': 'SA'
    },
    {
        'fullname': 'Senegal',
        'dial_code': '+221',
        'name': 'SN',
        'value': 'SN'
    },
    {
        'fullname': 'Serbia',
        'dial_code': '+381',
        'name': 'RS',
        'value': 'RS'
    },
    {
        'fullname': 'Seychelles',
        'dial_code': '+248',
        'name': 'SC',
        'value': 'SC'
    },
    {
        'fullname': 'Sierra Leone',
        'dial_code': '+232',
        'name': 'SL',
        'value': 'SL'
    },
    {
        'fullname': 'Singapore',
        'dial_code': '+65',
        'name': 'SG',
        'value': 'SG'
    },
    {
        'fullname': 'Slovakia',
        'dial_code': '+421',
        'name': 'SK',
        'value': 'SK'
    },
    {
        'fullname': 'Slovenia',
        'dial_code': '+386',
        'name': 'SI',
        'value': 'SI'
    },
    {
        'fullname': 'Solomon Islands',
        'dial_code': '+677',
        'name': 'SB',
        'value': 'SB'
    },
    {
        'fullname': 'Somalia',
        'dial_code': '+252',
        'name': 'SO',
        'value': 'SO'
    },
    {
        'fullname': 'South Africa',
        'dial_code': '+27',
        'name': 'ZA',
        'value': 'ZA'
    },
    {
        'fullname': 'South Sudan',
        'dial_code': '+211',
        'name': 'SS',
        'value': 'SS'
    },
    {
        'fullname': 'South Georgia and the South Sandwich Islands',
        'dial_code': '+500',
        'name': 'GS',
        'value': 'GS'
    },
    {
        'fullname': 'Spain',
        'dial_code': '+34',
        'name': 'ES',
        'value': 'ES'
    },
    {
        'fullname': 'Sri Lanka',
        'dial_code': '+94',
        'name': 'LK',
        'value': 'LK'
    },
    {
        'fullname': 'Sudan',
        'dial_code': '+249',
        'name': 'SD',
        'value': 'SD'
    },
    {
        'fullname': 'Suriname',
        'dial_code': '+597',
        'name': 'SR',
        'value': 'SR'
    },
    {
        'fullname': 'Svalbard and Jan Mayen',
        'dial_code': '+47',
        'name': 'SJ',
        'value': 'SJ'
    },
    {
        'fullname': 'Swaziland',
        'dial_code': '+268',
        'name': 'SZ',
        'value': 'SZ'
    },
    {
        'fullname': 'Sweden',
        'dial_code': '+46',
        'name': 'SE',
        'value': 'SE'
    },
    {
        'fullname': 'Switzerland',
        'dial_code': '+41',
        'name': 'CH',
        'value': 'CH'
    },
    {
        'fullname': 'Syrian Arab Republic',
        'dial_code': '+963',
        'name': 'SY',
        'value': 'SY'
    },
    {
        'fullname': 'Taiwan',
        'dial_code': '+886',
        'name': 'TW',
        'value': 'TW'
    },
    {
        'fullname': 'Tajikistan',
        'dial_code': '+992',
        'name': 'TJ',
        'value': 'TJ'
    },
    {
        'fullname': 'Tanzania, United Republic of Tanzania',
        'dial_code': '+255',
        'name': 'TZ',
        'value': 'TZ'
    },
    {
        'fullname': 'Thailand',
        'dial_code': '+66',
        'name': 'TH',
        'value': 'TH'
    },
    {
        'fullname': 'Timor-Leste',
        'dial_code': '+670',
        'name': 'TL',
        'value': 'TL'
    },
    {
        'fullname': 'Togo',
        'dial_code': '+228',
        'name': 'TG',
        'value': 'TG'
    },
    {
        'fullname': 'Tokelau',
        'dial_code': '+690',
        'name': 'TK',
        'value': 'TK'
    },
    {
        'fullname': 'Tonga',
        'dial_code': '+676',
        'name': 'TO',
        'value': 'TO'
    },
    {
        'fullname': 'Trinidad and Tobago',
        'dial_code': '+1868',
        'name': 'TT',
        'value': 'TT'
    },
    {
        'fullname': 'Tunisia',
        'dial_code': '+216',
        'name': 'TN',
        'value': 'TN'
    },
    {
        'fullname': 'Turkey',
        'dial_code': '+90',
        'name': 'TR',
        'value': 'TR'
    },
    {
        'fullname': 'Turkmenistan',
        'dial_code': '+993',
        'name': 'TM',
        'value': 'TM'
    },
    {
        'fullname': 'Turks and Caicos Islands',
        'dial_code': '+1649',
        'name': 'TC',
        'value': 'TC'
    },
    {
        'fullname': 'Tuvalu',
        'dial_code': '+688',
        'name': 'TV',
        'value': 'TV'
    },
    {
        'fullname': 'Uganda',
        'dial_code': '+256',
        'name': 'UG',
        'value': 'UG'
    },
    {
        'fullname': 'Ukraine',
        'dial_code': '+380',
        'name': 'UA',
        'value': 'UA'
    },
    {
        'fullname': 'United Arab Emirates',
        'dial_code': '+971',
        'name': 'AE',
        'value': 'AE'
    },
    {
        'fullname': 'United Kingdom',
        'dial_code': '+44',
        'name': 'GB',
        'value': 'GB'
    },
    {
        'fullname': 'United States',
        'dial_code': '+1',
        'name': 'US',
        'value': 'US'
    },
    {
        'fullname': 'Uruguay',
        'dial_code': '+598',
        'name': 'UY',
        'value': 'UY'
    },
    {
        'fullname': 'Uzbekistan',
        'dial_code': '+998',
        'name': 'UZ',
        'value': 'UZ'
    },
    {
        'fullname': 'Vanuatu',
        'dial_code': '+678',
        'name': 'VU',
        'value': 'VU'
    },
    {
        'fullname': 'Venezuela, Bolivarian Republic of Venezuela',
        'dial_code': '+58',
        'name': 'VE',
        'value': 'VE'
    },
    {
        'fullname': 'Vietnam',
        'dial_code': '+84',
        'name': 'VN',
        'value': 'VN'
    },
    {
        'fullname': 'Virgin Islands, British',
        'dial_code': '+1284',
        'name': 'VG',
        'value': 'VG'
    },
    {
        'fullname': 'Virgin Islands, U.S.',
        'dial_code': '+1340',
        'name': 'VI',
        'value': 'VI'
    },
    {
        'fullname': 'Wallis and Futuna',
        'dial_code': '+681',
        'name': 'WF',
        'value': 'WF'
    },
    {
        'fullname': 'Yemen',
        'dial_code': '+967',
        'name': 'YE',
        'value': 'YE'
    },
    {
        'fullname': 'Zambia',
        'dial_code': '+260',
        'name': 'ZM',
        'value': 'ZM'
    },
    {
        'fullname': 'Zimbabwe',
        'dial_code': '+263',
        'name': 'ZW',
        'value': 'ZW'
    }
]);

angular.module('khUtils').service("CachingUtilsService", function () {


    this.addFactoryToCache = function (factory, key, getRequest, getObjectParams) {
        var data = JSON.parse(sessionStorage.getItem(key));
        // eslint-disable-next-line angular/definedundefined
        if (data !== undefined && data !== null) {
            return data;
        } else {
            if (!getRequest) {
                return factory.query(getObjectParams, function (result) {
                    sessionStorage.setItem(key, JSON.stringify(result));
                    return JSON.parse(sessionStorage.getItem(key));
                });
            } else {
                return factory.get(getObjectParams, function (result) {
                    sessionStorage.setItem(key, JSON.stringify(result));
                    return JSON.parse(sessionStorage.getItem(key));
                });
            }
        }
    };

    this.loadGenericFactory = function (factory, model, session_key) {
        return factory.init(model).query(function (result) {
            return result;
        });
    };

    this.addFactoryWithoutCache = function (factory, getRequest, getObjectParams) {
        if (getRequest) {
            return factory.get(getObjectParams, function (result) {
                return result;
            });
        } else {
            return factory.query(getObjectParams, function (result) {
                return result;
            });
        }
    };

    this.getItemFromCache = function (key) {
        checkExpiryOnLocalStorage();
        return JSON.parse(localStorage.getItem(key));
    };

    function checkExpiryOnLocalStorage() {
        var hours = 540000; // 15 minutes
        var now = new Date().getTime();
        var setupTime = localStorage.getItem('setupTime');
        if (setupTime == null) {
            localStorage.setItem('setupTime', now);
        } else {
            if (now - setupTime > hours) {
                localStorage.clear();
                localStorage.setItem('setupTime', now);
            }
        }
    }

});
angular.module("khFileUploads").service("fileUploads", ["$rootScope", "GenericFileUploadsHelperService", "FileUploadsFactory", "ImageFileUploadsFactory",
	function ($rootScope, GenericFileUploadsHelperService, FileUploadsFactory, ImageFileUploadsFactory) {
	this.saveFiles = function () {
		return $rootScope.model_files_for_app;
	};
	this.saveImages = function () {
		return $rootScope.model_images_for_app;
	};
		this.clearFiles = function () {
			$rootScope.model_files_for_app = [];
		};
	this.displayExistingImages = function (images, dbtype) {
		GenericFileUploadsHelperService.imageFilePathFromObject($rootScope, images, ImageFileUploadsFactory, dbtype);
	};
	this.displayExistingFiles = function (files, dbtype) {
		$rootScope.model_files_for_app = [];
		GenericFileUploadsHelperService.filePathFromObject($rootScope, files, FileUploadsFactory, dbtype);
	};
	}])
	.service("GenericFileUploadsHelperService", [function () {
		this.filePathFromObject = function (scope, data_from_model, FileUploadsFactory, dbtype) {
			angular.forEach(data_from_model, function (value) {
				if (dbtype == 'mysql') {
					FileUploadsFactory.get({id: value}, function (result) {
						scope.$broadcast("file_loaded", result);
					});
				} else if (dbtype == 'mongo') {
					FileUploadsFactory.get({id: value.file_id}, function (result) {
						scope.$broadcast("file_loaded", result);
					});
				}
			})
		};
		this.imageFilePathFromObject = function (scope, data_from_model, FileUploadsFactory, dbtype) {
			switch (dbtype) {
				case 'mysql':
					angular.forEach(data_from_model, function (value) {
						FileUploadsFactory.get({id: value}, function (result) {
							scope.$broadcast("image_file_loaded", result);
						});
					});
					break;
				case 'mongo':
					angular.forEach(data_from_model, function (value) {
						FileUploadsFactory.get({id: value.file_id}, function (result) {
							scope.$broadcast("image_file_loaded", result);
						});
					});
					break;
			}
		};
}]);
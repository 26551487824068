angular.module("khUtils").service("DateTimeHelperFactory", ["$rootScope",
    function ($rootScope) {

        var PythonToMomentDateFormatConvertorMap = {
            "%d %b, %Y": "DD MMM, YYYY",
            "%b %d, %Y": "MMM DD, YYYY",
            "%d %b, %Y %H:%M": "DD MMM, YYYY HH:mm",
            "%b %d, %Y %H:%M": "MMM DD, YYYY HH:mm",
            "%H:%M": "HH:mm",
            "%H:%M:%S": "HH:mm:ss"
        };
        var PythonToAngularDateFormatConvertorMap = {
            "%d %b, %Y": "d MMM, y",
            "%b %d, %Y": "MMM d, y",
            "%d %b, %Y %H:%M": "d MMM, y HH:mm",
            "%b %d, %Y %H:%M": "MMM d, y HH:mm",
            "%H:%M": "HH:mm",
            "%H:%M:%S": "HH:mm:ss"
        };
        this.convertDateTimeFormatFromPythonToMoment = function (dateTimeFormatString) {
            return PythonToMomentDateFormatConvertorMap[dateTimeFormatString];
        };
        this.convertDateTimeFormatFromPythonToAngular = function (dateTimeFormatString) {
            return PythonToAngularDateFormatConvertorMap[dateTimeFormatString];
        };
        this.convertDateFormatUsingMoment = function (date_string, from_format) {
            from_format = from_format || 'YYYY-MM-DDThh:mm:ss';
            return moment(date_string, from_format).format($rootScope.userSettingDateFormat);
        };

        this.convertDateTimeFormatUsingMoment = function (date_time_string, from_format) {
            from_format = from_format || 'YYYY-MM-DDThh:mm:ss';
            return moment(date_time_string, from_format).format($rootScope.userSettingDateTimeFormat);
        };

        this.convertUnixTimeToDateFormatUsingMoment = function (unix_time) {
            return moment(unix_time).format($rootScope.userSettingDateFormat);
        };

        this.convertDatePickerToDateString = function(dateTime) {
            return moment(dateTime).add(12, 'h').format("YYYY-MM-DD");
        };

        this.convertDatePickerToDateStringWithoutHourAdjustment = function(dateTime) {
            return moment(dateTime).format("YYYY-MM-DD");
        };

    }]);
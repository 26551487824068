angular.module('KhNavbar').component('ashesSelect', {
  bindings: {
    label: '@',
    items: '<',
    selectedValue: '<',
    valueMember: '@',
    displayMember: '@',
    onSelect: '&',
    shouldSort: '@',
    emptyLabel: '@',
    emptyValue: '@'
  },
  templateUrl:
    '/static/assets/khapps2/base/navbar/ashes-select/ashes-select.component.html',
  controller: function() {
    const self = this;
    const MAX_SELECT_ITEMS = 50; // Max number of items allowed in a select control. If the number of items is greater than this, we will show an autocomplete control instead of a select.
    const MAX_ITEMS_DISPLAYED = 100; // Max number of items displayed at once in the Autocomplete control
    const DEFAULT_ID_PROPERTY = 'id';
    const DEFAULT_NAME_PROPERTY = 'name';

    this.options = [];
    this.selectedOption = null;
    this.isAutocomplete = null;

    self.$onChanges = function(changes) {
      if (
        (changes.items &&
          itemsHaveChanged(
            changes.items.currentValue,
            changes.items.previousValue
          )) ||
        changes.valueMember ||
        changes.displayMember ||
        changes.shouldSort
      ) {
        const options = generateOptions(
          self.items,
          self.valueMember,
          self.displayMember,
          self.shouldSort
        );

        self.isAutocomplete = options.length > MAX_SELECT_ITEMS;
        self.options = options;
      }

      if (changes.selectedValue) {
        self.selectedOption = self.options.find(
          option => option.id === self.selectedValue
        );
      }
    };

    function itemsHaveChanged(newValue, oldValue) {
      if (newValue === null && oldValue === null) {
        return false;
      }
      if (newValue === null || oldValue === null) {
        return true;
      }
      return newValue.length !== oldValue.length;
    }

    this.onChange = function() {
      self.emitSelect(self.selectedValue);
    };
    self.filter = function(searchText) {
      const text = searchText.toLowerCase();
      return self.options
        .filter(option => option.name.toLowerCase().indexOf(text) === 0)
        .slice(0, MAX_ITEMS_DISPLAYED);
    };
    self.optionSelected = function(option) {
      self.emitSelect(option ? option.id : null);
    };

    self.emitSelect = function(selectedValue) {
      if (self.onSelect) {
        const value = self.valueMember || DEFAULT_ID_PROPERTY;
        const selectedItem = self.items.find(
          item => item[value] === selectedValue
        );
        self.onSelect({ $data: selectedItem });
      }
    };

    /**
     * Generates options based on the items source and the display and value members/properties.
     * @param {*} items list of items to be transform to options
     * @param {*} valueMember property used as value/id
     * @param {*} displayMember property used as description/name
     * @param {*} shouldSort whether the options should be sorted or not -by name-
     */
    function generateOptions(items, valueMember, displayMember, shouldSort) {
      if (!items) {
        return [];
      }

      const display = displayMember || DEFAULT_NAME_PROPERTY;
      const value = valueMember || DEFAULT_ID_PROPERTY;
      const options = items.map(item => ({
        id: item[value],
        name: item[display]
      }));

      if (shouldSort) {
        options.sort(function(a, b) {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
      }

      return options;
    }
  }
});

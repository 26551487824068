angular.module("KhBase").factory("LegacyOperationFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/operations/data/:operation_slug/', {}, {
        query: {
            method: 'GET',
            isArray: false
        },
        save: {
            method: 'POST'
        },
        update: {
            method: 'PUT'
        },
        patch: {
            method: 'PATCH',
            params: {method: "status"}
        },
        get: {
            method: 'GET'
        },
        delete: {
            method: 'DELETE'
        },
        detail: {
            url: 'api/v2.0/operation/detail/:operation_slug/',
            method: 'GET'
        }
    });
}]);

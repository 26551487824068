angular.module("khUtils").directive('khTabs', function () {
	return {
		restrict: 'E',
		scope: false,
		replace: false,
		templateUrl: '/static/assets/khapps2/base/khutils/partials/kh_tabs.html',
		link: function (scope, element, attrs, controllers) {
			scope.tabdetails = JSON.parse(attrs["tabDetails"]);
		}
	};
});
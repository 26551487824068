angular.module('khUtils').service('GenericUtilsService', function() {
  var self = this;

  this.isEmpty = function(obj) {
    var hasOwnProperty = Object.prototype.hasOwnProperty;

    if (obj == null) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== 'object') return true;
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  };

  this.updateListAfterEdit = function(
    all_objects,
    slug_key,
    required_slug,
    response
  ) {
    for (var i = 0; i < all_objects.length; i++) {
      if (all_objects[i][slug_key] === required_slug) {
        all_objects[i] = response;
      }
    }
    return all_objects;
  };

  this.loadTable = function(NgTableParams, dict) {
    return new NgTableParams({}, { dataset: dict });
  };

  this.handleError = function($filter, $khMessage, error, duration) {
    if (!duration) duration = 10000;
    if (error.status == 400) {
      $khMessage.show($filter('translate')('error_message_400'), duration);
    } else if (error.status == 409) {
      $khMessage.show(error.data, duration);
    } else {
      $khMessage.show($filter('translate')('default_error_message'), duration);
    }
  };

  this.handleErrorV2 = function($filter, $khMessage, error) {
    if (error.status === 401) {
      $khMessage.show($filter('translate')('error_message_401'));
    } else if (error.status === 402) {
      $khMessage.show($filter('translate')('error_message_402'));
    } else if (error.status === 403) {
      $khMessage.show($filter('translate')('error_message_403'));
    } else if (error.status === 404) {
      $khMessage.show($filter('translate')('error_message_404'));
    } else if (error.data.message) {
      $khMessage.show(error.data.message);
    } else {
      $khMessage.show($filter('translate')('default_error_message'));
    }
  };
});

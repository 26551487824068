angular.module("khUtils").directive('khCircularLoader', ['$http', "$compile",
	function ($http, $compile) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var el = angular.element("<md-progress-circular md-mode='indeterminate'></md-progress-circular>");
				$compile(el)(scope);
				element.prepend(el);
				scope.isLoading = function () {
					return $http.pendingRequests.length > 0;
				};
				scope.$watch(scope.isLoading, function (v) {
					if (v) {
						el.show();
					} else {
						el.hide();
					}
				});
			}
		};
	}]);
function getUrlParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
var from_mobile = (getUrlParameterByName('from_mobile') == 'true');
if (from_mobile) {
    var sheet = (function () {
        var style = document.createElement("style");
        style.appendChild(document.createTextNode(""));
        document.head.appendChild(style);
        return style.sheet;
    })();
    sheet.insertRule(".external-toolbar { display:none!important;}", 0);
    sheet.insertRule(".report-card { padding-top:20px!important}", 1);
}
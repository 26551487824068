angular.module('KhBase').factory("FarmFieldFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/fms/farmfield/', {}, {
        save: {
            method: 'POST'
        },
        get: {
            params: {field_slug: '@field_slug'},
            url: '/api/v1.0/fms/farmfield/:field_slug/',
            method: 'GET'
        },
        update: {
            params: {field_slug: '@field_slug'},
            url: '/api/v1.0/fms/farmfield/:field_slug/',
            method: 'PUT'
        },
        delete: {
            params: {field_slug: '@field_slug'},
            url: '/api/v1.0/fms/farmfield/:field_slug/',
            method: 'DELETE'
        }
    });
}]);

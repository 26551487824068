angular.module("KhNavbar")
/**
 * Farm / field / field crop filter service. This is intended to be used in secondary nav bars
 *
 * We fetch a summary of all farms and fields in a single API call for efficiency.
 */
    .service('FarmFiltersService', function (GrowingSeasonFarmFieldFilterFactory,
                                             TeamSelectionService,
                                             PlantingSeasonListFactory,
                                             UserSettingsService,
                                             $q, $resource,
                                             $rootScope) {
        var self = this;
        var ALL_FARMS = "All Farms";
        var ALL_FIELDS = "All Fields";
        var subject = new Rx.BehaviorSubject();

        //
        // PUBLIC
        //

        self.team_slug = null;
        self.team_id = null;

        // Available
        self.farms = [];
        self.growingSeasonNames = [];
        self.farmFields = [];
        self.farmsById = {};
        self.farmsBySlug = {};
        self.fieldsBySlug = {};
        self.fieldsById = {};

        // Selected filter options
        self.filter = {};
        let initialLoad = false;

        /**
         * Return an observation for the entire filter. Note that this will published when the initial list
         * of possible farm & fields is loaded, and from then on whenever the filter is changed by the user
         * @return {Rx.BehaviorSubject} observable. This is a BehaviorSubject so it will always have a current value
         */
        this.get$ = function () {
            return subject;
        };

        /**
         * Return the slug of the currently selected farm
         * @return farm slug
         */
        this.getSelectedFarmSlug = function () {
            return self.filter.farm_slug;
        };

        /**
         * Return the currently selected farm
         * @return farm obj
         */
        this.getSelectedFarm = function () {
            return _.findWhere(self.farms, {'slug': self.filter.farm_slug});
        };


        /**
         * Return the currently selected field
         * @return field obj
         */
        this.getSelectedField = function () {
            return _.findWhere(self.farmFields, {'slug': self.filter.field_slug});
        };

        /**
         * Return the slug of the currently selected field
         * @return field slug
         */
        this.getSelectedFieldSlug = function () {
            return self.filter.field_slug;
        };


        /**
         * Return the currently selected growing season
         * @return {null}
         */
        this.getGrowingSeason = function () {
            return self.filter.growing_season;
        };

        /**
         * Select a farm by slug
         * @param slug - farm slug
         */
        this.selectFarm = function (slug) {
            self.farmSelected(findFarmBySlug(slug));
        };
        function findFarmBySlug(slug) {
            return _.findWhere(self.farms, {'slug': slug});
        }


        /**
         * Called by the component when a farm is selected. Refresh the possible list of fields
         */
        this.farmSelected = function (farm) {
            // ignore if the select matches the previous value - for some reason md-autocomplete
            // updates md-selected-item multiple times
            const farm_slug = (farm && farm.slug) ? farm.slug : "All Farms";
            if (farm_slug !== self.filter.farm_slug) {
                self.filter.farm_id = farm.id;
                self.filter.farm_slug = farm_slug;
                setFarmFields();
                self.filter.field_slug = null;
                self.filter.field_id = null;
                publish(true);
            }
        };

        /**
         * Called by the component when a field is selected.
         */
        this.fieldSelected = function (field) {
            if (field) {
                self.filter.field_slug = field.slug;
                self.filter.field_id = field.id;
            } else {
                self.filter.field_slug = self.filter.field_id = null;
            }
            publish(true);
        };

        /**
         * Called by the component when the season is selected
         */
        this.seasonSelected = function () {
            publish(true);
        };

        /**
         * Called when a farm is updated e.g via the myfarm edit dialog
         */
        this.farmUpdated = function (id, name, slug) {
            const farmDetails = findFarmById(id);
            if (!farmDetails) {
                const f = {'id': id, 'name': name, 'slug': slug, 'fields': [], 'value': slug};
                self.farms = [...self.farms, f];
                self.farmsBySlug[slug] = f;
            } else {
                farmDetails.name = name;
            }
        };

        /**
         * Reload everything
         * @returns Promise
         */
        this.reload = ()=>{
          return fetchFarmSummaries();
        };

        /**
         * Called when a field is updated e.g via the myfarm edit dialog
         */
        this.fieldUpdated = function () {
            // just refetch the entire list for now - it's quick enough
            return fetchFarmSummaries();
        };

        /**
         * A farm has been deleted
         */
        this.farmDeleted = function () {
            return fetchFarmSummaries();
        };

        /**
         * Check if a field exists with this name
         * @param farm_slug - farm slug
         * @param field_name - field name
         * @param exclude_field_slug - exclude this field
         */
        this.checkFarmFieldName = function (farm_slug, field_name, exclude_field_slug) {
            var farm = _.findWhere(self.farms, {'slug': farm_slug});
            if (farm) {
                return _.find(farm.fields, function (fld) {
                    return (fld.name === field_name && fld.slug !== exclude_field_slug);
                });
            }
        };

        /**
         * Return a field by it's id
         * @param id - field id
         * @returns field
         */
        this.getFieldById = function (id) {
            return self.fieldsById[id];
        };

        /**
         * Return all farms
         * @returns array of farms
         */
        this.getFarms = function () {
            return _.filter(self.farms, function (farm) {
                return farm.slug != ALL_FARMS;
            });
        };

        /**
         * Return all seasons
         * @returns array of seasons
         */
        this.getGrowingSeasonNames = function () {
            return self.growingSeasonNames;
        };

        //
        // PRIVATE
        //

        function findFieldById(id) {
            return _.findWhere(self.farms, {'id': id});
        }

        function findFarmById(id) {
            return _.findWhere(self.farms, {'id': id});
        }

        function findFarmBySlug(slug) {
            return _.findWhere(self.farms, {'slug': slug});
        }

        /**
         * Update the list of farm fields, either when a new farm is selected or when the list of farms
         * is initialised
         */
        function setFarmFields() {
            const s = self.filter.farm_slug;
            if (s) {
                var farm = self.farmsBySlug[s];
                if (!farm) {
                    self.filter.farm_id = null;
                    self.filter.farm_slug = null;
                    self.farmFields = [];
                } else {
                    self.farmFields = self.farmsBySlug[s].fields;
                }
            } else {
                self.field_slug = null;
                self.field_id = null;
                self.farmFields = null;
            }
        }

        /**
         * Publish the new filter to the world. The new way is to publish via an RxJS observable,
         * but we update the old $rootScope stuff as well.
         * @param saveSession - if true then save in the session state API
         */
        function publish(saveSession) {
            // Broadcast to rootScope for legacy code
            legacyBroadcast();
            // now use the new RxJs pub/sub channel
            var copy = angular.copy(self.filter);
            copy.team_slug = self.team_slug;
            subject.onNext(copy);
            if (saveSession) {
                // store the current filter in local storage
                sessionStorage.setItem('navbarFilter', JSON.stringify(self.filter));

                // legacy session API - there may well be nasty server-side code that's relying on this
                GrowingSeasonFarmFieldFilterFactory.save({
                    growing_season: self.filter.growing_season,
                    farm: self.filter.farm_slug,
                    field: self.filter.field_slug
                });
            }
        }

        // FIXME to ensure that legacy code still works, we also publish to $rootScope
        // I'm fairly sure these are no longer needed, but they can stay here for now until I'm certain

        function legacySetSelectedSeason() {
            if (self.filter.growing_season &&
                (!$rootScope.selected_growing_season ||
                    ($rootScope.selected_growing_season.name !== self.filter.growing_season))) {
                $rootScope.selected_growing_season = {
                    name: self.filter.growing_season,
                    value: self.filter.growing_season
                };
            }
        }

        function legacySetSelectedFarm() {
            if (!self.filter.farm_slug || self.filter.farm_slug === ALL_FARMS) {
                if ($rootScope.selected_farm && $rootScope.selected_farm.name !== ALL_FARMS) {
                    $rootScope.selected_farm = {name: ALL_FARMS, value: ALL_FARMS};
                }
            } else {
                $rootScope.selected_farm = _.findWhere(self.farms, {slug: self.filter.farm_slug});
            }
        }

        function legacySetSelectedField() {
            if (!self.filter.field_slug || self.filter.field_slug === ALL_FIELDS) {
                if ($rootScope.selected_field && $rootScope.selected_field.name !== ALL_FIELDS) {
                    $rootScope.selected_field = {name: ALL_FIELDS, value: ALL_FIELDS};
                }
            } else {
                $rootScope.selected_field = _.findWhere(self.farmFields, {slug: self.filter.field_slug});
            }
        }

        // update the existing $rootScope fields and broadcast that they have changed
        function legacyBroadcast() {
            legacySetSelectedSeason();
            legacySetSelectedFarm();
            legacySetSelectedField();
            $rootScope.$broadcast('filter:selections_updated', self.filter);
        }

        /**
         * Fetch all valid options in parallel for the named team
         * @param team - team object
         */
        function fetchOptions(team) {
            let team_id = (team)? team.id: null;
            const summary_qp = team_id ? {"team_id": team_id}: null;
            $q.all([
                $resource('/api/field/summary-by-farm/').query(summary_qp).$promise,
                PlantingSeasonListFactory.query({as_dict: "yes"}).$promise,
                $resource('/api/v1.0/khutils/currentplantingseason/').get().$promise,
            ])
                .then(function (results) {
                    self.growingSeasonNames = results[1];
                    // store last filter in local storage
                    const lastFilter = sessionStorage.getItem('navbarFilter')
                    if (lastFilter && !initialLoad) {
                        self.filter = JSON.parse(lastFilter);
                        initialLoad = true;
                    }

                    // now the next bit is really only for local dev work, as I got
                    if (self.filter.farm_id && !self.farmsById[self.filter.farm_id]) {
                        // unknown farm
                        self.filter.farm_id = null;
                        self.filter.field_id = null;
                    }
                    // unknown field
                    if (self.filter.field_id && !self.fieldsById[self.filter.field_id]) {
                        self.filter.field_id = null;
                    }

                    if (team) {
                        self.filter.team_id = team_id;
                        self.filter.team_slug = team.slug;
                    } else {
                        self.filter.team_id = self.filter.team_slug = null;
                    }

                    updateFarmFieldList(results[0]);

                    if (self.filter.farm_slug && !self.filter.farm_id) {
                        const farm = self.farmsBySlug[self.filter.farm_slug];
                        if (farm) {
                            self.filter.farm_id = farm.id;
                        }
                    }
                    if (self.filter.field_slug && !self.filter.field_id) {
                        const field = self.fieldsBySlug[self.filter.field_slug];
                        if (field) {
                            self.filter.field_id = field.id;
                        }
                    }

                    if (UserSettingsService.DEFAULT_USER_FARM && !self.filter.farm_id) {
                        // override farm
                        const farm = self.farmsBySlug[self.filter.farm_slug];
                        if (farm) {
                            self.filter.farm_slug = UserSettingsService.DEFAULT_USER_FARM;
                            self.filter.farm_id = farm.id;
                        }
                    }
                    if (UserSettingsService.DEFAULT_USER_FIELD  && !self.filter.field_id) {
                        // override field
                        const field = self.fieldsBySlug[self.filter.field_slug];
                        if (field) {
                            self.filter.field_slug = UserSettingsService.DEFAULT_USER_FIELD;
                            self.filter.field_id = field.id;
                        }
                    }
                    if (self.filter.farm_id) {
                        self.selectedFarm = self.farmsById[self.filter.farm_id];
                    }
                    if (self.filter.field_id) {
                        self.selectedField = self.fieldsById[self.filter.field_id];
                    }
                    if (!self.filter.growing_season) {
                        // use the current one
                        self.filter.growing_season = results[2].name;
                    }
                    setFarmFields();
                    publish();
                });
        }

        /**
         * Simple helper to update the farms list. We also have a map that is indexed by slug for convenience
         * @param data
         */
        function updateFarmFieldList(data) {
            var allFarm = {
                "fields": [],
                'id': null,
                'name': ALL_FARMS,
                'slug': ALL_FARMS,
                'value': ALL_FARMS
            };
            _.forEach(data, function (farm) {
                allFarm["fields"] = allFarm["fields"].concat(farm["fields"])
            });

            data.unshift(allFarm);
            self.farms = data;
            self.farmsById = _.indexBy(data, 'id');
            self.farmsBySlug = _.indexBy(data, 'slug');
            self.fieldsBySlug = _.indexBy(allFarm.fields, 'slug');
            self.fieldsById = _.indexBy(allFarm.fields, 'id');
        }

        /**
         * Refetch the farm summaries
         * @returns Promise
         */
        function fetchFarmSummaries() {
            return $resource('/api/field/summary-by-farm/').query({"team_id": self.team_id}, function (data) {
                updateFarmFieldList(data);
                setFarmFields();
            }).$promise;
        }

        /**
         * Subscribe to the top nav team observable - this will cause us to fetch our list of farm/field
         * options initially and on team change.
         * Since this service is a singleton there is no need to unsubscribe
         */
        TeamSelectionService.get$()
            .subscribe(function (team) {
                if (team) {
                    self.team_slug = team.slug;
                    self.team_id = team.id;
                } else {
                    self.team_id = self.team_slug = null;
                }
                self.filter = {};
                fetchOptions(team);
            });

        /**
         * Set default field farm in secondary nav bar
         */
        this.setDefaultFieldFarm = function () {
            // Check if field not set
            if (!self.filter.field_slug || self.filter.field_slug === ALL_FIELDS) {

                // Checking if farm not set already
                if (!self.filter.farm_slug || self.filter.farm_slug === ALL_FARMS) {

                    // Setting first farm
                    if (self.farms.length > 0) {
                        var farm = self.farms[0];
                        self.filter.farm_id = farm.id;
                        self.filter.farm_slug = farm.slug;
                        self.selectedFarm = farm;

                        // Setting first field
                        self.farmFields = self.farmsBySlug[farm.slug].fields;
                        if (self.farmFields.length > 0) {
                            self.filter.field_id = self.farmFields[0].id;
                            self.filter.field_slug = self.farmFields[0].slug;
                        }
                        publish(true);
                    }

                } else {
                    // Farm has already been set, setting first field
                    if (self.farmFields.length > 0) {
                        self.filter.field_slug = self.farmFields[0].slug;
                        publish(true);
                    }
                }
            }
        };

    });

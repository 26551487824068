angular.module("khUtils").directive('khTabsDialog', function ($mdDialog) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			scope: {
				'tabdetails': '=',
				'dialogtitle': '@'
			},
			link: function (scope, element, attrs, controllers) {
				scope.cancel = function () {
					$mdDialog.cancel();
				}
			},
			templateUrl: '/static/assets/khapps2/base/khutils/partials/kh_tabs.html'
		};
	}
);
angular.module("khUtils").directive('khSelectForApp', function () {
	return {
		restrict: 'E',
		transclude: true,
		require: 'ngModel',
		replace: true,
		scope: {
			'data': '=',
			'titletext': '@',
			'placeholdertext': '@',
			'onSelect': '&onSelect',
			'datamodel': '=ngModel'
		},
		templateUrl: '/static/assets/khapps2/base/khutils/partials/khSelectForApp.html',
		link: function (scope, element, attrs, controllers) {
			element.find('input').on('keydown', function (ev) {
				ev.stopPropagation();
			});
		}
	};
});
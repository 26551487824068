import { map } from 'rxjs/operators';

/**
 * This service manages the currently selected team. It's a bridge between the new Angular TeamSelectionService
 * and the nasty $rootScope-infested A1.x code
 */
angular
  .module('KhNavbar')
  .service('SelectedTeamService', function($rootScope, TeamSelectionService) {
    /**
     * Have all teams been selected?
     * @return {boolean} - True if "all teams" have been selected
     */
    this.allTeamsSelected = function() {
      return TeamSelectionService.allTeamsSelected();
    };

    /**
     * Return the slug for the currently selected team, or null if "All Teams" is selected
     * @return {string} - team slug, or null
     */
    this.getSelectedSlug = function() {
      return TeamSelectionService.getCurrentSlug();
    };

    /**
     * Return the slug for the currently selected team, or null if "All Teams" is selected
     * @return {number} - team id, or null
     */
    this.getSelectedId = function() {
        return TeamSelectionService.getCurrentId();
    };

    /**
     * Return the Subject for the current selected team *slug*
     * This is mostly only used by the legacy Labs code
     * @return {Rx.Subject}
     */
    this.getTeamSlug$ = function() {
      return TeamSelectionService.get$().pipe(
        map(team => {
          team ? team.slug : null;
        })
      );
    };

    this.getSelectedId = function() {
      return TeamSelectionService.getCurrentId();
    };
  });

/**
 * This service manages the currently selected app -side nav bar-.
 */
angular.module("KhNavbar").service('SideNavBarService', function() {
    var appSubject$ = new Rx.BehaviorSubject();
    var self=this;
    
    self.selected = null;

    /**
     * Set the selected app (menu option)
     * @param app - app
     */
    self.setSelected = function(app) {
        self.selected = app;
        appChanged(app);
    };

    /**
     * Return the Subject for the current selected app
     * @return {Rx.Subject}
     */
    self.getApp$ = function() {
        return appSubject$;
    };

    /**
     * Private function called when the selected app has changed
     * @param app
     */
    function appChanged(app) {
        appSubject$.onNext(app);
    }
});
angular.module("khFileUploads").controller("khFileUploadsController", ["$scope", "$rootScope", "$cookies", "khFileUploadsFactory",
	"FileUploadsFactory",
	function ($scope, $rootScope, $cookies, khFileUploadsFactory, FileUploadsFactory) {
		function initialize() {
			$scope.displayable_files = [];
			$rootScope.model_files_for_app = [];
		}

		initialize();
		$scope.getFileDetails = function (e) {
			if ($scope.displayable_files.length < parseInt($scope.maxfiles) && e.files.length <= parseInt($scope.maxfiles)) {
				if (e.files.length <= parseInt($scope.maxfiles)) {
					angular.forEach(e.files, function (value, key) {
						if ($scope.displayable_files.length > 0 && !$scope.allowduplicates) {
							angular.forEach($scope.displayable_files, function (val) {
								if (value.name == val.file_name) {
									window.alert("File with same name has already been uploaded");
								} else {
									$scope.uploading_progress_bar = true;
									khFileUploadsFactory.uploadFile(value, $cookies, $scope);
								}
							})
						} else {
							$scope.uploading_progress_bar = true;
							khFileUploadsFactory.uploadFile(value, $cookies, $scope);
						}
					});
				} else {
					window.alert("You can select a maximum of " + $scope.maxfiles + " files only.");
				}
			} else {
				window.alert("You can select a maximum of " + $scope.maxfiles + " files only.");
			}
		};
		$scope.$on("file_uploaded", function (event, data) {
			$scope.uploading_progress_bar = false;
			$scope.displayable_files.push(data);
			switch ($scope.dbtype) {
				case 'mysql':
					$rootScope.model_files_for_app.push(data.id);
					break;
				case 'mongo':
					$rootScope.model_files_for_app.push({
						file_path: data.file,
						file_id: data.id,
						file_name: data.file_name
					});
					break;
			}
			$scope.$apply();
			return $rootScope.model_files_for_app;
		});
		$scope.removeFile = function (file, dbtype) {
			FileUploadsFactory.delete({id: file.id}, function (result) {
				$scope.deleteFile(file, dbtype);
			});
		};
		$scope.deleteFile = function (file, dbtype) {
			switch (dbtype) {
				case 'mysql':
					angular.forEach($rootScope.model_files_for_app, function (value, key) {
						if (value == file.id) {
							$rootScope.model_files_for_app.splice(key, 1);
						}
					});
					break;
				case 'mongo':
					angular.forEach($rootScope.model_files_for_app, function (value, key) {
						if (value.file_id == file.id) {
							$rootScope.model_files_for_app.splice(key, 1);
						}
					});
					break;
			}
			angular.forEach($scope.displayable_files, function (value, key) {
				if (value.file_name == file.file_name) {
					$scope.displayable_files.splice(key, 1);
				}
			});
			if ($rootScope.model_files_for_app.length == 0) {
				initialize();
			} else {
				return $rootScope.model_files_for_app;
			}
		};
		function containsObject(obj, list) {
			var i;
			for (i = 0; i < list.length; i++) {
				if (list[i].file_name === obj.file_name) {
					return true;
				}
			}

			return false;
		}
		$scope.$on("file_loaded", function (result, data) {
			$scope.displayable_files.push(data);
			switch ($scope.dbtype) {
				case 'mysql':
					$rootScope.model_files_for_app.push(data.id);
					break;
				case 'mongo':
					if (containsObject(data, $rootScope.model_files_for_app)) {

					} else {
						$rootScope.model_files_for_app.push({
							file_path: data.file,
							file_name: data.file_name,
							file_id: data.id
						});
					}

					break;
			}
			return $rootScope.model_files_for_app;
		});
		$scope.displayPreviews = function (file) {
			if (file) {
				switch ($scope.filetype) {
					case 'files':
						return file.file.substr(file.file.lastIndexOf('.') + 1);
					case 'images':
						return file.image_file.substr(file.image_file.lastIndexOf('.') + 1);
						break;
				}
			}
		};
	}]);
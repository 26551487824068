angular.module("khUtils").directive('khLoader', ['$http', "$compile",
	function ($http, $compile) {
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			if (attrs["khLoader"] == "true") {
				var el = angular.element("<md-progress-linear class='md-accent' md-mode='indeterminate'></md-progress-linear>");
				$compile(el)(scope);
				element.prepend(el);
				scope.isLoading = function () {
					return $http.pendingRequests.length > 0;
				};
				scope.$watch(scope.isLoading, function (v) {
					if (v) {
						el.show();
					} else {
						el.hide();
					}
				});
			}

		}
	};
}]);
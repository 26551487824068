// Poor name but there are already 2 other PlatformSettingsResources
angular.module('khUtils').service('PlatformSettingsService', function($resource) {

    var self = this;
    self.promise = $resource('/api/v1.0/khutils/platformsettings/').get(function (settings) {
        _.extend(self, settings);
    }).$promise;

});


angular.module('khUtils').service("WeatherUtilsService", function () {

    var self = this;

    this.windDirectionImageMap = function () {
        return {

            'N': require('../../../khapps/weather/img/winddirections/white/S_white.png'),
            'NNE': require('../../../khapps/weather/img/winddirections/white/SSW_white.png'),
            'NE': require('../../../khapps/weather/img/winddirections/white/SW_white.png'),
            'ENE': require('../../../khapps/weather/img/winddirections/white/WSW_white.png'),
            'E': require('../../../khapps/weather/img/winddirections/white/W_white.png'),
            'ESE': require('../../../khapps/weather/img/winddirections/white/WNW_white.png'),
            'SE': require('../../../khapps/weather/img/winddirections/white/NW_white.png'),
            'SSE': require('../../../khapps/weather/img/winddirections/white/NNW_white.png'),
            'S': require('../../../khapps/weather/img/winddirections/white/N_white.png'),
            'SSW': require('../../../khapps/weather/img/winddirections/white/NNE_white.png'),
            'SW': require('../../../khapps/weather/img/winddirections/white/NE_white.png'),
            'WSW': require('../../../khapps/weather/img/winddirections/white/ENE_white.png'),
            'W': require('../../../khapps/weather/img/winddirections/white/E_white.png'),
            'WNW': require('../../../khapps/weather/img/winddirections/white/ESE_white.png'),
            'NW': require('../../../khapps/weather/img/winddirections/white/SE_white.png'),
            'NNW': require('../../../khapps/weather/img/winddirections/white/SSE_white.png')
        };

    };

    this.windDirectionInterpretationMap = function () {
        return {
            'N': 'From North',
            'NNE': 'From North North East',
            'NE': 'From North East',
            'ENE': 'From East North East',
            'E': 'From East',
            'ESE': 'From East South East',
            'SE': 'From South East',
            'SSE': 'From South South East',
            'S': 'From South',
            'SSW': 'From South South West',
            'SW': 'From South West',
            'WSW': 'From West South West',
            'W': 'From West',
            'WNW': 'From West North West',
            'NW': 'From North West',
            'NNW': 'From North North West'
        };
    };

    this.windDirectionChartImageMap = function () {
        return {

            'N': require('../../../khapps/weather/img/winddirections/black/S_black.png'),
            'NNE': require('../../../khapps/weather/img/winddirections/black/SSW_black.png'),
            'NE': require('../../../khapps/weather/img/winddirections/black/SW_black.png'),
            'ENE': require('../../../khapps/weather/img/winddirections/black/WSW_black.png'),
            'E': require('../../../khapps/weather/img/winddirections/black/W_black.png'),
            'ESE': require('../../../khapps/weather/img/winddirections/black/WNW_black.png'),
            'SE': require('../../../khapps/weather/img/winddirections/black/NW_black.png'),
            'SSE': require('../../../khapps/weather/img/winddirections/black/NNW_black.png'),
            'S': require('../../../khapps/weather/img/winddirections/black/N_black.png'),
            'SSW': require('../../../khapps/weather/img/winddirections/black/NNE_black.png'),
            'SW': require('../../../khapps/weather/img/winddirections/black/NE_black.png'),
            'WSW': require('../../../khapps/weather/img/winddirections/black/ENE_black.png'),
            'W': require('../../../khapps/weather/img/winddirections/black/E_black.png'),
            'WNW': require('../../../khapps/weather/img/winddirections/black/ESE_black.png'),
            'NW': require('../../../khapps/weather/img/winddirections/black/SE_black.png'),
            'NNW': require('../../../khapps/weather/img/winddirections/black/SSE_black.png')
        };
    };

    this.MessageWeatherChartDataLoading = "Loading data...";

    this.parameterUINameConvertorMap = function () {
        return {

            "temperature": 'Temperature',
            "mean_temperature": 'Avg Temperature',
            "max_temperature": 'Maximum Temperature',
            "min_temperature": 'Minimum Temperature',

            "dew_point": 'Dew Point',
            "min_dew_point": 'Min Dew Point',
            "max_dew_point": 'Max Dew Point',
            "mean_dew_point": 'Avg Dew Point',

            "windchill": 'Wind Chill',
            "min_windchill": 'Min Wind Chill',
            "max_windchill": 'Max Wind Chill',
            "mean_windchill": 'Avg Wind Chill',

            "heat_index": 'Heat Index',
            "min_heat_index": 'Min Heat Index',
            "max_heat_index": 'Max Heat Index',
            "mean_heat_index": 'Avg Heat Index',

            'pressure': 'Pressure',
            'mean_pressure': 'Avg Pressure',
            'min_pressure': 'Min Pressure',
            'max_pressure': 'Max Pressure',

            'relative_humidity': 'Relative Humidity',
            'mean_relative_humidity': 'Avg Relative Humidity',
            'min_relative_humidity': 'Min Relative Humidity',
            'max_relative_humidity': 'Max Relative Humidity',

            'wind_direction': 'Wind Direction',
            'mode_wind_direction': 'Wind Direction',

            'wind_speed': 'Wind Speed',
            'mean_wind_speed': 'Avg Wind Speed',
            'min_wind_speed': 'Min Wind Speed',
            'max_wind_speed': 'Max Wind Speed',

            'precipitation': 'Rainfall',
            'total_precipitation': 'Total Rainfall',

            'solar_irradiance': 'Solar Radiation',

            'solar_radiation': 'Solar Radiation',
            'min_solar_radiation': 'Min Solar Radiation',
            'max_solar_radiation': 'Max Solar Radiation',
            'mean_solar_radiation': 'Avg Solar Radiation',

            'photosynthetic_active_radiation': 'PAR',
            'min_photosynthetic_active_radiation': 'Min PAR',
            'max_photosynthetic_active_radiation': 'Max PAR',
            'mean_photosynthetic_active_radiation': 'Avg PAR',

            'soil_temperature': 'Soil Temperature',
            'min_soil_temperature': 'Min Soil Temperature',
            'max_soil_temperature': 'Max Soil Temperature',
            'mean_soil_temperature': 'Avg Soil Temperature',

            'weather_type': 'Weather Type',
            'soil_moisture': 'Soil Moisture',
            'spray_operation': 'Spray',
            'sowing_operation': 'Sowing',
            'fertiliser_operation': 'Fertiliser',
            "sunshine": "Sunshine",
            "max_dailytemperature": "Maximum Temperature",
            "min_dailytemperature": "Minimum Temperature",
            "avg_temperature": "Avg Temperature",
            "airfrost": "Airfrost",
            'ref_et_hargreaves': 'ETo Hargreaves',
            'ref_et_penman': 'ETo Penman-Monteith',
            'date_time': 'Time',
            'SOLAR_RADIATION': 'Solar Radiation',
            'SOIL_MOISTURE': 'Soil Moisture',
            'SOIL_SALINITY': 'Soil Salinity',
            'SOIL_TEMPERATURE': 'Soil Temperature',

            "uv_index": "UV Index",
            "min_uv_index": "Min UV Index",
            "max_uv_index": "Max UV Index",
            "mean_uv_index": "Avg UV Index",

            "uva": "UVA",
            "min_uva": "Min UVA",
            "max_uva": "Max UVA",
            "mean_uva": "Avg UVA",

            "uvb": "UVB",
            "min_uvb": "Min UVB",
            "max_uvb": "Max UVB",
            "mean_uvb": "Mean UVB",

            "soil_water_tension": "Soil Water Tension",
            "apparent_temperature": "Apparent Temperature",
            "Precipitation": "Rainfall"
        };
    };

    this.parameterUnitMap = function () {
        return {
            "temperature": '(deg C)',
            "mean_temperature": '(deg C)',
            "max_temperature": '(deg C)',
            "min_temperature": '(deg C)',
            "max_dailytemperature": "(deg C)",
            "min_dailytemperature": "(deg C)",

            "dew_point": '(deg C)',
            "min_dew_point": '(deg C)',
            "max_dew_point": '(deg C)',
            "mean_dew_point": '(deg C)',

            "windchill": '(deg C)',
            "min_windchill": '(deg C)',
            "max_windchill": '(deg C)',
            "mean_windchill": '(deg C)',

            "heat_index": '(deg C)',
            "min_heat_index": '(deg C)',
            "max_heat_index": '(deg C)',
            "mean_heat_index": '(deg C)',

            'pressure': '(hPa)',
            'mean_pressure': '(hPa)',
            'min_pressure': '(hPa)',
            'max_pressure': '(hPa)',

            'relative_humidity': '(%)',
            'mean_relative_humidity': '(%)',
            'min_relative_humidity': '(%)',
            'max_relative_humidity': '(%)',

            'wind_direction': '',
            'mode_wind_direction': '',

            'wind_speed': '(mps)',
            'mean_wind_speed': '(mps)',
            'min_wind_speed': '(mps)',
            'max_wind_speed': '(mps)',

            'precipitation': '(mm)',
            'total_precipitation': '(mm)',
            'Precipitation': '(mm)',

            'solar_irradiance': '(w/m2)',
            'solar_radiation': '(w/m2)',
            'min_solar_radiation': '(w/m2)',
            'max_solar_radiation': '(w/m2)',
            'mean_solar_radiation': '(w/m2)',

            'photosynthetic_active_radiation': '(w/m2)',
            'min_photosynthetic_active_radiation': '(w/m2)',
            'max_photosynthetic_active_radiation': '(w/m2)',
            'mean_photosynthetic_active_radiation': '(w/m2)',

            'soil_temperature': '(deg C)',
            'min_soil_temperature': '(deg C)',
            'max_soil_temperature': '(deg C)',
            'mean_soil_temperature': '(deg C)',

            'spray_operation': '',
            'sowing_operation': '',
            'fertiliser_operation': '',
            'ref_et_hargreaves': '(mm)',
            'ref_et_penman': '(mm)',

            "uv_index": '',
            "min_uv_index": "",
            "max_uv_index": "",
            "mean_uv_index": "",

            "uva": '(w/m2)',
            "min_uva": "(w/m2)",
            "max_uva": "(w/m2)",
            "mean_uva": "(w/m2)",

            "uvb": '(w/m2)',
            "min_uvb": "(w/m2)",
            "max_uvb": "(w/m2)",
            "mean_uvb": "(w/m2)",

            "soil_water_tension": '(hpa)'
        };
    };

    this.parameterUnitMapWithoutBracket = function () {
        return {
            "temperature": 'deg C',
            "mean_temperature": 'deg C',
            "max_temperature": 'deg C',
            "min_temperature": 'deg C',
            "max_dailytemperature": "deg C",
            "min_dailytemperature": "deg C",

            "dew_point": 'deg C',
            "min_dew_point": 'deg C',
            "max_dew_point": 'deg C',
            "mean_dew_point": 'deg C',

            "windchill": 'deg C',
            "min_windchill": 'deg C',
            "max_windchill": 'deg C',
            "mean_windchill": 'deg C',

            "heat_index": 'deg C',
            "min_heat_index": 'deg C',
            "max_heat_index": 'deg C',
            "mean_heat_index": 'deg C',

            'pressure': 'hPa',
            'mean_pressure': 'hPa',
            'min_pressure': 'hPa',
            'max_pressure': 'hPa',

            'relative_humidity': '%',
            'mean_relative_humidity': '%',
            'min_relative_humidity': '%',
            'max_relative_humidity': '%',

            'wind_direction': '',
            'mode_wind_direction': '',

            'wind_speed': 'mps',
            'mean_wind_speed': 'mps',
            'min_wind_speed': 'mps',
            'max_wind_speed': 'mps',

            'precipitation': 'mm',
            'total_precipitation': 'mm',
            'Precipitation': 'mm',

            'solar_irradiance': 'w/m2',
            'solar_radiation': 'w/m2',
            'min_solar_radiation': 'w/m2',
            'max_solar_radiation': 'w/m2',
            'mean_solar_radiation': 'w/m2',

            'photosynthetic_active_radiation': 'w/m2',
            'min_photosynthetic_active_radiation': 'w/m2',
            'max_photosynthetic_active_radiation': 'w/m2',
            'mean_photosynthetic_active_radiation': 'w/m2',

            'soil_temperature': 'deg C',
            'min_soil_temperature': 'deg C',
            'max_soil_temperature': 'deg C',
            'mean_soil_temperature': 'deg C',

            'spray_operation': '',
            'sowing_operation': '',
            'fertiliser_operation': '',
            'ref_et_hargreaves': 'mm',
            'ref_et_penman': 'mm',

            "uv_index": '',
            "min_uv_index": "",
            "max_uv_index": "",
            "mean_uv_index": "",

            "uva": 'w/m2',
            "min_uva": "w/m2",
            "max_uva": "w/m2",
            "mean_uva": "w/m2",

            "uvb": 'w/m2',
            "min_uvb": "w/m2",
            "max_uvb": "w/m2",
            "mean_uvb": "w/m2",

            "soil_water_tension": 'hpa'
        };
    };

    this.similarSummaryParamMap = function () {
        return {

            'min_temperature': ['min_temperature', 'max_temperature', 'mean_temperature'],
            'max_temperature': ['min_temperature', 'max_temperature', 'mean_temperature'],
            'mean_temperature': ['min_temperature', 'max_temperature', 'mean_temperature'],

            'min_pressure': ['min_pressure', 'max_pressure', 'mean_pressure'],
            'max_pressure': ['min_pressure', 'max_pressure', 'mean_pressure'],
            'mean_pressure': ['min_pressure', 'max_pressure', 'mean_temperature'],

            'min_relative_humidity': ['min_relative_humidity', 'max_relative_humidity', 'mean_relative_humidity'],
            'max_relative_humidity': ['min_relative_humidity', 'max_relative_humidity', 'mean_relative_humidity'],
            'mean_relative_humidity': ['min_relative_humidity', 'max_relative_humidity', 'mean_relative_humidity'],

            'min_wind_speed': ['min_wind_speed', 'max_wind_speed', 'mean_wind_speed'],
            'max_wind_speed': ['min_wind_speed', 'max_wind_speed', 'mean_wind_speed'],
            'mean_wind_speed': ['min_wind_speed', 'max_wind_speed', 'mean_wind_speed'],

            'min_solar_radiation': ['min_solar_radiation', 'max_solar_radiation', 'mean_solar_radiation'],
            'max_solar_radiation': ['min_solar_radiation', 'max_solar_radiation', 'mean_solar_radiation'],
            'mean_solar_radiation': ['min_solar_radiation', 'max_solar_radiation', 'mean_solar_radiation'],

            'min_photosynthetic_active_radiation': ['min_photosynthetic_active_radiation', 'max_photosynthetic_active_radiation', 'mean_photosynthetic_active_radiation'],
            'max_photosynthetic_active_radiation': ['min_photosynthetic_active_radiation', 'max_photosynthetic_active_radiation', 'mean_photosynthetic_active_radiation'],
            'mean_photosynthetic_active_radiation': ['min_photosynthetic_active_radiation', 'max_photosynthetic_active_radiation', 'mean_photosynthetic_active_radiation'],

            'min_dew_point': ['min_dew_point', 'max_dew_point', 'mean_dew_point'],
            'max_dew_point': ['min_dew_point', 'max_dew_point', 'mean_dew_point'],
            'mean_dew_point': ['min_dew_point', 'max_dew_point', 'mean_dew_point'],

            'min_heat_index': ['min_heat_index', 'max_heat_index', 'mean_heat_index'],
            'max_heat_index': ['min_heat_index', 'max_heat_index', 'mean_heat_index'],
            'mean_heat_index': ['min_heat_index', 'max_heat_index', 'mean_heat_index'],

            'min_windchill': ['min_windchill', 'max_windchill', 'mean_windchill'],
            'max_windchill': ['min_windchill', 'max_windchill', 'mean_windchill'],
            'mean_windchill': ['min_windchill', 'max_windchill', 'mean_windchill'],

            'min_soil_temperature': ['min_soil_temperature', 'max_soil_temperature', 'mean_soil_temperature'],
            'max_soil_temperature': ['min_soil_temperature', 'max_soil_temperature', 'mean_soil_temperature'],
            'mean_soil_temperature': ['min_soil_temperature', 'max_soil_temperature', 'mean_soil_temperature'],

            'min_uv_index': ['min_uv_index', 'max_uv_index', 'mean_uv_index'],
            'max_uv_index': ['min_uv_index', 'max_uv_index', 'mean_uv_index'],
            'mean_uv_index': ['min_uv_index', 'max_uv_index', 'mean_soil_temperature'],

            'min_uva': ['min_uva', 'max_uva', 'mean_uva'],
            'max_uva': ['min_uva', 'max_uva', 'mean_uva'],
            'mean_uva': ['min_uva', 'max_uva', 'mean_uva'],

            'min_uvb': ['min_uvb', 'max_uvb', 'mean_uvb'],
            'max_uvb': ['min_uvb', 'max_uvb', 'mean_uvb'],
            'mean_uvb': ['min_uvb', 'max_uvb', 'mean_uvb']
        };
    };

    this.getParametersNamevalueDictList = function (parametersArray) {
        var parametersNamevalueDictList = [];
        angular.forEach(parametersArray, function (param, index) {

            if (!['wind_direction', 'mode_wind_direction'].includes(param)) {
                var paramName = self.parameterUINameConvertorMap()[param];

                if (paramName) {
                    if (param === 'wind_speed' || param === 'mean_wind_speed') {
                        paramName = 'Wind';
                    }
                    parametersNamevalueDictList.push(
                        {
                            'name': paramName,
                            'value': param
                        }
                    );
                }


            }
        });
        return parametersNamevalueDictList;
    };


    // ================= CHART Helper Functions ===========================================

    this.removeElementFromArray = function (array, element, callback) {
        var index = array.indexOf(element);
        if (index >= 0) {
            array.splice(index, 1);
        }
        return array;
    };

    this.getBaseChartConfig = function () {
        return {
            exporting: {
                enabled: false
            },
            lang: {
                noData: 'Loading Data...'
            },
            chart: {
                zoomType: 'xy'
            },
            credits: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },

            navigator: {
                enabled: false,
                adaptToUpdatedData: true
            },
            title: {
                text: ''
            },
            xAxis: [],
            yAxis: [],
            tooltip: {
                shared: true
            },
            legend: {
                enabled: true,

                align: 'center',
                verticalAlign: 'top',
                floating: true,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColorSolid) || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false,
                y: -4
            },
            series: []
        };
    };

    var ChartParamProperties = {
        'precipitation': {
            'yAxis': {
                labels: {
                    formatter: function () {
                        return this.value + ' mm';
                    },
                    style: {
                        color: '#4572A7'
                    }
                },
                title: {
                    text: 'Rainfall',
                    style: {
                        color: '#4572A7'
                    }
                },
                opposite: false

            },
            'series': {
                name: 'Rainfall',
                color: '#4572A7',
                type: 'column',
                yAxis: 0,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mm',
                    valueDecimals: 2
                }

            }
        },
        'total_precipitation': {
            'yAxis': {
                labels: {
                    formatter: function () {
                        return this.value + ' mm';
                    },
                    style: {
                        color: '#4572A7'
                    }
                },
                title: {
                    text: 'Total Rainfall',
                    style: {
                        color: '#4572A7'
                    }
                },
                opposite: false

            },
            'series': {
                name: 'Total Rainfall',
                color: '#4572A7',
                type: 'column',
                yAxis: 0,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mm',
                    valueDecimals: 2
                }

            }
        },
        'precipitation_3h': {
            'yAxis': {
                labels: {
                    formatter: function () {
                        return this.value + ' mm';
                    },
                    style: {
                        color: '#4572A7'
                    }
                },
                title: {
                    text: 'Rainfall',
                    style: {
                        color: '#4572A7'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Rainfall',
                color: '#4572A7',
                type: 'column',
                yAxis: 0,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mm',
                    valueDecimals: 2
                }

            }
        },
        'precipitation_6h': {
            'yAxis': {
                labels: {
                    formatter: function () {
                        return this.value + ' mm';
                    },
                    style: {
                        color: '#4572A7'
                    }
                },
                title: {
                    text: 'Rainfall',
                    style: {
                        color: '#4572A7'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Rainfall',
                color: '#4572A7',
                type: 'column',
                yAxis: 0,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mm',
                    valueDecimals: 2
                }

            }
        },

        'ref_et_hargreaves': {
            'yAxis': {// Primary yAxis

                title: {
                    text: 'ETo Hargreaves',
                    style: {
                        color: '#1CCC2C ',
                        fontWeight: 'bold'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' mm';
                    },
                    style: {
                        color: '#1CCC2C'
                    }
                },
                min: 0 //for keeping min y axis label 0

            }
            ,
            'series': {
                name: 'ETo Hargreaves',
                color: '#1CCC2C',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mm',
                    valueDecimals: 2
                }
            }
        },
        'ref_et_penman': {
            'yAxis': {

                labels: {
                    formatter: function () {
                        return this.value + 'mm';
                    },
                    style: {
                        color: '#1413CC'
                    }
                },
                min: 0, //for keeping min y axis label 0
                title: {
                    text: 'ETo Penman-Monteith',
                    style: {
                        color: '#1413CC'
                    }
                },
                opposite: true
            },
            'series': {
                name: 'ETo Penman-Monteith',
                color: '#1413CC',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mm',
                    valueDecimals: 2
                }
            }
        },


        'wind_speed': {
            'yAxis': {// Primary yAxis

                labels: {
                    formatter: function () {
                        return this.value + ' mps';
                    },
                    style: {
                        color: '#25A9CC'
                    }
                },
                min: 0, //for keeping min y axis label 0
                title: {
                    text: 'Wind',
                    style: {
                        color: '#25A9CC',
                        fontWeight: 'bold'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Wind',
                color: '#25A9CC',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mps',
                    valueDecimals: 2
                }
            }
        },

        'mean_wind_speed': {
            'yAxis': {// Primary yAxis
                labels: {
                    formatter: function () {
                        return this.value + ' mps';
                    },
                    style: {
                        color: '#25A9CC'
                    }
                },
                min: 0, //for keeping min y axis label 0
                title: {
                    text: 'Avg Wind Speed',
                    style: {
                        color: '#25A9CC',
                        fontWeight: 'bold'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg Wind Speed',
                color: '#25A9CC',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mps',
                    valueDecimals: 2
                }
            }
        },
        'min_wind_speed': {
            'yAxis': {// Primary yAxis
                labels: {
                    formatter: function () {
                        return this.value + ' mps';
                    },
                    style: {
                        color: '#5c8a8a'
                    }
                },
                min: 0, //for keeping min y axis label 0
                title: {
                    text: 'Min Wind Speed',
                    style: {
                        color: '#5c8a8a',
                        fontWeight: 'bold'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Min Wind Speed',
                color: '#5c8a8a',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mps',
                    valueDecimals: 2
                }
            }
        },
        'max_wind_speed': {
            'yAxis': {// Primary yAxis
                labels: {
                    formatter: function () {
                        return this.value + ' mps';
                    },
                    style: {
                        color: '#2d862d'
                    }
                },
                min: 0, //for keeping min y axis label 0
                title: {
                    text: 'Max Wind Speed',
                    style: {
                        color: '#2d862d',
                        fontWeight: 'bold'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max Wind Speed',
                color: '#2d862d',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' mps',
                    valueDecimals: 2
                }
            }
        },

        'relative_humidity': {
            'yAxis': {

                title: {
                    text: 'Relative Humidity',
                    style: {
                        color: '#CC834A'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' %';
                    },
                    style: {
                        color: '#CC834A'
                    }
                },
                max: 100,
                opposite: true
            },

            'series': {
                name: 'Relative Humidity',
                color: '#CC834A',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' %',
                    valueDecimals: 2
                }
            }
        },

        'mean_relative_humidity': {
            'yAxis': {

                title: {
                    text: 'Avg Relative Humidity',
                    style: {
                        color: '#CC834A'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' %';
                    },
                    style: {
                        color: '#CC834A'
                    }
                },
                max: 100,
                opposite: true
            },

            'series': {
                name: 'Avg Relative Humidity',
                color: '#CC834A',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' %',
                    valueDecimals: 2
                }
            }
        },
        'min_relative_humidity': {
            'yAxis': {

                title: {
                    text: 'Min Relative Humidity',
                    style: {
                        color: '#4d4d00'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' %';
                    },
                    style: {
                        color: '#4d4d00'
                    }
                },
                max: 100,
                opposite: true
            },

            'series': {
                name: 'Min Relative Humidity',
                color: '#4d4d00',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' %',
                    valueDecimals: 2
                }
            }
        },
        'max_relative_humidity': {
            'yAxis': {

                title: {
                    text: 'Max Relative Humidity',
                    style: {
                        color: '#336699'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' %';
                    },
                    style: {
                        color: '#336699'
                    }
                },
                max: 100,
                opposite: true
            },

            'series': {
                name: 'Max Relative Humidity',
                color: '#336699',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' %',
                    valueDecimals: 2
                }
            }
        },

        'pressure': {
            'yAxis': {

                title: {
                    text: 'Pressure',
                    style: {
                        color: '#0092fe'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' hPa';
                    },
                    style: {
                        color: '#0092fe'
                    }
                },
                opposite: true
            },

            'series': {
                name: 'Pressure',
                color: '#0092fe',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' hPa',
                    valueDecimals: 2
                }
            }
        },
        'min_pressure': {
            'yAxis': {

                title: {
                    text: 'Min Pressure',
                    style: {
                        color: '#996633'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' hPa';
                    },
                    style: {
                        color: '#996633'
                    }
                },
                opposite: true
            },

            'series': {
                name: 'Min Pressure',
                color: '#996633',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' hPa',
                    valueDecimals: 2
                }
            }
        },
        'max_pressure': {
            'yAxis': {

                title: {
                    text: 'Max Pressure',
                    style: {
                        color: '#666633'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' hPa';
                    },
                    style: {
                        color: '#666633'
                    }
                },
                opposite: true
            },

            'series': {
                name: 'Max Pressure',
                color: '#666633',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' hPa',
                    valueDecimals: 2
                }
            }
        },
        'mean_pressure': {
            'yAxis': {

                title: {
                    text: 'Avg Pressure',
                    style: {
                        color: '#0092fe'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' hPa';
                    },
                    style: {
                        color: '#0092fe'
                    }
                },
                opposite: true
            },

            'series': {
                name: 'Avg Pressure',
                color: '#0092fe',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' hPa',
                    valueDecimals: 2
                }
            }
        },
        'soil_water_tension': {
            'yAxis': {

                title: {
                    text: 'Soil Water tension',
                    style: {
                        color: '#7D840F'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + ' hPa';
                    },
                    style: {
                        color: '#7D840F'
                    }
                },
                opposite: true
            },

            'series': {
                name: 'Soil Water Tension',
                color: '#7D840F',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' hPa',
                    valueDecimals: 2
                }
            }
        },

        'temperature': {
            'yAxis': {

                title: {
                    text: 'Temperature',
                    style: {
                        color: '#FF4000'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#FF4000'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Temperature',
                type: 'spline',
                color: '#FF4000',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },

        'mean_temperature': {
            'yAxis': {

                title: {
                    text: 'Avg Temperature',
                    style: {
                        color: '#FF4000'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#FF4000'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg Temperature',
                type: 'spline',
                color: '#FF4000',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },

        'windchill': {
            'yAxis': {

                title: {
                    text: 'Wind Chill',
                    style: {
                        color: '#698fcf'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#698fcf'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Wind Chill',
                type: 'spline',
                color: '#698fcf',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'min_windchill': {
            'yAxis': {

                title: {
                    text: 'Min Wind Chill',
                    style: {
                        color: '#660033'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#660033'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Min Wind Chill',
                type: 'spline',
                color: '#660033',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'mean_windchill': {
            'yAxis': {

                title: {
                    text: 'Avg Wind Chill',
                    style: {
                        color: '#698fcf'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#698fcf'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg Wind Chill',
                type: 'spline',
                color: '#698fcf',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'max_windchill': {
            'yAxis': {

                title: {
                    text: 'Max Wind Chill',
                    style: {
                        color: '#666633'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#666633'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max Wind Chill',
                type: 'spline',
                color: '#666633',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'heat_index': {
            'yAxis': {

                title: {
                    text: 'Heat Index',
                    style: {
                        color: '#ff6800'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#ff6800'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Heat Index',
                type: 'spline',
                color: '#ff6800',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'mean_heat_index': {
            'yAxis': {

                title: {
                    text: 'Avg Heat Index',
                    style: {
                        color: '#ff6800'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#ff6800'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg Heat Index',
                type: 'spline',
                color: '#ff6800',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'min_heat_index': {
            'yAxis': {

                title: {
                    text: 'Min Heat Index',
                    style: {
                        color: '#336600'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#336600'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Min Heat Index',
                type: 'spline',
                color: '#336600',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'max_heat_index': {
            'yAxis': {

                title: {
                    text: 'Max Heat Index',
                    style: {
                        color: '#6b6b47'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#6b6b47'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max Heat Index',
                type: 'spline',
                color: '#6b6b47',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },

        'soil_temperature': {
            'yAxis': {

                title: {
                    text: 'Soil Temperature',
                    style: {
                        color: '#EEA30C'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#EEA30C'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Soil Temperature',
                type: 'spline',
                color: '#EEA30C',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'mean_soil_temperature': {
            'yAxis': {

                title: {
                    text: 'Avg Soil Temperature',
                    style: {
                        color: '#EEA30C'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#EEA30C'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg Soil Temperature',
                type: 'spline',
                color: '#EEA30C',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'min_soil_temperature': {
            'yAxis': {

                title: {
                    text: 'Min Soil Temperature',
                    style: {
                        color: '#666633'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#666633'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Min Soil Temperature',
                type: 'spline',
                color: '#666633',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'max_soil_temperature': {
            'yAxis': {

                title: {
                    text: 'Max Soil Temperature',
                    style: {
                        color: '#006666'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#006666'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max Soil Temperature',
                type: 'spline',
                color: '#006666',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },

        'photosynthetic_active_radiation': {
            'yAxis': {

                title: {
                    text: 'PAR',
                    style: {
                        color: '#EE730C'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#EE730C'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'PAR',
                type: 'spline',
                color: '#EE730C',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'max_photosynthetic_active_radiation': {
            'yAxis': {

                title: {
                    text: 'Max PAR',
                    style: {
                        color: '#660033'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#660033'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max PAR',
                type: 'spline',
                color: '#660033',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'min_photosynthetic_active_radiation': {
            'yAxis': {

                title: {
                    text: 'Min PAR',
                    style: {
                        color: '#336600'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#336600'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Min PAR',
                type: 'spline',
                color: '#336600',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'mean_photosynthetic_active_radiation': {
            'yAxis': {

                title: {
                    text: 'Avg PAR',
                    style: {
                        color: '#EE730C'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#EE730C'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg PAR',
                type: 'spline',
                color: '#EE730C',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },

        'solar_irradiance': {
            'yAxis': {

                title: {
                    text: 'Solar Radiation',
                    style: {
                        color: '#FFBD00'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#FFBD00'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Solar Radiation',
                type: 'spline',
                color: '#FFBD00',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'solar_radiation': {
            'yAxis': {

                title: {
                    text: 'Solar Radiation',
                    style: {
                        color: '#FFBD00'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#FFBD00'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Solar Radiation',
                type: 'spline',
                color: '#FFBD00',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'mean_solar_radiation': {
            'yAxis': {

                title: {
                    text: 'Avg Solar Radiation',
                    style: {
                        color: '#FFBD00'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#FFBD00'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg Solar Radiation',
                type: 'spline',
                color: '#FFBD00',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'min_solar_radiation': {
            'yAxis': {

                title: {
                    text: 'Min Solar Radiation',
                    style: {
                        color: '#0a734f'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#0a734f'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Min Solar Radiation',
                type: 'spline',
                color: '#0a734f',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'max_solar_radiation': {
            'yAxis': {

                title: {
                    text: 'Max Solar Radiation',
                    style: {
                        color: '#333300'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#333300'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max Solar Radiation',
                type: 'spline',
                color: '#333300',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'uva': {
            'yAxis': {

                title: {
                    text: 'UVA',
                    style: {
                        color: '#621964'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#621964'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'UVA',
                type: 'spline',
                color: '#621964',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },

        'uvb': {
            'yAxis': {

                title: {
                    text: 'UVB',
                    style: {
                        color: '#F1B8F3'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#F1B8F3'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'UVB',
                type: 'spline',
                color: '#F1B8F3',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'SOLAR_RADIATION': {
            'yAxis': {

                title: {
                    text: 'Solar Radiation',
                    style: {
                        color: '#FFBD00'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '(w/m2)';
                    },
                    style: {
                        color: '#FFBD00'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Solar Radiation',
                type: 'spline',
                color: '#FFBD00',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' (w/m2)',
                    valueDecimals: 2
                }
            }
        },
        'SOIL_MOISTURE': {
            'yAxis': {

                title: {
                    text: 'Soil Moisture',
                    style: {
                        color: '#0EC6C6'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '%';
                    },
                    style: {
                        color: '#0EC6C6'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Soil Moisture',
                type: 'spline',
                color: '#0EC6C6',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' %',
                    valueDecimals: 2
                }
            }
        },

        'dew_point': {
            'yAxis': {

                title: {
                    text: 'Dew Point',
                    style: {
                        color: '#89A54E'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#89A54E'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Dew Point',
                type: 'spline',
                color: '#89A54E',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'min_dew_point': {
            'yAxis': {

                title: {
                    text: 'Min Dew Point',
                    style: {
                        color: '#996633'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#996633'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Min Dew Point',
                type: 'spline',
                color: '#996633',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'max_dew_point': {
            'yAxis': {

                title: {
                    text: 'Max Dew Point',
                    style: {
                        color: '#666633'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#666633'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max Dew Point',
                type: 'spline',
                color: '#666633',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'mean_dew_point': {
            'yAxis': {

                title: {
                    text: 'Avg Dew Point',
                    style: {
                        color: '#89A54E'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#89A54E'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg Dew Point',
                type: 'spline',
                color: '#89A54E',
                yAxis: 1,
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: ' °C',
                    valueDecimals: 2
                }
            }
        },
        'max_temperature': {
            'yAxis': {
                title: {
                    text: 'Max Temperature',
                    style: {
                        color: '#16A085'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#5c8a8a'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max Temperature',
                color: '#16A085',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '°C'
                }
            }
        },
        'max_dailytemperature': {
            'yAxis': {
                title: {
                    text: 'Max Temperature',
                    style: {
                        color: '#16A085'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#16A085'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max Temperature',
                color: '#16A085',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '°C'
                }
            }
        },
        'min_temperature': {
            'yAxis': {
                title: {
                    text: 'Min Temperature',
                    style: {
                        color: '#3399ff'
                    }


                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#3399ff'
                    }

                }

            },
            'series': {
                name: 'Min Temperature',
                color: '#3399ff',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '°C'
                }
            }
        },
        'min_dailytemperature': {
            'yAxis': {
                title: {
                    text: 'Min Temperature'

                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },

                }

            },
            'series': {
                name: 'Min Temperature',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '°C'
                }
            }
        },
        'avg_temperature': {
            'yAxis': {
                title: {
                    text: 'Avg Temperature',
                    style: {
                        color: '#E67E22'
                    }
                },
                labels: {
                    formatter: function () {
                        return this.value + '°C';
                    },
                    style: {
                        color: '#E67E22'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg Temperature',
                color: '#E67E22',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '°C'
                }
            }
        },
        'sunshine': {
            'yAxis': {
                title: {
                    text: 'Sunshine (Hr)',
                    style: {
                        color: '#DAA520',
                    }
                },
                labels: {
                    format: '{value}',
                    style: {
                        color: '#DAA520',
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Sunshine',
                color: '#DAA520',
                type: 'column',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: 'Hrs'
                }
            }


        },
        'airfrost': {
            'yAxis': {
                title: {
                    text: 'Air Frost (days)',
                    style: {
                        color: '#A9A9A9',
                    }
                },
                labels: {
                    format: '{value}',
                    style: {
                        color: '#A9A9A9',
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Air Frost',
                color: '#A9A9A9',
                type: 'spline',
                data: [],
                marker: {
                    enabled: false
                },
                connectNulls: true,
                tooltip: {
                    valueSuffix: 'Days'
                }
            }


        },
        'uv_index': {
            'yAxis': {

                title: {
                    text: 'UV Index',
                    style: {
                        color: '#A535A9'
                    }
                },
                labels: {
                    style: {
                        color: '#A535A9'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'UV Index',
                type: 'spline',
                color: '#A535A9',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '',
                    valueDecimals: 2
                }
            }
        },

        'mean_uv_index': {
            'yAxis': {

                title: {
                    text: 'Avg UV Index',
                    style: {
                        color: '#A535A9'
                    }
                },
                labels: {
                    style: {
                        color: '#A535A9'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Avg UV Index',
                type: 'spline',
                color: '#A535A9',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '',
                    valueDecimals: 2
                }
            }
        },
        'max_uv_index': {
            'yAxis': {

                title: {
                    text: 'Max UV Index',
                    style: {
                        color: '#A535A9'
                    }
                },
                labels: {
                    style: {
                        color: '#A535A9'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Max UV Index',
                type: 'spline',
                color: '#A535A9',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '',
                    valueDecimals: 2
                }
            }
        },
        'min_uv_index': {
            'yAxis': {

                title: {
                    text: 'Min UV Index',
                    style: {
                        color: '#A535A9'
                    }
                },
                labels: {
                    style: {
                        color: '#A535A9'
                    }
                },
                opposite: true

            },
            'series': {
                name: 'Min UV Index',
                type: 'spline',
                color: '#A535A9',
                data: [],
                marker: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '',
                    valueDecimals: 2
                }
            }
        }
    };

    this.getChartConfig = function ($scope, chartParams, xAxisType) {
        $scope.chartConfiguration.xAxis.push(xAxisType);
        for (var i = 0; i < chartParams.length; i++) {


            if (['wind_direction', 'mode_wind_direction'].includes(chartParams[i])) {

            } else {
                var paramXAxis = ChartParamProperties[chartParams[i]].series;
                paramXAxis['yAxis'] = i;
                $scope.chartConfiguration.yAxis.push(ChartParamProperties[chartParams[i]].yAxis);
                $scope.chartConfiguration.series.push(ChartParamProperties[chartParams[i]].series);
            }
        }
    };

    this.pushDataInChart = function ($scope, data, chart_parameters, extraChartSettings) {

        if (data) {

            var paramValuesListDict = {};
            for (var i = 0; i < chart_parameters.length; i++) {
                paramValuesListDict[chart_parameters[i]] = [];
            }
            angular.forEach(data, function (paramValueDict, xCoordinate) {

                if (extraChartSettings.conversionRequired) {
                    xCoordinate = extraChartSettings.conversionMethod(xCoordinate);
                }
                for (var j = 0; j < chart_parameters.length; j++) {
                    var paramValues = paramValuesListDict[chart_parameters[j]];

                    var paramXYCoordinates;

                    if (extraChartSettings.xyValuePush) {
                        if (chart_parameters[j] == 'wind_speed') {
                            paramXYCoordinates = {
                                x: xCoordinate,
                                y: paramValueDict[chart_parameters[j]],
                                marker: {symbol: 'url(' + self.windDirectionImageMap()[paramValueDict['wind_direction']] + ')'}
                            };
                        }

                        else if (chart_parameters[j] == 'mean_wind_speed') {
                            paramXYCoordinates = {
                                x: xCoordinate,
                                y: paramValueDict[chart_parameters[j]],
                                marker: {symbol: 'url(' + self.windDirectionImageMap()[paramValueDict['mode_wind_direction']] + ')'}
                            };
                        }

                        else {
                            paramXYCoordinates = {
                                x: xCoordinate,
                                y: paramValueDict[chart_parameters[j]]
                            };
                        }
                    }
                    else {
                        paramXYCoordinates = {
                            name: xCoordinate,
                            y: paramValueDict[chart_parameters[j]],
                            drilldown: xCoordinate
                        };
                    }
                    paramValues.push(paramXYCoordinates);
                }
            });
            for (var k = 0; k < chart_parameters.length; k++) {
                $scope.chartConfiguration.series[k].data = paramValuesListDict[chart_parameters[k]];
            }


        }
        else {
            $scope.chartConfiguration.lang.noData = 'Data is currently not available';
            $scope.chartConfiguration = false;
        }


    };

    this.pushListDataInChart = function ($scope, data, chartParameters, extraChartSettings) {

        if (data) {

            var paramValuesListDict = {};

            angular.forEach(chartParameters, function (chartParam, index) {
                    paramValuesListDict[chartParam] = [];
                }
            );

            angular.forEach(data, function (paramValueDict, index) {
                    var xCoordinate = paramValueDict['timestamp'];
                    if (extraChartSettings.conversionRequired) {
                        xCoordinate = extraChartSettings.conversionMethod(xCoordinate);
                    }
                    angular.forEach(chartParameters, function (chartParam, index) {
                            var paramValues = paramValuesListDict[chartParam];
                            var paramXYCoordinates;
                            if (extraChartSettings.xyValuePush) {

                                if (chartParam == 'wind_speed' && extraChartSettings.includeWindDirection == true) {
                                    paramXYCoordinates = {
                                        x: xCoordinate,
                                        y: paramValueDict[chartParam],
                                        marker: {symbol: 'url(' + self.windDirectionChartImageMap()[paramValueDict['wind_direction']] + ')'}
                                    };
                                }

                                else if (chartParam == 'mean_wind_speed' && extraChartSettings.includeWindDirection == true) {
                                    paramXYCoordinates = {
                                        x: xCoordinate,
                                        y: paramValueDict[chartParam],
                                        marker: {symbol: 'url(' + self.windDirectionChartImageMap()[paramValueDict['mode_wind_direction']] + ')'}
                                    };
                                }
                                else {
                                    var yCoordinate = paramValueDict[chartParam];
                                    if (yCoordinate === undefined) {
                                        yCoordinate = null;
                                    }

                                    paramXYCoordinates = {
                                        x: xCoordinate,
                                        y: yCoordinate
                                    };
                                }
                            }
                            else {
                                paramXYCoordinates = {

                                    name: xCoordinate,
                                    y: paramValueDict[chartParam],
                                    drilldown: xCoordinate
                                };
                            }
                            paramValues.push(paramXYCoordinates);
                        }
                    );
                }
            );
            angular.forEach(chartParameters, function (chartParam, index) {
                    var similarSummaryParamArrayList = self.similarSummaryParamMap()[chartParam];
                    if (similarSummaryParamArrayList) {
                        var allDataPoints = [];
                        angular.forEach(similarSummaryParamArrayList, function (similarParam, index) {
                                var data = paramValuesListDict[similarParam];
                                angular.forEach(data, function (dataPoint, index) {
                                        if (dataPoint.y !== undefined || dataPoint.y !== null) {
                                            allDataPoints.push(dataPoint.y);
                                        }

                                    }
                                );
                            }
                        );
                        // Setting min max for y axis
                        if (allDataPoints) {
                            $scope.chartConfiguration.yAxis[index].min = Math.min.apply(null, allDataPoints);
                            $scope.chartConfiguration.yAxis[index].max = Math.max.apply(null, allDataPoints);
                        }
                    }
                    $scope.chartConfiguration.series[index].data = paramValuesListDict[chartParam];
                }
            );
        }
        else {
            $scope.chartConfiguration.lang.noData = 'Data is currently not available';
            $scope.chartConfiguration = false;
        }

    };

    this.new_high_chart_data = function (filters, key, chart_data, chart_metadata) {
        var data = self.sortSeriesData(chart_data)[0].data;
        return {

            chart: {
                events: {
                    load: function () {

                        var centerX = 100,
                            centerY = 150,
                            path = [],
                            angle,
                            radius,
                            badgeColor = Highcharts.Color(Highcharts.getOptions().colors[0]).brighten(-0.2).get(),
                            spike,
                            seriesDataImage,
                            renderer;

                        if (this.chartWidth < 530) {
                            return;
                        }

                        // Draw the spiked disc
                        for (angle = Math.PI / 4; angle < 2 * Math.PI; angle += Math.PI / 2) {
                            radius = 80;
                            path.push(
                                'L',
                                centerX + radius * Math.cos(angle),
                                centerY + radius * Math.sin(angle)
                            );
                            spike = !spike;
                        }
                        path[0] = 'M';
                        path.push('z');
                        this.renderer.path(path)
                            .attr({
                                // fill: '#EFFFFF',
                                zIndex: 6
                            })
                            .add();

                        // Employee image overlay
                        seriesDataImage = this.renderer.path(path)
                            .attr({
                                zIndex: 7,
                                opacity: 0,
                                stroke: badgeColor,
                                'stroke-width': 1
                            })
                            .add();

                        // Prepare mouseover
                        renderer = this.renderer;
                        if (renderer.defs) { // is SVG
                            $.each(data, function () {
                                var point = this,
                                    pattern;
                                if (point.image) {
                                    pattern = renderer.createElement('pattern').attr({
                                        id: 'pattern-' + point.image,
                                        patternUnits: 'userSpaceOnUse',
                                        width: 400,
                                        height: 400
                                    }).add(renderer.defs);
                                    renderer.image(
                                        // 'https://www.highcharts.com/images/employees2014/' + point.image + '.jpg',
                                        point.image,
                                        centerX - 80,
                                        centerY - 80,
                                        160,
                                        213
                                    ).add(pattern);

                                    Highcharts.addEvent(point, 'mouseOver', function () {
                                        seriesDataImage
                                            .attr({
                                                fill: 'url(#pattern-' + point.image + ')'
                                            })
                                            .animate({opacity: 1}, {duration: 500});
                                    });
                                    Highcharts.addEvent(point, 'mouseOut', function () {
                                        seriesDataImage.animate({opacity: 0}, {duration: 500});
                                    });
                                }
                            });
                        }
                    }
                }
            },

            xAxis: {
                type: 'datetime',
                minTickInterval: 1 * 24 * 36e5,
                labels: {
                    align: 'center'
                }
            },

            title: {
                text: 'Growth Chart with Images'
            },

            tooltip: {
                style: {
                    width: '250px'
                }
            },

            yAxis: [{
                labels: {
                    enabled: false
                },
                title: {
                    text: ''
                },
                gridLineColor: 'rgba(0, 0, 0, 0.07)'
            }, {
                allowDecimals: true,
                // max: 36,
                labels: {
                    style: {
                        color: Highcharts.getOptions().colors[2]
                    }
                },
                title: {
                    text: '',
                    style: {
                        color: Highcharts.getOptions().colors[2]
                    }
                },
                opposite: true,
                gridLineWidth: 0
            }],

            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 2
                    },
                    fillOpacity: 0.5
                },
                flags: {
                    tooltip: {
                        xDateFormat: '%B %e, %Y'
                    }
                }
            },
            series: self.sortSeriesData(chart_data)
        };
    };

    this.sortSeriesData = function (chart_data) {
        chart_data.data.splice(1);
        for (var i = 0; i < 1; i++) {
            chart_data.data[i].data = chart_data.data[i].data.sort(self.sortByTimeStamp);
        }
        return chart_data.data;
    };

    this.sortByTimeStamp = function (a, b) {
        if (a[0] < b[0]) return -1;
        if (a[0] > b[0]) return 1;
        return 0;
    };


    // ================== DATE TIME RELATED =======================================

    this.getDbDate = function(readable_date) {
        return moment(readable_date).format("YYYY-MM-DD");
    };

    this.getDbDateDailyFormatDay = function(moment_object) {
        return moment_object.format("YYYY-MM-DD");
    };

    this.getUserSettingsDateFormat = function($rootScope, moment_object) {
        return moment_object.format($rootScope.userSettingDateFormatMoment);
    };


    this.getDbDateHourlyFormatHour = function (moment_object) {
        return moment_object.format("YYYY-MM-DDTHH:MM:00");
    };


    this.getDbDateHourlyFormatCurrentHourUTC = function () {
        return moment.utc().format("YYYY-MM-DDTHH:MM:00");
    };


    this.subtractDays = function (moment_object, no_of_days) {
        return moment_object.subtract(no_of_days, "days");
    };

    this.subtractHours = function (moment_object, no_of_hours) {
        return moment_object.subtract(no_of_hours, "hours");
    };

    this.addDays = function (moment_object, no_of_days) {
        return moment_object.add(no_of_days, "days");
    };


});

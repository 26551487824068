export default angular.module("KhFormElements", ["formly"])
    .run(["formlyConfig", "formlyValidationMessages",
        function (formlyConfig, formlyValidationMessages) {
            formlyConfig.setType({
                name: 'ckeditor',
                template: '<md-input-container>' +
                '<p>{{options.templateOptions.label}}</p>' +
                '<textarea ng-model="model[options.key]" aria-label="data" data-ck-editor></textarea>' +
                '</md-input-container>'
            });
            formlyConfig.setType({
                name: 'password',
                overwriteOk: true,
                template: '<md-input-container><label>{{options.templateOptions.label}}</label><input' +
                ' ng-model="model[options.key]"' +
                ' type="password"></md-input-container>'
            });

            formlyConfig.setType({
                name: 'timepicker',
                overwriteOk: true,
                templateUrl: '/static/assets/khapps2/base/khformelements/partials/kh_timepicker.html'
            });
            formlyConfig.setType({
                name: 'datetimepicker',
                overwriteOk: true,
                templateUrl: '/static/assets/khapps2/base/khformelements/partials/kh_datetimepicker.html'
            });
            formlyConfig.setType({
                name: 'searchable_select',
                extends: 'select',
                templateUrl: '/static/assets/khapps2/base/khformelements/partials/kh_searchable_select.html'
            });
            formlyConfig.setType({
                name: 'file_upload',
                templateUrl: '/static/assets/khapps2/base/khformelements/partials/kh_file_upload.html'
            });
             formlyConfig.setWrapper({
                name: 'panel',
                types: ['nested'],
                templateUrl: '/static/assets/khapps2/base/khformelements/panel.html'
            });

            formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';
            formlyValidationMessages.addStringMessage('required', 'This field is required');

        }])
	.directive('ckEditor', function () {
		return {
			require: '?ngModel',
			link: function (scope, elm, attr, ngModel) {
				var ck = CKEDITOR.replace(elm[0]);
				if (!ngModel) return;
				ck.on('instanceReady', function () {
					ck.setData(ngModel.$viewValue);
				});
				function updateModel() {
					scope.$apply(function () {
						ngModel.$setViewValue(ck.getData());
					});
				}

				ck.on('change', updateModel);
				ck.on('key', updateModel);
				ck.on('dataReady', updateModel);

				ngModel.$render = function (value) {
					ck.setData(ngModel.$viewValue);
				};
			}
		};
	});
angular.module('KhBase').config(function ($mdThemingProvider, $translateProvider, $mdDateLocaleProvider, $mdAriaProvider, $sceDelegateProvider, $httpProvider) {

        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            //_put_whitelist_url_here_for_angular_app
            // do not remove above comment, it's been use to put the whitelist url on the go
            'https://*.kisanhub.com/**',
            'https://*.niabnetwork.com/**',
            'https://*.kisanhub.in/**',
            'http://*.dj.com/**'
        ]);

        $mdThemingProvider.theme('default')
            .primaryPalette('green')
            .accentPalette('blue');
        $mdThemingProvider.theme('dark-grey')
            .backgroundPalette('blue-grey', {
                'default': '400'
            }).dark();
        //enable it for production.
        $mdAriaProvider.disableWarnings();

        $translateProvider.translations('en', {
            edit: 'Edit',
            clear: 'Clear',
            add: 'Add',
            cancel: 'Cancel',
            update: 'Update',
            apply: 'Apply',
            delete: 'Delete',
            view: 'View',
            pdf: 'PDF',
            actions: 'Actions',
            read_more: 'Read more',
            more: 'More',
            submit: 'Submit',
            notes: 'Notes',
            yes: 'Yes',
            no: 'No',
            uuid: 'Identifier',
            carousel_view: 'Card view',
            chart_view: 'Chart view',
            table_view: 'Table view',
            not_available: 'N/A',
            default_error_message: 'Internal server error; please contact our support team.',
            error_message_400: 'Please check your submitted data.',
            error_message_401: 'You are not authorised to perform this action.',
            error_message_403: 'You do not have permission to perform this action.',
            error_message_404: 'The page you requested cannot be found.',
            no_data_for_selected_filters: 'No data for selected filters.',
            created_successfully: 'Saved successfully.',
            updated_successfully: 'Updated successfully.',
            deleted_successfully: 'Deleted successfully.',
            export: 'Export',
            no_records_found: 'No records found.',
            please_choose_a_growing_season: 'Please choose a growing season.',
            export_table: 'Export Table',
            duplicate: 'Duplicate',
            deselect_all: 'Deselect all',
            ok: 'Ok',
            description: 'Description',
            map: {
                slug: "Unique ID",
                field: 'Field',
                edit_soil_sample: 'Edit soil sample',
                sample_details: 'Sample details',
                name: 'Name',
                field_name: 'Field name',
                date: 'Date',
                depth: 'Depth',
                test_results: 'Test results',
                result: 'Result',
                lab: 'Lab',
                farm: 'Farm',
                edit_field_plot_crop: 'Edit field plot crop',
                share: 'Share',
                subscribe_to_satellite_services: 'Subscribe to satellite services',
                notification: 'Notification',
                crop_details: 'Crop details',
                crop: 'Crop',
                variety: 'Variety',
                expected_sowing_date: 'Expected sowing date',
                soil_type: 'Soil type',
                cropped_area_ha: 'Cropped area (ha)',
                drawn_area_ha: 'Drawn area (ha)',
                operations: 'Operations',
                diary: 'Diary',
                layers_and_legend: 'Layers and Legend',
                information_guide: 'Information guide on layers, legend and use of mapping',
                field_information: 'Field Information',
                edit_field: 'Edit field',
                show_headland: 'Show headland',
                show_tramline: 'Show tramline',
                field_details: 'Field details',
                workable_area_ha: 'Workable area (ha)',
                field_area_ha: 'Field area (ha)',
                lat_long: 'Lat/long',
                field_group: 'Field group',
                edit_farm: 'Edit farm',
                location: 'Location',
                farm_details: 'Farm details',
                time: 'Time',
                day: 'Day'
            }
        });
        $translateProvider.useSanitizeValueStrategy('sanitize');
        $translateProvider.preferredLanguage('en');
        $mdDateLocaleProvider.formatDate = function (date) {
            return date ? moment(date).format('DD/MM/YYYY') : '';
        };
        $mdDateLocaleProvider.parseDate = function (dateString) {
            var m = moment(dateString, 'DD/MM/YYYY', true);
            return m.isValid() ? m.toDate() : new Date(NaN);
        };
        $httpProvider.interceptors.push( () => {
            return {
                request: (config) => {
                    config.headers['Kisanhub-Client'] = 'ashes';
                    return config;
                }
            }
        })
    });

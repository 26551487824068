angular.module("khUtils")
/**
 * Weather data service for farm/field/plot
 */
    .service('WeatherService', function ($resource, FarmFiltersService, DateUtilsService) {

        this.getWeatherData = function (weatherFormat, slugType, queryParamDict) {
            /**
             * Returns promise
             * possible weatherFormat: forecast,blended,blended-instantaneous,blended-duration-summaries
             * slugType: {'slug': 'hvhvdhdvhdv', 'type': 'farm'} here type can be farm/field/plot
             * queryParamDict: dictionary having extra arg needs to be sent to kisanmet
             */
            return $resource('/api/weather/:weatherFormat/:type/:slug', Object.assign({'weatherFormat': weatherFormat}, slugType)).get(queryParamDict).$promise;
        };

        this.getSlugType = function () {
            var slug, type;
            if (FarmFiltersService.filter.farm_slug && FarmFiltersService.filter.farm_slug !== "All Farms") {
                slug = FarmFiltersService.filter.farm_slug;
                type = 'farm';
            }
            if (FarmFiltersService.filter.field_slug && FarmFiltersService.filter.field_slug !== "All Fields") {
                slug = FarmFiltersService.filter.field_slug;
                type = 'field';
            }
            return {'slug': slug, 'type': type};
        };

    });

export default angular.module("FiltersData", ["ngResource"])
    .factory("FarmNamesFactory", ["$resource", function ($resource) {
        return $resource('api/v1.0/khutils/farms/', {as_dict: 'yes'});
    }])
    .factory("FieldNamesFactory", ["$resource", function ($resource) {
        return $resource('api/v1.0/fms/farmfield/', {as_dict: 'yes'});
    }])
    .factory("PlantingSeasonListFactory", ["$resource", function ($resource) {
        return $resource('/api/v1.0/khutils/plantingseasons/');
    }])
    .factory("GrowingSeasonFarmFieldFilterFactory", ["$resource", function ($resource) {
        return $resource('/api/v1.0/khutils/growingseasonfarmfield/', {}, {

            query: {
                isArray: false,
                method: 'GET'
            },
            save: {
                params: {farm: '@farm', field: '@field', growing_season: '@growing_season'},
                method: 'POST'
            }

        });
    }]);
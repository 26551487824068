//
// FIXME this does NOT need to be run in a run() function
// It should be the controller of the main component
//

angular.module('KhBase').run(function ($rootScope, $mdSidenav, $http, $mdDialog) {
    if (window.innerWidth > 1080) {
        $rootScope.leftsidenavIsOpen = true;
    }
    $rootScope.$watch("leftsidenavIsOpen", function (newvalue) {
        $rootScope.$broadcast("leftsidenav_toggled", newvalue)
    });


    $rootScope.$on('$locationChangeStart', function (event) {
        if (angular.element(document.body).hasClass('md-dialog-is-showing')) {
            event.preventDefault();
            $mdDialog.cancel();
        }
    });

    $rootScope.buildToggler = function (componentId) {
        if (componentId == "leftsidenav") {
            $rootScope.leftsidenavIsOpen = !$rootScope.leftsidenavIsOpen;
        }
        $mdSidenav(componentId).toggle();
    };

    $rootScope.IE_11 = ie_11;
    $http.defaults.xsrfCookieName = "csrftoken";
    $http.defaults.xsrfHeaderName = "X-CSRFToken";

    $rootScope.khIcon = {
        account_remove: "account-remove",
        account_check: "account-check",
        add: "plus",
        apps: "apps",
        action_vertical: "dots-vertical",
        action: "dots-horizontal",
        alert: "alert-circle",
        assign: "account-switch",
        copy: "content-copy",
        close: "close",
        check_status: "checkbox-marked-circle",
        chart_view: "chart-areaspline",
        dialog_close: "keyboard-backspace",
        done: "check",
        export_to_file: "file-export",
        edit: "pencil",
        completed_activity: "calendar-check",
        cross_status: "close-circle",
        detail_view: "chart-pie",
        duplicate: "content-duplicate",
        download: "download",
        facebook: "facebook",
        full_extent: "earth",
        hide: "eye-off",
        info: "information-outline",
        linkedin: "linkedin",
        map: "google-maps",
        paste: "content-paste",
        pdf: "file-pdf",
        file_find: "file-find",
        planned_activity: "calendar-blank",
        notification: "bell",
        public: "lock-open",
        private: "lock",
        question_mark: "help-circle",
        remove: "delete",
        reset: "refresh",
        save: "content-save",
        show_lists: "format-list-bulleted",
        settings: "settings",
        share: "share-variant",
        table: "table",
        tag: "tag-heart",
        twitter: "twitter",
        team: "account-multiple",
        upload: "upload",
        user: "account",
        user_location: "crosshairs-gps",
        user_search: "account-search",
        view: "eye",
        newtab: "open-in-new",
        satellite_0: "satellite",
        satellite_1: "satellite-variant",
        calculator: "calculator",
        deselect_all: "select-off",
        crop_book: "book-open-page-variant",
        trial_book: "book-open-page-variant",
        store_book: "book-open-page-variant",
        lab_book: "book-open-page-variant",
        soil_sample: "flask",
        arrow_right: "arrow-right",
        search: "magnify",
        arrow_left: "arrow-left"

    };
    setTimeout(function () {
        if (!ie_11) {
            var khsearch = document.querySelectorAll(".select-filter-header-head");
            for (var i = 0; i < khsearch.length; i++) {
                khsearch[i].style.width = "100%";
            }
        }
    }, 4000);
});
angular.module('KhBase').factory("FarmFieldTramlineFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/fms/farmfieldtramline/', {}, {
        save: {
            method: 'POST'
        },
        get: {
            params: {field_tramline_slug: '@field_tramline_slug'},
            url: '/api/v1.0/fms/farmfieldtramline/:field_tramline_slug/',
            method: 'GET'
        },
        update: {
            params: {field_tramline_slug: '@field_tramline_slug'},
            url: '/api/v1.0/fms/farmfieldtramline/:field_tramline_slug/',
            method: 'PUT'
        }
    });
}]);
angular.module("KhBase").factory("FieldPlotCropListFactory", ["$resource", function ($resource) {

    return $resource('/api/v1.0/khutils/fieldplotcrops/', {}, {
        query: {
            params: {farm: '@farm', field: '@field', growing_season: '@growing_season'},
            url: '/api/v1.0/khutils/fieldplotcrops/',
            isArray: true
        }
    });
}]);

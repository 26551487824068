angular.module("KhBase")
.factory("UserSettingsFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/profiles/user-settings/', {}, {
        get: {
            method: 'GET'
        },
        update: {
            method: 'POST'
        }
    });
}]);

angular.module("khUtils").directive('khSelect', function () {
    return {
        restrict: 'E',
        transclude: true,
        require: 'ngModel',
        replace: true,
        scope: {
            'data': '=',
            'titletext': '@',
            'placeholdertext': '@',
            'initialvalue': '=',
            'onselect': '&',
            'datamodel': '=ngModel'
        },
        controller: function ($scope) {
            $scope.on_select = function () {
                $scope.onselect();
                delete $scope.searchmodel;
            };

        },
        templateUrl: '/static/assets/khapps2/base/khutils/partials/khSelect.html',
        link: function (scope, element, attrs, controllers) {
            element.find('input').on('keydown', function (ev) {
                ev.stopPropagation();
            });
	        scope.IE_11 = ie_11;
        }
    };
});
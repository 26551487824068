angular.module('khUtils').constant("UserSettingsConstants", {
    USER: {
        DEFAULT_USER_READABLE_DATE_FORMAT: "DD MMMM YYYY",
        DEFAULT_USER_READABLE_DATE_TIME_FORMAT: "",
        DEFAULT_USER_READABLE_TIME_FORMAT: "hh:mm a",
        DEFAULT_USER_FIELD: "",
        DEFAULT_USER_FARM: "",
        DEFAULT_USER_APP: ""
    }
});

angular.module('KhNavbar').component('farmFilters', {
  bindings: {
    hideFarms: '<',
    hideFields: '<',
    hideSeasons: '<'
  },
  templateUrl: '/static/assets/khapps2/base/navbar/farm-filters.component.html',
  controller: function(FarmFiltersService) {
    var self = this;

    this.service = FarmFiltersService;

    this.farmSelected = function(farm) {
      self.service.farmSelected(farm);
    };
    this.fieldSelected = function(field) {
      self.service.fieldSelected(field);
    };
  }
});

angular.module("khFileUploads").directive("khImageUploads", [function () {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: '/static/assets/khapps2/base/khutils/khFileUploads/partials/kh_fileuploads.html',
			controller: "khImageUploadsController",
			scope: {
				appTag: '@',
				dbtype: '@',
				displaytype: '@',
				maxfiles: '@',
				allowduplicates: '@',
				filetype: '@'
			}
		}
	}]);
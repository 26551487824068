import {
  downgradeComponent,
  downgradeInjectable
} from '@angular/upgrade/static';
import { UserDetailsComponent } from '../../../angular/src/app/settings/profile/user-details/user-details.component';
import { TeamSelectionService } from '../../../angular/src/app/shared/core/services';
import { TeamSelectComponent } from '../../../angular/src/app/shared/components/navbar/team-select/team-select.component';
import { GenericBannerComponent } from '../../../angular/src/app/generic-banner/generic-banner.component.ts';

export default angular.module('KhNavbar', []);

angular
  .module('KhNavbar')
  .directive(
    'userDetails',
    downgradeComponent({ component: UserDetailsComponent })
  )
  .service('TeamSelectionService', downgradeInjectable(TeamSelectionService))
  .directive(
    'appTeamSelect',
    downgradeComponent({ component: TeamSelectComponent })
  )
  .directive(
    'appGenericBanner',
    downgradeComponent({ component: GenericBannerComponent })
  );

angular.module("khUtils").factory("KhutilsFarmListFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/khutils/farms/:farm_slug/');
}]);


angular.module("khUtils").factory("KhutilsFarmListParametersFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/khutils/farms/', {}, {

        query: {
            params: {team_slug: '@team_slug', as_dict:'@as_dict'},
            isArray: true
        }


    });
}]);
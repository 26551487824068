angular.module("khUtils").directive("checkElemetsForNav", function () {
	return {
		restrict: 'A',
		link: function (scope, element, attrs, controllers) {
			scope.show_links = false;
			function checkNavFilters(ti) {
				setTimeout(function () {
					if (element[0].children[0]) {
						if (element[0].children[0].children.length > 0) {
							scope.show_links = true;
							scope.$apply();
						} else {
							scope.show_links = false;
							scope.$apply();
						}
					}
				}, ti);
			}
			checkNavFilters(4000);
			scope.$on('$routeChangeStart', function (next, current) {
				checkNavFilters(1000);
			});

		}
	};
});
angular.module('KhBase').factory("FieldPlotCropFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/fms/fieldplotcrop/', {}, {
        get: {
            params: {field_plot_crop_slug: '@field_plot_crop_slug'},
            url: '/api/v1.0/fms/fieldplotcrop/:field_plot_crop_slug/',
            method: 'GET'
        },
        query: {
            params: {farm: '@farm', field: '@field', growing_season: '@growing_season'},
            url: '/api/v1.0/fms/fieldplotcrop/',
            isArray: true
        },
          delete: {
            params: {field_plot_crop_slug: '@field_plot_crop_slug'},
            url: '/api/v1.0/fms/fieldplotcrop/:field_plot_crop_slug/',
            method: 'DELETE'
        },
    });
}]);

import * as phoenixCommon from './phoenix.common';


angular.module('KhBase').service("PhoenixService", function ($http, $q) {

  this.getHostUrl = phoenixCommon.getHostUrl(window.location);

  this.getRefreshToken = function() {

    let defer = $q.defer();
    $http.get('/api/jwt-auth/refreshtoken/').then((resp) => {
        defer.resolve(resp.data.token);
    });
    return defer.promise;
  }


});

angular.module('KhBase').factory("StoreLotFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/store/storelot/', {}, {

        save: {
            method: 'POST'
        },
        query: {
            params: {store_slug: '@store_slug'},
            isArray: true
        },
        get: {
            params: {storelot_slug: '@storelot_slug'},
            url: '/api/v1.0/store/storelot/:storelot_slug/',
            method: 'GET'
        },
        update: {
            params: {storelot_slug: '@storelot_slug'},
            url: '/api/v1.0/store/storelot/:storelot_slug/',
            method: 'PUT'
        },
         delete: {
            params: {storelot_slug: '@storelot_slug'},
            url: '/api/v1.0/store/storelot/:storelot_slug/',
            method: 'DELETE'
        }

    });
}]);
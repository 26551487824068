export default angular.module('kh.accordion', ['kh.collapse', 'kh.tabindex'])
    .run(["$templateCache", function ($templateCache) {
        $templateCache.put("kh/template/accordion/accordion-group.html",
            "<md-card ><div role=\"tab\" id=\"{{::headingId}}\" aria-selected=\"{{isOpen}}\"" +
            " class=\"accordion-heading\"" +
            " ng-keypress=\"toggleOpen($event)\">\n" +
            "  <h4 layout='row' class=\"accordion-title pointer\">\n" +
            "    <a ng-click=\"toggleOpen()\" role=\"button\" data-toggle=\"collapse\" href" +
            " aria-expanded=\"{{isOpen}}\" aria-controls=\"{{::accordionId}}\" tabindex=\"0\" class=\"accordion-toggle\" kh-accordion-transclude=\"heading\" ng-disabled=\"isDisabled\" kh-tabindex-toggle><span kh-accordion-header ng-class=\"{'text-muted': isDisabled}\">{{heading}}</span></a>\n" +
            " <span flex></span> " +
            " <div ng-include='buttonTemplate'></div> " +
            "<md-button ng-click=\"toggleOpen()\" aria-label='Toggle pane' ng-if='isOpen'" +
            " class='md-icon-button'><md-icon" +
            " md-svg-icon='chevron-up'></md-icon></md-button>" +
            "<md-button ng-click=\"toggleOpen()\" aria-label='Toggle pane' ng-if='!isOpen'" +
            "  class='md-icon-button'><md-icon" +
            " md-svg-icon='chevron-down'></md-icon></md-button>  </h4>\n" +
            "</div>\n" +
            "<md-content id=\"{{::accordionId}}\" aria-labelledby=\"{{::headingId}}\"" +
            " aria-hidden=\"{{!isOpen}}\"" +
            " role=\"tabaccordion\" class=\"accordion-collapse collapse\" kh-collapse=\"!isOpen\">\n" +
            "  <div class=\"accordion-body\" ng-transclude></div>\n" +
            "</div></md-card>\n" +
            "");
        $templateCache.put("kh/template/accordion/accordion.html",
            "<div role=\"tablist\" class=\"accordion-group\" ng-transclude></div>");
    }])
    .constant('khAccordionConfig', {
        closeOthers: true
    })

    .controller('khAccordionController', ['$scope', '$attrs', 'khAccordionConfig', function ($scope, $attrs, accordionConfig) {
        this.groups = [];
        this.closeOthers = function (openGroup) {
            var closeOthers = angular.isDefined($attrs.closeOthers) ?
                $scope.$eval($attrs.closeOthers) : accordionConfig.closeOthers;
            if (closeOthers) {
                angular.forEach(this.groups, function (group) {
                    if (group !== openGroup) {
                        group.isOpen = false;
                    }
                });
            }
        };


        this.addGroup = function (groupScope) {
            var that = this;
            this.groups.push(groupScope);

            groupScope.$on('$destroy', function (event) {
                that.removeGroup(groupScope);
            });
        };

        this.removeGroup = function (group) {
            var index = this.groups.indexOf(group);
            if (index !== -1) {
                this.groups.splice(index, 1);
            }
        };
    }])

    .directive('khAccordion', function () {
        return {
            controller: 'khAccordionController',
            controllerAs: 'accordion',
            transclude: true,
            templateUrl: function (element, attrs) {
                return attrs.templateUrl || 'kh/template/accordion/accordion.html';
            }
        };
    })
    .directive('khAccordionGroup', function () {
        return {
            require: '^khAccordion',
            transclude: true,
            restrict: 'A',
            templateUrl: function (element, attrs) {
                return attrs.templateUrl || 'kh/template/accordion/accordion-group.html';
            },
            scope: {
                heading: '@',
                accordionClass: '@?',
                isOpen: '=?',
                buttonTemplate: '=',
                isDisabled: '=?'
            },
            controller: function () {
                this.setHeading = function (element) {
                    this.heading = element;
                };
            },
            link: function (scope, element, attrs, accordionCtrl) {
                element.addClass('accordion');
                accordionCtrl.addGroup(scope);

                scope.openClass = attrs.openClass || 'accordion-open';
                scope.accordionClass = attrs.accordionClass || 'accordion-default';
                scope.$watch('isOpen', function (value) {
                    element.toggleClass(scope.openClass, !!value);
                    if (value) {
                        accordionCtrl.closeOthers(scope);
                    }
                });

                scope.toggleOpen = function ($event) {
                    if (!scope.isDisabled) {
                        if (!$event || $event.which === 32) {
                            scope.isOpen = !scope.isOpen;
                        }
                        if (scope.isOpen) {
                            scope.$broadcast("toggleOpen");
                        }
                    }
                };

                var id = 'accordiongroup-' + scope.$id + '-' + Math.floor(Math.random() * 10000);
                scope.headingId = id + '-tab';
                scope.accordionId = id + '-accordion';
            }
        };
    })

    .directive('khAccordionHeading', function () {
        return {
            transclude: true,
            template: '',
            replace: true,
            require: '^khAccordionGroup',
            link: function (scope, element, attrs, accordionGroupCtrl, transclude) {
                accordionGroupCtrl.setHeading(transclude(scope, angular.noop));
            }
        };
    })

    .directive('khAccordionTransclude', function () {
        return {
            require: '^khAccordionGroup',
            link: function (scope, element, attrs, controller) {
                scope.$watch(function () {
                    return controller[attrs.khAccordionTransclude];
                }, function (heading) {
                    if (heading) {
                        var elem = angular.element(element[0].querySelector(getHeaderSelectors()));
                        elem.html('');
                        elem.append(heading);
                    }
                });
            }
        };

        function getHeaderSelectors() {
            return 'kh-accordion-header,' +
                'data-kh-accordion-header,' +
                'x-kh-accordion-header,' +
                'kh\\:accordion-header,' +
                '[kh-accordion-header],' +
                '[data-kh-accordion-header],' +
                '[x-kh-accordion-header]';
        }
    });




angular.module("khFileUploads").service("khFileUploadsFactory", [function () {
	this.uploadFile = function (fileobject, cookies, scope, type) {
		var formData = new FormData();
		var request = new XMLHttpRequest();
		switch (scope.filetype) {
			case 'images':
				formData.append("image_file", fileobject);
				formData.append("image_name", fileobject.name);
				break;
			case 'files':
				formData.append("file", fileobject);
				formData.append("file_name", fileobject.name);
				break;
		}
		request.open("POST", "api/v1.0/fileuploads/create/");
		request.setRequestHeader("X-CSRFToken", cookies.get("csrftoken"));
		request.send(formData);
		request.onload = function () {
			if (request.readyState === request.DONE) {
				scope.uploading_progress_bar = false;
				if (type == 'image') {
					return scope.$broadcast("image_uploaded", JSON.parse(request.responseText));
				} else {
					return scope.$broadcast("file_uploaded", JSON.parse(request.responseText));
				}

			}
		}
	}
}])
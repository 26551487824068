angular.module("KhNavbar").controller('TopNavbarController', function ($scope, $mdSidenav, $element, $mdDialog,  $rootScope,
                                                     TeamsOfUserFactory, ProfileFactory,
                                                     UserSettingsService,
                                                     SelectedTeamService) {

    $scope.web_env = WEB_ENV;                                                   

    $scope.openMenu = function () {
        $mdSidenav('left').toggle();
    };

    $scope.teams = SelectedTeamService;

    $scope.selectedItemChange = function (team_slug) {
        SelectedTeamService.teamChanged(team_slug);
    };

    $scope.showUserSettingsDialog = function (ev) {
        UserSettingsService.showEditDialog(ev);
    };

    $scope.accountSettings = function () {
        $mdDialog.show({
            controller: 'AccountSettingsController',
            templateUrl: '/static/assets/khapps/settings_v2/general/account_settings/partials/AccountSettings.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false
        });
    };
    $scope.editProfile = function () {
        $mdDialog.show({
            controller: 'EditProfileController',
            templateUrl: '/static/assets/khapps/settings_v2/general/profile/EditProfile.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false
        });
    };

    function getProfile() {
        ProfileFactory.get(function (result) {
            $scope.profile = result;
            $rootScope.user_id = $scope.profile.user;
            $scope.is_profile_pic_loaded = !!$scope.profile.picture;
        });
    }

    getProfile();
    $rootScope.$on('profile_edited', function () {
        getProfile();
    });
    $element.find('input').on('keydown', function (ev) {
        ev.stopPropagation();
    });

    change_pic_loaded_status = function () {
        $scope.is_profile_pic_loaded = false;
    };

});
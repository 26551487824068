angular.module("khUtils").factory("SubscriptionPackageFactory", ["$resource", function ($resource) {
    return $resource('/api/v1.0/subscription-packages/light/:id/', {id: '@id'}, {
        get: {
            method: 'GET'
        },
        save: {
            method: 'POST'
        },
        update: {
            method: 'PUT'
        }

    });
}]);
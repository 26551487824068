angular.module("khUtils").service('$khMessage', function ($mdToast) {
	this.show = function (message, delay) {
		if (!delay) {
			delay = 5000;
		}
		var toast = $mdToast.simple()
			.textContent(message)
			.action('Close')
			.hideDelay(delay)
			.highlightAction(true)
			.highlightClass('md-accent')
			.position('top right');
		$mdToast.show(toast);
	};
	this.hide = function () {
		$mdToast.cancel();
	}
});
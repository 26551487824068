/**
 * Fetch all teams that this user can access
 */
angular.module('khUtils').service('UserTeamsService', function($resource) {
    var res = $resource('/api/utils/team/all/');

    /**
     * Return a summary of all teams accessible by this user
     * @return Promise for all teams
     */
    this.query = function() {
        return res.query().$promise;
    }
});
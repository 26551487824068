angular.module('khUtils').service("ArticleUtilsService", function (CachingUtilsService) {

    var self = this;

    /* eslint-disable angular/document-service,angular/json-functions,angular/definedundefined */
    function range(start, stop, step) {
        if (typeof stop === 'undefined') {
            stop = start;
            start = 1;
        }
        if (typeof step === 'undefined') {
            step = 1;
        }
        if ((step > 0 && start > stop) || (step < 0 && start < stop)) {
            return [];
        }
        var result = [];
        for (var i = start; step > 0 ? i <= stop : i >= stop; i += step) {
            result.push(i);
        }
        return result;
    }

    function generate_range(start, stop, step) {
        var result = [];
        for (var i = start; i <= stop; i += step) {
            result.push(i);
        }
        return result;
    }

     this.addFlex = function (element) {
            $(element).each(function () {
                $(this).addClass("flex");
            });
        };

    // eslint-disable-next-line no-unused-vars
    this.ArticlesListingManager = function (scope, location, ArticlesFactory, RegionsFactory, ArticlesMetaSetupFactory,
                                            mdDialog, mdSidenav, rootScope, $sce, $anchorScroll, $khMessage, backward_cmp) {
        rootScope.show_filters = true;
        scope.show_search_button = true;
        scope.articles_per_page = 30;
        scope.loading_error = 0;
        // eslint-disable-next-line no-undef
        scope.tenant_id = tenant_id;
        scope.clearSearchTerm = function () {
            scope.searchCategoryTerm = '';
            scope.searchRegionTerm = '';
            scope.searchCommoditiesTerm = '';
            scope.searchTagsTerm = '';
            scope.searchContentTypeTerm = '';
        };
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////   Messages    ////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        function noArticlesOnSearchMessage(search_query) {
            scope.message = 'There are no articles for your search query: ' + search_query;
        }

        function noArticlesOnFiltersMessage() {
            scope.message = 'There are no articles for the applied filters, please clear filter to see all articles.';
        }

        function noArticles() {
            scope.message = 'There are no articles for your team, it is possible that, ' +
                'your current subscription package does not give you access to articles.';
        }

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////   API CALLS   ////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // Added backward_cmp flag to handle conflicting responses from Articles, Support Videos and
        // Archived articles API
        var count_attr, data_attr;
        if (backward_cmp) {
            count_attr = 'count';
            data_attr = 'results';
        }
        else {
            count_attr = 'total_count';
            data_attr = 'data';
        }
        function getArticlesForPage(pageNum) {
            ArticlesFactory.query({page: pageNum}, function (result) {

                if (result[count_attr] === 0) {
                    noArticles();
                }
                scope.article_count = result[count_attr];
                if (backward_cmp) {
                    backward_cmp_buildPages(result.count);
                }
                else {
                    buildPages(result.total_pages);
                }
                scope.articles = [];
                scope.articles = result[data_attr];

                $anchorScroll('articles');
            }, function () {
                scope.loading_error = 1;
            });
        }

        rootScope.$on('article_saved', function () {
            getArticlesForPage(scope.currentPage);
        });

        function buildPages(count) {
            scope.articles_pages = generate_range(1, count, 1);
            sessionStorage.setItem('articles_pages', JSON.stringify(scope.articles_pages));
            return scope.articles_pages;
        }

        function backward_cmp_buildPages(count) {
            scope.articles_pages = range(Math.ceil(count / scope.articles_per_page));
            sessionStorage.setItem('articles_pages', JSON.stringify(scope.articles_pages));
            return scope.articles_pages;
        }


        function get_query_params(filtersDictionary, pageNum) {
            // eslint-disable-next-line no-undef
            var query_params = _.mapObject(filtersDictionary, function (value, key) {
                if (value && angular.isArray(value)) {
                    return value.join(',');
                } else {
                    return value;
                }
            });
            query_params.page = pageNum;
            return query_params;
        }

        function getArticlesForFilters(pageNum, filtersDictionary) {
            var query_params = get_query_params(filtersDictionary, pageNum);
            ArticlesFactory.query(query_params, function (result) {
                if (result[count_attr] === 0) {
                    if (filtersDictionary.category && !(filtersDictionary.commodities ||
                        filtersDictionary.months || filtersDictionary.content_type || filtersDictionary.tags || filtersDictionary.publish_date ||
                        filtersDictionary.article_type || filtersDictionary.region)) {
                        scope.no_access_message = true;
                    } else {
                        noArticlesOnFiltersMessage();
                    }

                } else if (result[count_attr] === 0) {
                    noArticlesOnFiltersMessage();
                }
                scope.article_count = result[count_attr];

                if (backward_cmp) {
                    backward_cmp_buildPages(result.count);
                }
                else {
                    buildPages(result.total_pages);
                }
                scope.articles = result[data_attr];
                // eslint-disable-next-line no-undef
                self.ArticleHelpers().smoothScroll();
            });
        }

        function getArticlesForSearch(pageNum, search_query) {
            ArticlesFactory.query({page: pageNum, search: search_query}, function (result) {
                if (result[count_attr] === 0) {
                    noArticlesOnSearchMessage(search_query);
                }
                scope.article_count = result[count_attr];
                scope.articles = result[data_attr];
                if (backward_cmp) {
                    backward_cmp_buildPages(result.count);
                }
                else {
                    buildPages(result.total_pages);
                }
                // eslint-disable-next-line no-undef
                self.ArticleHelpers().smoothScroll();
            });
        }

        // eslint-disable-next-line no-undef
        scope.monthNames = self.ArticleHelpers().getMonthNames;
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////   Listing     ////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        scope.filtersDictionary = {};
        scope.filters_list = {};
        getArticlesForPage(scope.currentPage);
        scope.ReadArticleHelper = function (slug) {
            // eslint-disable-next-line no-undef
            return self.ArticleHelpers().readArticle(location, slug);
        };

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////   Filters     ////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        scope.applyFilters = function () {
            scope.clearSearched();
            scope.message = null;
            scope.no_access_message = false;
            scope.currentPage = 1;
            scope.filtered = true;
            getArticlesForFilters(scope.currentPage, scope.filtersDictionary);
            sessionStorage.setItem('filtersDictionary', JSON.stringify(scope.filtersDictionary));
        };
        scope.removeItem = function (item) {
            scope.message = null;
            scope.no_access_message = false;
            if (item[1] === 'publish_date') {
                scope.selected_year = null;
                rootScope.$broadcast('selected_year_cleared');
            } else if (item[1] === 'article_type') {
                scope.selected_type = null;
                rootScope.$broadcast('selected_type_cleared');
            } else if (item[1] === 'region') {
                scope.selected_region = null;
                rootScope.$broadcast('selected_region_cleared');
            }
            delete scope.filtersDictionary[item[1]];
            delete scope.filters_list[item[1]];
            scope.currentPage = 1;
            sessionStorage.setItem('filtersDictionary', JSON.stringify(scope.filtersDictionary));
            if (Object.keys(scope.filtersDictionary).length === 0 || Object.keys(scope.filters_list).length === 0) {
                scope.clearFilter();
            }
            getArticlesForFilters(scope.currentPage, scope.filtersDictionary);
        };
        scope.clearFilter = function () {
            scope.searched = false;
            scope.no_access_message = false;
            scope.search_query = null;
            scope.selected_year = '';
            scope.selected_type = '';
            scope.selected_region = '';
            scope.filtersDictionary = {};
            scope.filters_list = {};
            scope.currentPage = 1;
            scope.no_access_message = false;
            scope.message = null;
            getArticlesForPage(scope.currentPage);
            sessionStorage.removeItem('search_query');
            sessionStorage.removeItem('filtersDictionary');
            sessionStorage.removeItem('filters_list');
            rootScope.$broadcast('filters_cleared');
        };
        scope.clearFiltered = function () {
            scope.no_access_message = false;
            scope.selected_category = [];
            scope.selected_regions = [];
            scope.selected_commodities = [];
            scope.selected_tags = [];
            scope.selected_content_type = [];
            scope.selected_formats = [];
            scope.selected_year = '';
            scope.selected_type = '';
            scope.filtersDictionary = {};
            scope.filters_list = {};
            scope.currentPage = 1;
            scope.message = null;
            sessionStorage.removeItem('search_query');
            sessionStorage.removeItem('filtersDictionary');
            sessionStorage.removeItem('filters_list');
        };
        scope.clearSearched = function () {
            scope.searched = false;
            scope.search_query = null;
            scope.no_access_message = false;
        };
        scope.applyFilter = function (item, key) {
            if (item) {
                scope.filtersDictionary[key] = item;
                scope.applyFilters();
            }
        };
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////   Search   ////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        scope.searchArticles = function (search_query) {
            if (search_query.length > 0) {
                scope.clearFiltered();
                scope.message = null;
                scope.currentPage = 1;
                scope.searched = true;
                getArticlesForSearch(scope.currentPage, search_query);
                sessionStorage.setItem('search_query', search_query);
                rootScope.$broadcast('searched', search_query);
            }
        };

        scope.checkForClearing = function (search_query) {
            if (search_query.length === 0) {
                scope.clearFilter();
            }
        };
        scope.pageChanged = function (pageNum) {
            if (sessionStorage.getItem('search_query')) {
                getArticlesForSearch(pageNum, scope.search_query);
            } else if (sessionStorage.getItem('filtersDictionary')) {
                getArticlesForFilters(pageNum, scope.filtersDictionary);
            } else {
                getArticlesForPage(pageNum);
            }
            scope.currentPage = pageNum;
        };

        /* eslint-disable no-undef*/
        scope.meta = {
            team_regions: CachingUtilsService.addFactoryWithoutCache(RegionsFactory),
            categories: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'categories', 'article_categories'),
            commodities: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'commodities', 'article_commodities'),
            tags: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'tags', 'article_tags'),
            content_type: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'content-type', 'article_content_type'),
            article_type: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'article-type', 'article_article_type'),
            years: CachingUtilsService.loadGenericFactory(ArticlesMetaSetupFactory, 'years', 'article_years')
        };
        /* eslint-enable no-undef*/

        scope.showArticle = function (article_slug) {
            callArticleAgain(article_slug);
        };

        scope.deleteArticle = function (article_slug, ev) {
            var confirm = mdDialog.confirm()
                .title('Delete article')
                .textContent('Are your sure you want to delete this article?')
                .ariaLabel('Delete article')
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');
            mdDialog.show(confirm).then(function () {
                ArticlesFactory.delete({slug: article_slug}, function () {
                    $khMessage.show('Successfully deleted.');
                    getArticlesForPage(scope.currentPage);
                }, function () {
                    $khMessage.show('Unable to delete article, please contact support.');
                });
            }, function () {

            });
        };

        function callArticleAgain(article_slug) {
            mdDialog.show({
                controller: 'SingleArticleController',
                templateUrl: '/static/assets/khapps/articles/articles/partials/SingleArticle.html',
                parent: angular.element(document.body),
                locals: {
                    article_slug: article_slug
                },
                clickOutsideToClose: false
            }).then(function (some) {
                if (some) {
                    callArticleAgain(some);
                }
            });
        }

        scope.editArticle = function (article_slug, article) {
            if (article && (['Memberreport', 'Rapidtrialreport'].indexOf(article.article_type_fk) !== -1)) {
                window.open('/dashboard/trial/#/report/' + article_slug, '_blank');
            } else {

                mdDialog.show({
                    controller: 'ArticleAddEditController',
                    templateUrl: '/static/assets/khapps/articles/articles_add_edit_module/partials/ArticleAddEditForm.html',
                    parent: angular.element(document.body),
                    locals: {
                        article_slug: article_slug
                    },
                    clickOutsideToClose: false
                });
            }
        };

        scope.trustContentAsHtml = function (content) {
            return $sce.trustAsHtml(content);
        };
        scope.checkIfPageExists = function (page) {
            return JSON.parse(sessionStorage.getItem('articles_pages')).indexOf(parseInt(page)) === -1;
        };

        scope.selected_regions = [];
        scope.add_region_to_filter = function (slug) {
            scope.selected_regions.push(slug);
            scope.applyFilter(scope.selected_regions, 'region');
        };

        scope.selected_tags = [];
        scope.add_tag_to_filter = function (slug) {
            scope.selected_tags.push(slug);
            scope.applyFilter(scope.selected_tags, 'tags');
        };
    };


    this.ArticleHelpers = function () {
        var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return {
            getMonthNames: monthNames,
            getShortMonthNames: shortMonthNames,
            getMonth: function (month_short) {
                return monthNames[month_short];
            },
            getReadableDate: function (date) {
                if (date) {
                    var split_date = date.split('-');
                    var month = shortMonthNames[parseInt(split_date[1], 10) - 1];
                    return split_date[2] + ' ' + month + ', ' + split_date[0];
                }
            },
            readArticle: function (location, slug) {
                return location.url('/view/' + slug);
            },
            editArticle: function (location, slug) {
                return location.url('/edit/' + slug);
            },
            checkEditPermission: function () {
                if (permission === 'true') {
                    return permission;
                } else return false;
            },
            checkGlobalEditPermission: function () {
                return permission === 'true';
            },
            checkGlobalTrialPermission: function () {
                return trial_app_permission === 'true';
            },
            smoothScroll: function () {
                $(window).scroll(function () {
                    if ($(this).scrollTop() > 100) {
                        $('.scrollup').fadeIn();
                    } else {
                        $('.scrollup').fadeOut();
                    }
                });
                $('html, body').animate({
                    scrollTop: 0
                }, 600);
            },
            disable_editable_divs: function () {
                $('.editor-body').removeAttr('contenteditable').removeAttr('tabindex');
            },
            generate_and_add_references_table: function (member_reports, current_article_slug) {
                var references = '<div id=\'references-table\'> <div class=\'editor-title\'> <h4> Related Reports </h4> </div><div class=\'editor-body ckeditor-inline\' contenteditable=\'true\' id=\'references-inline\'> <table class=\' table table-bordered table-striped table-hover \'> <tbody> <tr class=\'text-center\'> <td>Report Title	</td> <td>Published Date</td></tr>  ';
                var published_reports = 0;
                for (var i = 0; i < member_reports.length; i++) {
                    if (member_reports[i].status === 2 && member_reports[i].slug !== current_article_slug) {
                        published_reports += 1;
                        references += '<tr class=\'reference-table-row\' > <td>' + member_reports[i].title + '</td>';
                        references += '<td>' + member_reports[i].publish_date_readable + '</td>';
                    }
                }
                if (published_reports === 0) {
                    references += '<tr class=\'reference-table-row\' > <td colspan=\'2\'> No Published Related reports available.</td>';
                }
                references += '</tbody> </table> </div></div>';
                $('#references-content').html(references);
            }
        };
    };
});

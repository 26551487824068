/**
 * Angular JS support in sentry/browser is not added yet. Following code is to work it with Angular JS
 * https://github.com/getsentry/sentry-javascript/issues/1880
 */

import * as Sentry from '@sentry/browser';
import { Integration, SentryEvent } from '@sentry/types';

// See https://github.com/angular/angular.js/blob/v1.4.7/src/minErr.js
const angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/;

function normalizeData(event: SentryEvent) {
  // We only care about mutating an exception
  // eslint-disable-next-line lodash/prefer-get
  const exception = event.exception && event.exception.values && event.exception.values[0];
  if (exception) {
    const matches = angularPattern.exec(exception.value || '');

    if (matches) {
      // This type now becomes something like: $rootScope:inprog
      exception.type = matches[1];
      exception.value = matches[2];

      event.message = exception.type + ': ' + exception.value;
      // auto set a new tag specifically for the angular error url
      event.extra = { ...event.extra, angularDocs: matches[3].substr(0, 250) };
    }
  }

  return event;
}

/**
 * AngularJS integration
 *
 * Provides an $exceptionHandler for AngularJS
 */
export class SentryAngularJSIntegration implements Integration {
  public static id: string = 'AngularJS';
  public static moduleName = 'ngSentry';

  public name: string = SentryAngularJSIntegration.id;
  private readonly angular: ng.IAngularStatic;

  public constructor(options: { angular?: ng.IAngularStatic } = {}) {
    this.angular = options.angular || (window as Window & { angular: ng.IAngularStatic }).angular;
  }

  public setupOnce(): void {
    if (!this.angular) {
      console.error('SentryAngularJSIntegration is missing an angular instance');
      return;
    }

    function $exceptionHandlerDecorator($delegate: ng.IExceptionHandlerService) {
      const $exceptionHandler: ng.IExceptionHandlerService = (exception, cause) => {
        Sentry.withScope((scope) => {
          if (cause) {
            scope.setExtra('cause', cause);
          }
          scope.addEventProcessor((event) => normalizeData(event) || event);
          Sentry.captureException(exception);
        });

        // Based on https://github.com/angular/angular.js/issues/5217
        console.error(exception);
      };
      return $exceptionHandler;
    }

    this.angular
      .module(SentryAngularJSIntegration.moduleName, [])
      .config(['$provide', ($provide: ng.auto.IProvideService) => {
        $provide.decorator('$exceptionHandler', ['$delegate', $exceptionHandlerDecorator]);
      }]);
  }
}
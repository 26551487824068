export default angular.module('kh.tabindex', [])

	.directive('khTabindexToggle', function () {
		return {
			restrict: 'A',
			link: function (scope, elem, attrs) {
				attrs.$observe('disabled', function (disabled) {
					attrs.$set('tabindex', disabled ? -1 : null);
				});
			}
		};
	});
angular.module("KhBase").factory("FarmNamesFactory", ["$resource", function ($resource) {
    return $resource('api/v1.0/khutils/farms/', {}, {
            query: {
                method: 'GET',
                params: {as_dict: 'yes'},
                isArray: true
            },
            get: {
                params: {farm_slug: '@farm_slug'},
                url: 'api/v1.0/khutils/farms/:farm_slug/',
                method: 'GET'
            }
        }
    );
}]);

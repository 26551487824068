angular.module('khUtils').service('FarmSummariesService', function($resource) {
    var resource = $resource('/api/fms/farm/summary/');

    /**
     * Fetch the farm summaries for the given team
     * @param team_slug - team slug
     * @return Promise
     */
    this.fetch = function(team_slug) {
        return resource.query({team_slug: team_slug}).$promise;
    }

});
/**
 * Get phoenix host url
 * @param {*} location : Current location
 */
function getHostUrl(location) {
  const prefix = 'appx';
  let phoenixHost = '';
  const host = location.host;
  if (location.hostname === 'localhost') {
    phoenixHost = 'localhost:4200';
  } else if (host.startsWith('app')) {
    phoenixHost = host.replace('app', prefix);
  } else if (host.includes('niab')) {
    phoenixHost = 'appx.kisanhub.com';
  } else if (host.startsWith('www.')) {
    let wwwhost = host.substr(host.indexOf('.') + 1);
    phoenixHost = `${prefix}-${wwwhost}`;
  } else {
    phoenixHost = `${prefix}-${location.host}`;
  }
  return `${location.protocol}//${phoenixHost}/`;
}

export { getHostUrl };
